import gql from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from 'graphql/page-info';

export const CREATE_WALL_MUTATION = gql`
	mutation CreateWall {
		createSortWall {
			success
		}
	}
`;

export const SORT_WALLS_QUERY = gql`
	query SortWalls(
		$after: String
		$before: String
		$first: Int
		$last: Int
		$statuses: [SortationStatus!]!
		$searchText: String
		$completedAtFrom: DateTime
		$completedAtTo: DateTime
	) {
		sortations(
			after: $after
			before: $before
			first: $first
			last: $last
			statuses: $statuses
			searchText: $searchText
			completedAtFrom: $completedAtFrom
			completedAtTo: $completedAtTo
		) {
			edges {
				node {
					id
					wall
					status
					batchId
					expectedShipDate
					timeline {
						createdAt
					}
					lines {
						id
						status
						quantity
					}
					pickingJobs {
						id
						pickingStatus
						lines {
							id
							status
						}
					}
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;

export const SORT_WALLS_COUNTS_QUERY = gql`
	query CountSortWalls($searchText: String, $completedAtFrom: DateTime, $completedAtTo: DateTime) {
		AllCount: sortationsCount(
			searchText: $searchText
			statuses: [Unassigned, Picking, Picked, Sorting, Sorted, Exception]
		) {
			count
		}

		UnassignedCount: sortationsCount(searchText: $searchText, statuses: [Unassigned]) {
			count
		}

		PickingCount: sortationsCount(searchText: $searchText, statuses: [Picking]) {
			count
		}

		ReadyToSortCount: sortationsCount(searchText: $searchText, statuses: [Picked]) {
			count
		}

		SortingCount: sortationsCount(searchText: $searchText, statuses: [Sorting]) {
			count
		}

		CompletedTodayCount: sortationsCount(
			searchText: $searchText
			statuses: [Unassigned, Picking, Picked, Sorting, Sorted, Exception]
			completedAtFrom: $completedAtFrom
			completedAtTo: $completedAtTo
		) {
			count
		}

		ExceptionsCount: sortationsCount(searchText: $searchText, statuses: [Exception]) {
			count
		}
	}
`;
