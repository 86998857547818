import { Layout, Modal, Page, TextContainer, TextStyle } from '@shopify/polaris';
import { Return, ReturnStatus, ReturnStatusInput } from '@sixriver/fulfillment-api-schema';
import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery, useMutation } from 'urql';

import { Details } from './Details';
import { INBOUND_RETURN_QUERY, CLOSE_INBOUND_RETURN_MUTATION } from './InboundReturn.graphql';
import { InternalNotes } from './InternalNotes';
import { Lines } from './Lines';
import { Progress } from './Progress';
import { ReturnIssues } from './ReturnIssues';
import { TrackingInformation } from './TrackingInformation';
import { Error } from 'components/Error';
import { ErrorBanner } from 'components/ErrorBanner';
import { ReturnStatusBadge } from 'components/ReturnStatusBadge';
import { useLocalization } from 'hooks/useLocalization';

interface ModalArgs {
	visible: boolean;
	title?: string;
	message?: string;
	buttonLabel?: string;
	buttonAction?(): void;
}

export function InboundReturn() {
	const { messages, translate } = useLocalization();

	const {
		params: { returnId },
	} = useRouteMatch<{ returnId: string }>();

	// query logic
	const [{ data, error }, refetchReturn] = useQuery<{ inboundReturn: Return }>({
		query: INBOUND_RETURN_QUERY,
		variables: {
			id: returnId,
		},
	});

	const [modalArgs, setModalArgs] = useState<ModalArgs>({
		visible: false,
	});

	const [, closeMutation] = useMutation<{ completeReturn: Response }, ReturnStatusInput>(
		CLOSE_INBOUND_RETURN_MUTATION,
	);

	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);
	const [closeReturnEnabled, setCloseReturnEnabled] = useState(false);

	const { inboundReturn } = data || {};

	const runMutation = async (mutation: Function) => {
		try {
			const { error } = await mutation.call(null, {
				input: {
					externalId: inboundReturn?.externalId,
				},
			});

			if (error) {
				throw error;
			}
		} catch (e) {
			setIsBannerErrorVisible(true);
		}
	};

	useEffect(() => {
		const status = inboundReturn?.returnStatus as ReturnStatus;

		setCloseReturnEnabled([ReturnStatus.Returning].includes(status));
	}, [inboundReturn]);

	return error ? (
		<Error graphQLError={error} />
	) : (
		<Page
			breadcrumbs={[{ content: messages.returns, url: '/inbound-returns' }]}
			title={
				translate(messages.returnShipmentByExternalId, {
					externalId: inboundReturn?.externalId,
				}) as string
			}
			titleMetadata={<ReturnStatusBadge status={inboundReturn?.returnStatus} />}
			fullWidth
			primaryAction={{
				content: messages.markAsComplete,
				onAction: () =>
					setModalArgs({
						visible: true,
						title: messages.closeReturn,
						message: messages.confirmCloseReturn,
						buttonLabel: messages.closeReturn,
						buttonAction: () => runMutation(closeMutation),
					}),
				disabled: !closeReturnEnabled,
			}}
		>
			<Layout>
				<Layout.Section>
					<ErrorBanner
						isVisible={isBannerErrorVisible}
						onDismiss={() => {
							setIsBannerErrorVisible(false);
						}}
					/>
				</Layout.Section>
				<Layout.Section>
					<Progress inboundReturn={inboundReturn} />
					<Lines inboundReturn={inboundReturn} />
					<ReturnIssues inboundReturn={inboundReturn} refetchReturn={refetchReturn} />
				</Layout.Section>
				<Layout.Section secondary>
					<Details inboundReturn={inboundReturn} />
					<TrackingInformation inboundReturn={inboundReturn} />
					<InternalNotes inboundReturn={inboundReturn} refetchReturn={refetchReturn} />
				</Layout.Section>
			</Layout>
			<Modal
				open={modalArgs.visible}
				onClose={() => setModalArgs({ visible: false })}
				title={modalArgs.title}
				primaryAction={{
					content: modalArgs.buttonLabel,
					onAction: () => {
						setIsBannerErrorVisible(false);

						if (modalArgs.buttonAction) {
							modalArgs.buttonAction();
						}

						setModalArgs({ visible: false });
					},
					destructive: true,
					loading: false,
				}}
				secondaryActions={[
					{
						content: messages.goBack,
						onAction: () => setModalArgs({ visible: false }),
					},
				]}
			>
				<Modal.Section>
					<TextContainer>
						<TextStyle>{modalArgs.message}</TextStyle>
					</TextContainer>
				</Modal.Section>
			</Modal>
		</Page>
	);
}
