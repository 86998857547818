import { Link, AppliedFilterInterface, FilterInterface } from '@shopify/polaris';
import { Line, LineStatus, OrderConnection } from '@sixriver/fulfillment-api-schema';
import { ReactNode } from 'react';

import { ExceptionBadge } from '../Exception/ExceptionBadge';
import { ExceptionViews } from './ExceptionViews';
import { ResolveButtons } from './ResolveButtons';
import {
	DataTable,
	Column,
	PaginationCursors,
	SetPaginationCursors,
	DataTableView,
} from 'components/DataTable';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { getFirstPickingJob } from 'helpers/exception';
import { calculateUnits } from 'helpers/order';
import { useConfig } from 'hooks/useConfig';
import { SetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { onErrorProp } from 'hooks/usePrintLabelButton';
import * as routes from 'routes';

interface Props {
	loading?: boolean;
	data?: OrderConnection;
	paginationCursors: PaginationCursors;
	setPaginationCursors: SetPaginationCursors;
	views: DataTableView[];
	query?: string;
	setFilters: SetFilters;
	selectedView?: ExceptionViews;
	onPrintLabelError?: onErrorProp;
	appliedFilters?: AppliedFilterInterface[];
	filters?: FilterInterface[];
}

export function ExceptionsTable({
	loading = false,
	data,
	paginationCursors,
	setPaginationCursors,
	views,
	query,
	filters,
	setFilters,
	selectedView,
	onPrintLabelError,
	appliedFilters,
}: Props) {
	const { messages, translate } = useLocalization();
	const { config } = useConfig();

	const { pageInfo, edges = [] } = data || {};

	const columns: Column[] = [
		{
			type: 'text',
			heading: messages.labels,
		},
		{
			type: 'text',
			heading: messages.order,
		},
		{
			type: 'text',
			heading: messages.exceptionStatus,
		},
	];

	if (!config?.healingEnabled) {
		columns.push({
			type: 'text',
			heading: messages.reason,
		});
	}

	columns.push(
		{
			type: 'text',
			heading: messages.unitsPicked,
		},
		{
			type: 'text',
			heading: messages.createdAt,
		},
		{
			type: 'text',
			heading: messages.carrierCutoff,
		},
	);

	if (config?.workAreasEnabled) {
		columns.push({
			type: 'text',
			heading: messages.workArea,
		});
	}

	if (config?.healingEnabled) {
		columns.push({
			type: 'text',
			heading: messages.initialLicensePlate,
		});
	}

	const distinctExceptionReasons = (lines: Line[]): LineStatus[] => {
		return Array.from(
			new Set(
				lines
					.map((line) => line.status || [])
					.flat()
					.filter((status) => {
						return [
							LineStatus.DamagedProduct,
							LineStatus.MissingProduct,
							LineStatus.WorkflowException,
						].includes(status);
					}),
			),
		);
	};

	const rows: ReactNode[][] = (edges || []).map((edge) => {
		const { id, customerIdentifier, expectedShipDate, lines, servicedBy, timeline, workAreas } =
			edge.node;
		const { picked, total } = calculateUnits(lines);
		const pickingJob = getFirstPickingJob(servicedBy);

		const row = [
			<div key={`${id}-exception-handling`}>
				<ResolveButtons order={edge.node} onError={onPrintLabelError} />
			</div>,
			<Link key={`${id}-order`} url={routes.exception(id)}>
				{customerIdentifier}
			</Link>,
			<ExceptionBadge key={`${id}-status`} status={edge.node?.exception?.status} />,
		];

		if (!config?.healingEnabled) {
			const reasons = distinctExceptionReasons(lines);

			row.push(
				<div>
					{reasons.length === 1 ? (
						messages.exceptionTypes[reasons[0] as keyof typeof messages.exceptionTypes]
					) : reasons.length > 1 ? (
						messages.multiple
					) : (
						<NoData />
					)}
				</div>,
			);
		}

		row.push(
			<div key={`${id}-units-picked`}>{translate(messages.pickedOfTotal, { picked, total })}</div>,
			<RelativeDateTime key={`${id}-order-age`} date={timeline.createdAt} />,
			<div key={`${id}-ship-by`}>
				<DateTime date={expectedShipDate} />
				<RelativeDateTime date={expectedShipDate} mode="deadline" />
			</div>,
		);

		if (config?.workAreasEnabled) {
			row.push(
				workAreas.length ? <div>{workAreas.map((area) => area.name).join(', ')}</div> : <NoData />,
			);
		}

		if (config?.healingEnabled) {
			row.push(<div key={`${id}-lpn`}>{pickingJob?.container?.barcode}</div>);
		}

		return row;
	});

	return (
		<DataTable
			loading={loading}
			columns={columns}
			rows={rows}
			emptyStateHeading={messages.exceptionsNotFound}
			pageInfo={pageInfo}
			paginationCursors={paginationCursors}
			setPaginationCursors={setPaginationCursors}
			views={views}
			query={query}
			queryPlaceholder={messages.filterExceptions}
			filters={filters}
			setFilters={setFilters}
			selectedView={selectedView}
			appliedFilters={appliedFilters}
		/>
	);
}
