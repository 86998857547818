import { Button, Card, Layout, Page, Stack, TextStyle } from '@shopify/polaris';

import { InlineFrame } from 'components/InlineFrame';
import { useLocalization } from 'hooks/useLocalization';

export function Support() {
	const { messages } = useLocalization();

	return (
		<Page>
			<Layout>
				<Layout.Section>
					<div style={{ height: '90vh' }}>
						<div style={{ height: '70%' }}>
							<InlineFrame title={messages.support} src={`/6support/`} />
						</div>
						<div style={{ margin: '3em 0' }}>
							<Card title={messages.feedbackHeading}>
								<Card.Section>
									<Stack vertical>
										<TextStyle>{messages.feedbackRequest}</TextStyle>
										<Button external url="https://www.surveymonkey.com/r/6RSBridgeFeedback">
											{messages.feedbackAction}
										</Button>
									</Stack>
								</Card.Section>
							</Card>
						</div>
					</div>
				</Layout.Section>
			</Layout>
		</Page>
	);
}
