import {
	AppliedFilterInterface,
	Card,
	ChoiceList,
	Filters,
	FilterInterface,
	Layout,
	Page,
} from '@shopify/polaris';
import { useDebouncedValue } from '@shopify/react-hooks';
import {
	Count,
	OrderByDirection,
	QueryReturnsSummariesArgs,
	ReturnsSummaryPage,
	ReturnsSummaryOrderByField,
} from '@sixriver/fulfillment-api-schema';
import { useQuery } from 'urql';

import { InboundReturnViews, InboundReturnViewStateMap } from './InboundReturnViews';
import {
	RETURNS_SUMMARIES_QUERY,
	RETURNS_MERCHANTS_QUERY,
	COUNTS_QUERY,
} from './InboundReturns.graphql';
import { InboundReturnsTable } from './InboundReturnsTable';
import { Error } from 'components/Error';
import { SortBy, SortChoice } from 'components/SortBy';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { Tab, ViewTabs } from 'components/ViewTabs';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { addDays, endOfDay, startOfDay } from 'helpers/time';
import {
	SetFilters,
	useFilters,
	useSetFilters,
	DEFAULT_QUERY_KEY,
	DEFAULT_SORT_KEY,
	DEFAULT_VIEW_KEY,
} from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';

const RETURN_TYPE_KEY = 'returnType';
const STORE_KEY = 'store';
const ESTIMATED_ARRIVAL_KEY = 'estimatedArrival';
const queryKey = DEFAULT_QUERY_KEY;
const sortKey = DEFAULT_SORT_KEY;
const viewKey = DEFAULT_VIEW_KEY;

function calculateETAFrom(selectedETA: string | undefined) {
	return selectedETA ? startOfDay() : undefined;
}

function calculateETATo(selectedETA: string | undefined) {
	if (selectedETA) {
		const dayEnd = endOfDay();

		switch (selectedETA) {
			case '30DAYS':
				return addDays(dayEnd, 29);
			case '10DAYS':
				return addDays(dayEnd, 9);
			case '5DAYS':
				return addDays(dayEnd, 4);
			case '3DAYS':
				return addDays(dayEnd, 2);
			case '2DAYS':
				return addDays(dayEnd, 1);
			case 'TODAY':
			default:
				return dayEnd;
		}
	}

	return undefined;
}

export function InboundReturns() {
	const { messages, translate } = useLocalization();

	const defaultView = InboundReturnViews.All;
	const defaultSort = `${ReturnsSummaryOrderByField.ReturnStatus} ${OrderByDirection.Desc}`;

	// filtring logic
	const {
		query,
		sort = defaultSort,
		view = defaultView,
		[RETURN_TYPE_KEY]: returnTypeParam,
		[STORE_KEY]: storeParam,
		[ESTIMATED_ARRIVAL_KEY]: etaParam,
	} = useFilters([RETURN_TYPE_KEY, STORE_KEY, ESTIMATED_ARRIVAL_KEY]);
	const setFilters: SetFilters = useSetFilters();

	const searchText = useDebouncedValue(query) || '';
	const actualSearchText = searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined;
	const selectedReturnType = returnTypeParam;
	const selectedStores = storeParam ? storeParam.split(',') : undefined;
	const selectedETA = etaParam;

	const statuses = InboundReturnViewStateMap[view as InboundReturnViews];

	// query logic
	const [
		{
			fetching: returnsSummariesFetching,
			data: returnsSummariesData,
			error: returnsSummariesError,
		},
	] = useQuery<{ returnsSummaries: ReturnsSummaryPage }, QueryReturnsSummariesArgs>({
		query: RETURNS_SUMMARIES_QUERY,
		variables: {
			limit: 50,
			orderBy: sort ? (sort.split(' ')[0] as ReturnsSummaryOrderByField) : undefined,
			orderDirection: sort ? (sort.split(' ')[1] as OrderByDirection) : undefined,
			searchText: actualSearchText,
			statuses,
			returnType: selectedReturnType,
			merchants: selectedStores,
			etaFrom: calculateETAFrom(selectedETA),
			etaTo: calculateETATo(selectedETA),
		},
	});

	const [{ fetching: countsFetching, data: countsData, error: countsError }] = useQuery<{
		InTransitCount: Count;
		InProgressCount: Count;
		ClosedCount: Count;
		CancelRequestedCount: Count;
		CancelledCount: Count;
	}>({
		query: COUNTS_QUERY,
		variables: {
			merchants: selectedStores,
			etaFrom: calculateETAFrom(selectedETA),
			etaTo: calculateETATo(selectedETA),
			searchText: actualSearchText,
			returnType: selectedReturnType,
		},
	});

	const [{ fetching: merchantsFetching, data: merchantsData, error: merchantsError }] = useQuery<{
		returnsMerchants: string[];
	}>({
		query: RETURNS_MERCHANTS_QUERY,
	});

	const fetching = returnsSummariesFetching || countsFetching || merchantsFetching;
	const error = returnsSummariesError || countsError || merchantsError;

	const {
		InTransitCount = { count: 0 },
		InProgressCount = { count: 0 },
		ClosedCount = { count: 0 },
		CancelRequestedCount = { count: 0 },
		CancelledCount = { count: 0 },
	} = countsData || {};

	const views: Tab[] = [
		{
			label: messages.all,
			id: InboundReturnViews.All,
		},
		{
			label: messages.inTransit,
			metaLabel: InTransitCount.count,
			id: InboundReturnViews.InTransit,
		},
		{
			label: messages.inProgress,
			metaLabel: InProgressCount.count,
			id: InboundReturnViews.InProgress,
		},
		{
			label: messages.closed,
			metaLabel: ClosedCount.count,
			id: InboundReturnViews.Complete,
		},
		{
			label: messages.cancellationRequested,
			metaLabel: CancelRequestedCount.count,
			id: InboundReturnViews.CancelRequested,
		},
		{
			label: messages.canceled,
			metaLabel: CancelledCount.count,
			id: InboundReturnViews.Cancelled,
		},
	];

	const returnTypeChoices = [
		{
			label: messages.returnTypes.RMA,
			value: 'RMA',
		},
		{
			label: messages.returnTypes.RTS,
			value: 'RTS',
		},
	];

	const storeChoices =
		merchantsData?.returnsMerchants.map((merchant) => ({
			label: merchant,
			value: merchant,
		})) || [];

	const etaChoices = [
		{
			label: messages.arrivingToday,
			value: 'TODAY',
		},
		{
			label: translate(messages.arrivingWithinDays, { count: 2 }),
			value: '2DAYS',
		},
		{
			label: translate(messages.arrivingWithinDays, { count: 3 }),
			value: '3DAYS',
		},
		{
			label: translate(messages.arrivingWithinDays, { count: 5 }),
			value: '5DAYS',
		},
		{
			label: translate(messages.arrivingWithinDays, { count: 10 }),
			value: '10DAYS',
		},
		{
			label: translate(messages.arrivingWithinDays, { count: 30 }),
			value: '30DAYS',
		},
	];

	const filters: FilterInterface[] = [
		{
			key: RETURN_TYPE_KEY,
			label: messages.returnType,
			filter: (
				<ChoiceList
					title={messages.returnType}
					titleHidden
					choices={returnTypeChoices}
					selected={selectedReturnType ? [selectedReturnType] : []}
					onChange={(selected) => {
						setFilters([{ key: RETURN_TYPE_KEY, value: selected.join(',') }]);
					}}
				/>
			),
			shortcut: true,
		},
		{
			key: STORE_KEY,
			label: messages.store,
			filter: (
				<ChoiceList
					title={messages.store}
					titleHidden
					choices={storeChoices}
					selected={selectedStores || []}
					onChange={(selected) => {
						setFilters([{ key: STORE_KEY, value: selected.join(',') }]);
					}}
					allowMultiple
				/>
			),
			shortcut: true,
		},
		{
			key: ESTIMATED_ARRIVAL_KEY,
			label: messages.estimatedArrival,
			filter: (
				<ChoiceList
					title={messages.estimatedArrival}
					titleHidden
					choices={etaChoices}
					selected={selectedETA ? [selectedETA] : []}
					onChange={(selected) => {
						setFilters([{ key: ESTIMATED_ARRIVAL_KEY, value: selected.join(',') }]);
					}}
				/>
			),
			shortcut: true,
		},
	];

	const appliedFilters: AppliedFilterInterface[] = [
		...(selectedReturnType
			? [
					{
						key: RETURN_TYPE_KEY,
						label: messages.returnTypes[selectedReturnType as keyof typeof messages.returnTypes],
						onRemove: () => {
							setFilters([{ key: RETURN_TYPE_KEY, value: '' }]);
						},
					},
			  ]
			: []),
		...(selectedStores
			? [
					{
						key: STORE_KEY,
						label: selectedStores.join(', '),
						onRemove: () => {
							setFilters([{ key: STORE_KEY, value: '' }]);
						},
					},
			  ]
			: []),
		...(selectedETA
			? [
					{
						key: ESTIMATED_ARRIVAL_KEY,
						label:
							selectedETA === 'TODAY'
								? messages.arrivingToday
								: selectedETA === 'LATE'
								? messages.arrivingLate
								: (translate(messages.arrivingWithinDays, {
										count: parseInt(selectedETA),
								  }) as string),
						onRemove: () => {
							setFilters([{ key: ESTIMATED_ARRIVAL_KEY, value: '' }]);
						},
					},
			  ]
			: []),
	];

	const sortChoices: SortChoice[] = [
		{
			label: messages.sortOptions.storeAsc,
			value: `${ReturnsSummaryOrderByField.Merchant} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.storeDsc,
			value: `${ReturnsSummaryOrderByField.Merchant} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.progressDesc,
			value: `${ReturnsSummaryOrderByField.ReturnStatus} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.progressAsc,
			value: `${ReturnsSummaryOrderByField.ReturnStatus} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.expectedUnitsDesc,
			value: `${ReturnsSummaryOrderByField.QuantityExpected} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.expectedUnitsAsc,
			value: `${ReturnsSummaryOrderByField.QuantityExpected} ${OrderByDirection.Asc}`,
		},
	];

	const onClearAll = () => {
		setFilters([{ key: queryKey, value: '' }]);
	};

	return error ? (
		<Error graphQLError={error} />
	) : (
		<Page fullWidth title={messages.returnShipments}>
			<Layout>
				<Layout.Section>
					<Card>
						<Card.Section>
							<div style={{ paddingBottom: '2rem' }}>
								<ViewTabs
									tabs={views}
									selected={view || views?.[0]?.id}
									onSelect={(id) => setFilters([{ key: viewKey, value: id }])}
								/>
							</div>
							<Filters
								queryValue={query || undefined}
								queryPlaceholder={messages.filterInboundReturns}
								onQueryChange={(value) => setFilters([{ key: queryKey, value }])}
								onQueryClear={() => setFilters([{ key: queryKey, value: '' }])}
								filters={filters || []}
								appliedFilters={appliedFilters}
								onClearAll={onClearAll}
							>
								<SortBy
									choices={sortChoices}
									selected={sort ? [sort] : []}
									onChange={(selected) => setFilters([{ key: sortKey, value: selected[0] }])}
								/>
							</Filters>
						</Card.Section>
						<InboundReturnsTable data={returnsSummariesData?.returnsSummaries} loading={fetching} />
					</Card>
				</Layout.Section>
			</Layout>
			<TimezoneFooter />
		</Page>
	);
}
