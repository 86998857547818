module.exports = {
  "language": "Idioma",
  "products": "Productos",
  "addProduct": "Añadir producto",
  "countLines": {
    "one": "{count} línea",
    "other": "{count} líneas"
  },
  "acceptedFileFormats": "Formatos de archivos aceptados: JPEG, PNG, PDF",
  "account": "Cuenta",
  "action": "Acción",
  "actionLog": "Registro de acción",
  "active": "Activo",
  "activeEmployees": "Empleados activos",
  "activity": "Actividad",
  "actualArrival": "Llegada real",
  "addAttachments": "Añadir anexos",
  "addContainer": "Añadir contenedor",
  "addEmployee": "Añadir empleado",
  "addImage": "Añadir imagen",
  "addImageFromUrl": "Añadir imagen desde URL",
  "addImages": "Añadir imágenes",
  "addInternalNote": "Añadir nota interna",
  "addLocation": "Añadir ubicación",
  "addNote": "Añadir nota",
  "addNoteFailed": "Ocurrió un error al añadir la nota",
  "addNoteToShipment": "Añadir nota al envío",
  "address": "Dirección",
  "aisleId": "ID del pasillo",
  "all": "Todo",
  "allChucks": "Todos los chucks",
  "allContainers": "Todos los contenedores",
  "allLocations": "Todas las ubicaciones",
  "allProducts": "Todos los productos",
  "allWorkAreas": "Todas las áreas de trabajo",
  "allocationRules": "Reglas de adjudicación",
  "allowAccessToDevices": "Permitir el acceso a dispositivos de 6RS (chucks, terminales, portátiles)",
  "allowAccessToTheBridge": "Permitir el acceso a The Bridge",
  "analytics": "Analíticas",
  "apply": "Aplicar",
  "arrived": "Llegó",
  "arrivingToday": "Llegando hoy",
  "asnIssueReason": {
    "Barcode": "Código de barras faltante",
    "CancelledByAPI": "Cancelado por API",
    "CancelledByAdmin": "Cancelado por Admin",
    "Damaged": "Dañado",
    "Ineligible": "SKU inelegible",
    "ItemNotOnAsn": "El artículo no está en ASN",
    "MixedCartons": "Cartones mezclados",
    "NoAppointment": "Sin cita",
    "Other": "Otro problema",
    "Overage": "Sobreinventario",
    "ShipmentNotLabelled": "Envío no etiquetado",
    "Shortage": "Escasez",
    "UnsafeToUnload": "No es seguro descargar",
    "NeedsRework": "Necesita reelaboración"
  },
  "assignBatchId": "Asignar ID del lote",
  "assigned": "Asignado",
  "assignedBy": "Asignado por",
  "assignedKiosk": "Kiosco asignado",
  "assignedToBatch": "Asignado a lote",
  "assignedToWall": "Asignado a pared",
  "associate": "Asociado",
  "associateName": "Nombre del asociado",
  "associateNotFound": "No se encontró el asociado",
  "atRiskSLA": "En riesgo de perder el Acuerdo de nivel de servicio (SLA)",
  "attachImages": "Añadir imagen",
  "attachments": "Adjuntos",
  "auditCount": "Conteo de auditoría",
  "auditRequired": "Se requiere auditoría",
  "autoRefreshDisabled": "Actualización automática desactivada",
  "autoRefreshEnabled": "Actualización automática activada",
  "autoRefreshHint": "La actualización automática se produce cada 20 segundos cuando está activada",
  "available": "Disponible",
  "awaitingInventory": "Esperando inventario",
  "badgeBarcode": "Código de barras de credencial",
  "balance": "Saldo",
  "barcode": "Código de barras",
  "barcodeHelpText": "El código de barras de inicio de sesión debe ser único por empleado",
  "barcodeUniquePerUser": "El código de barras de la credencial debe ser único por usuario",
  "barcodesUniquePerContainer": "Los códigos de barras deben ser únicos por contenedor",
  "batch": "Lote",
  "batchCreated": "Lote creado",
  "batchId": "ID del lote",
  "batchState": "Estado del lote",
  "battery": "Batería",
  "batteryLevel": "Nivel de batería",
  "binHealth": "Salud del recipiente",
  "breakdown": "Desglose",
  "bridgeAccess": "Acceso a The Bridge",
  "bridgeAccessDescription": "Los usuarios de The Bridge recibirán un correo electrónico para establecer su contraseña de acceso a The Bridge.",
  "browserOffline": "Navegador fuera de línea",
  "bulkCancelErrors": {
    "alreadyCancelled": "Ya cancelado",
    "alreadyCompleted": "Ya completo",
    "alreadyInducted": "Ya introducido",
    "notFound": "No se encontró el ID",
    "unknown": "Desconocido"
  },
  "bulkOrderWorkNotStarted": "Aún no se ha iniciado el trabajo",
  "bulkOrders": "Pedidos por volumen",
  "cancellationRequested": "Cancelación solicitada",
  "cancel": "Cancelar",
  "cancelJob": "Cancelar trabajo",
  "cancelOrder": "Cancelar pedido",
  "cancelOrderSubmitted": "Anulación de pedido enviada. Esto puede tomar tiempo.",
  "cancelOrders": "Cancelar pedidos",
  "cancelReplenJob": "Cancelar trabajo de reabastecimiento",
  "cancelShipment": "Cancelar envío",
  "cancelSortWall": "Cancelar pared de clasificación",
  "cancelSortWallConfirm": "Las paredes canceladas no serán procesadas. ¿Desea continuar?",
  "cancelWall": "Cancelar pared",
  "canceled": "Cancelado",
  "canceledToday": "Cancelado hoy",
  "capacityType": "Tipo de capacidad",
  "carrier": "Transportista",
  "carrierCutoff": "Corte del transportista",
  "changeFilters": "Intente cambiar los filtros o el término de búsqueda",
  "checkConnection": "Revise su conexión de red",
  "checkNetwork": "Revise su WiFi o el estado de su red local y vuelva a cargar la página",
  "chuck": "Chuck",
  "chuckQueue": "Cola de chucks",
  "chuckStates": {
    "charging": "Cargando",
    "dwelling": "Inactivo/en pausa {minutes}+ minutos",
    "dwellingIdle": "Inactivo {minutes}+ minutos",
    "dwellingPaused": "En pausa {minutes}+ minutos",
    "faulty": "Defectuoso",
    "lowBattery": "Batería baja",
    "offline": "Fuera de línea",
    "online": "En línea",
    "paused": "En pausa"
  },
  "chuckWorkspace": "Espacio de trabajo de chuck",
  "chucks": "Chucks",
  "clear": "Libre",
  "cleared": "Liberado",
  "close": "Cerrar",
  "closeBatch": "Cerrar lote",
  "closeReturn": "Cerrar devolución",
  "closeShipment": "Cerrar envío",
  "closed": "Cerrado",
  "collectBatchDescription": "Este lote recolecte más pedidos de este producto.",
  "collecting": "Recolectando",
  "columns": "Columnas",
  "completed": "Completo",
  "completedAt": "Completo",
  "completedSince": "Completados desde",
  "completedToday": "Completado hoy",
  "confirmArriveShipment": "¿Está seguro de que desea marcar este envío como que llegó?",
  "confirmBulkCancelOrders": {
    "one": "Está a punto de cancelar {count} pedido.",
    "other": "Está a punto de cancelar {count} pedidos."
  },
  "confirmBulkOrderCloseBatch": "¿Está seguro de que quiere impedir que este lote recolecte pedidos? Esta acción no puede ser anulada.",
  "confirmCancelJobs": "Los trabajos cancelados no serán procesados. ¿Desea continuar?",
  "confirmCancelOrder": "Los pedidos cancelados no serán procesados. ¿Desea continuar?",
  "confirmCancelReplen": "Los artículos no serán reabastecidos. ¿Desea continuar?",
  "confirmCancelReplenFromLocation": "Si cancela este trabajo, los artículos no serán reabastecidos. Devolver los artículos a {location}.",
  "confirmCancelShipment": "El aviso de envío no puede ser modificado después de ser cancelado.",
  "confirmCloseReturn": "¿Está seguro de que desea cerrar esta devolución?",
  "confirmCloseShipment": "¿Está seguro de que desea cerrar este envío de entrada? Las notas de emisión y los anexos serán visibles para el servicio de asistencia técnica de SFN cuando se cierre y no se podrán realizar cambios",
  "confirmDeactivateEmployee": "¿Está seguro de que desea desactivar a este empleado?",
  "confirmDeleteContainer": "Este contenedor será eliminado. ¿Desea continuar?",
  "confirmDeleteLocation": "Esta ubicación será eliminada. ¿Desea continuar?",
  "confirmDeleteProduct": "Este producto será eliminado. ¿Desea continuar?",
  "confirmDeleteUpload": "¿Está seguro de que desea eliminar esta carga?",
  "confirmDeleteWorkLog": "¿Está seguro de que desea eliminar este registro de trabajo? Esta acción no puede ser anulada.",
  "confirmRemoveImage": "¿Está seguro de que desea quitar esta imagen?",
  "confirmWait": "Esto puede tomar tiempo. ¿Desea continuar?",
  "conflictState": "Situación del conflicto",
  "conflicts": "Conflictos",
  "connectionInterrupted": "Esta página no se ha podido visualizar porque la conexión a Internet se ha interrumpido.",
  "contactAdministrator": "Contacte a su administrador para pedir asistencia.",
  "container": "Contenedor",
  "containerBarcodeMustBeUnique": "El código de barras de la credencial debe ser único por contenedor",
  "containerDetails": "Detalles del contenedor",
  "containerDimensions": "Dimensiones del contenedor",
  "containerExternalIdPurpose": "El ID externo es la forma en que se identifica un contenedor en las pickwaves y demás comunicaciones entre 6RS y el software externo.",
  "containerHasSubections": "Este contenedor tiene subsecciones",
  "containerImage": "Imagen del contenedor",
  "containerInactive": "Contenedor inactivo",
  "containerName": "Nombre del contenedor",
  "containerTracked": "El contenedor de envío es monitoreado como inventario.",
  "containerType": "Tipo de contenedor",
  "containerTypes": {
    "picking": "Picking",
    "shipping": "Enviando",
    "storage": "Almacenamiento",
    "unknown": "Desconocido"
  },
  "containers": "Contenedores",
  "containersNotFound": "No se encontraron contenedores",
  "coordinates": "Coordenadas",
  "copiedToClipboard": "Copiado al portapapeles",
  "copyFailed": "¡Ups! Error al copiar",
  "copyLicensePlate": "Copiar placa",
  "copyOrderNumber": "Copiar número de pedido",
  "copyShipmentNumber": "Copiar número de envío",
  "copyToClipboard": "Copiar al portapapeles",
  "countCanceled": {
    "one": "{count} cancelado",
    "other": "{count} cancelados"
  },
  "countCollected": {
    "one": "{count} recolectado",
    "other": "{count} recolectados"
  },
  "countExceptions": {
    "one": "{count} excepción",
    "other": "{count} excepciones"
  },
  "countFailedToCancel": {
    "one": "{count} no se pudo cancelar",
    "other": "{count} no se pudieron cancelar"
  },
  "countInUseBySkus": {
    "one": "En uso por {count} SKU",
    "other": "En uso por {count} SKUs"
  },
  "countJobs": {
    "one": "{count} trabajo",
    "other": "{count} trabajos"
  },
  "countLastHours": {
    "one": "Última {count} hora",
    "other": "Últimas {count} horas"
  },
  "countLocations": {
    "one": "{count} ubicación",
    "other": "{count} ubicaciones"
  },
  "countOffline": {
    "one": "{count} chuck fuera de línea",
    "other": "{count} chucks fuera de línea"
  },
  "countOnline": {
    "one": "{count} chuck en línea",
    "other": "{count} chucks en línea"
  },
  "countOrders": {
    "one": "{count} pedido",
    "other": "{count} pedidos"
  },
  "countPacked": {
    "one": "{count} empaquetado",
    "other": "{count} empaquetados"
  },
  "countRequired": {
    "one": "{count} requerido",
    "other": "{count} requeridos"
  },
  "countShipments": {
    "one": "{count} envío",
    "other": "{count} envíos"
  },
  "countSkus": {
    "one": "{count} SKU",
    "other": "{count} SKUs"
  },
  "countUnits": {
    "one": "{count} unidad",
    "other": "{count} unidades"
  },
  "countUnitsAdded": {
    "one": "{count} unidad añadida",
    "other": "{count} unidades añadidas"
  },
  "countUnitsCounted": {
    "one": "{count} unidad contada",
    "other": "{count} unidades contadas"
  },
  "countUnitsRemoved": {
    "one": "{count} unidad eliminada",
    "other": "{count} unidades eliminadas"
  },
  "create": "Crear",
  "createBatch": "Crear lote",
  "createBatchDescription": "Crear lote con pedidos unitarios, de una línea.",
  "createCustomBatch": "Crear lote personalizado",
  "createNewTag": "Crear una nueva etiqueta",
  "createWall": "Crear pared",
  "createdAt": "Creado",
  "createdBatches": "Lotes creados",
  "cutoff": "Corte",
  "damaged": "Dañado",
  "dashCountJobs": "Trabajos de conteo",
  "dashMoveJobs": "Trabajos de movimiento",
  "dashboard": "Panel de control",
  "dataNotFound": "No se encontraron los datos que solicitó",
  "dataNotSaved": "Hubo un problema al guardar sus datos.",
  "dataSaved": "Sus datos fueron guardados.",
  "dataUpdated": "{name} actualizado",
  "date": "Fecha",
  "dateRange": "Rango de fechas",
  "dateRangeOptions": {
    "custom": "Personalizado",
    "last30Days": "Últimos 30 días",
    "last60Days": "Últimos 60 días",
    "last7Days": "Últimos 7 días",
    "last90Days": "Últimos 90 días",
    "today": "Hoy",
    "yesterday": "Ayer",
    "allTime": "Todas las horas",
    "last180Days": "Últimos 180 días",
    "last3Days": "Últimos 3 días"
  },
  "deactivateEmployee": "Desactivar empleado",
  "delete": "Eliminar",
  "deleteContainer": "Eliminar contenedor",
  "deleteDisabledWorkLogHelpText": "Las tareas registradas en el sistema no se pueden eliminar.",
  "deleteLocation": "Eliminar ubicación",
  "deleteProduct": "Eliminar producto",
  "deleteUpload": "Eliminar carga",
  "deleteWorkLog": "Eliminar registro de trabajo",
  "description": "Descripción",
  "destination": "Destino",
  "details": "Detalles",
  "device": "Dispositivo",
  "deviceAccess": "Acceso a dispositivo",
  "deviceAccessDescription": "El código de barras de la credencial se utiliza para acceder a los dispositivos 6RS y debe ser único por empleado.",
  "deviceNotFound": "Dispositivo no encontrado",
  "deviceTypes": {
    "Chuck": "Chuck",
    "Handheld": "Portátil",
    "PackoutTerminal": "Estación de empaquetado",
    "SortationKiosk": "Kiosco de la pared de clasificación",
    "TerminalOnWheels": "Terminal sobre ruedas"
  },
  "devices": "Dispositivos",
  "devicesNotFound": "No se encontraron dispositivos",
  "diffDue": "Vencimiento {dateDiff}",
  "diffExpected": "Previsto {dateDiff}",
  "diffLastMoved": "Se movió por última vez {dateDiff}",
  "diffLastSeen": "Visto por última vez {dateDiff}",
  "diffUpdated": "Actualizado {dateDiff}",
  "dimensions": "Dimensiones",
  "disabled": "Desactivado",
  "discard": "Descartar",
  "disconnected": "Desconectado",
  "discrepancyTypes": {
    "Miscount": "Se sospecha discrepancia debido a un error de conteo",
    "NegativeQuantity": "Se sospecha discrepancia debido a cantidad negativa",
    "Other": "Se sospecha discrepancia en esta ubicación",
    "PickShort": "Se sospecha discrepancia debido a picking corto"
  },
  "durationInHours": "{decimalHours} horas",
  "earliestCutoff": "Corte del transportista más rápido",
  "edit": "Editar",
  "editEmployee": "Editar empleado",
  "editIssues": "Editar problemas",
  "editNote": "Editar nota",
  "editWorkLog": "Editar registro de trabajo",
  "email": "Correo electrónico",
  "emailOptional": "Correo electrónico (opcional)",
  "employee": "Empleado",
  "employeeDeactivated": "Empleado desactivado",
  "employeeDetails": "Detalles del empleado",
  "employeeName": "Nombre del empleado",
  "employeeReactivated": "Empleado reactivado",
  "employeeRoles": {
    "admin": "Admin",
    "cfs_operator": "Administrador de almacén",
    "cfs_read_only": "Asociado de Bridge",
    "chuck_operator": "Asociado de piso",
    "integration": "Integración",
    "user": "Usuario",
    "user_admin": "Administrador de empleado"
  },
  "employeeTagsDescription": "Las etiquetas pueden utilizarse para clasificar a los empleados en grupos.",
  "employees": "Empleados",
  "employeesNotFound": "No se encontraron empleados",
  "enableContainer": "Habilitar contenedor",
  "enabled": "Habilitado",
  "endDate": "Fecha de finalización",
  "endTime": "Hora de finalización",
  "enterNote": "Ingresar una nota.",
  "error": "Error",
  "errorSubtitle": "Esta tarea no se pudo completar. Contacte al soporte de 6RS.",
  "errorTitle": "Algo salió mal.",
  "errorToast": "¡Ups! Algo salió mal. Contacte al soporte de 6RS.",
  "estimatedArrival": "Llegada estimada",
  "exampleNotToScale": "Ejemplo (no a escala)",
  "exception": "Excepción",
  "exceptionCleared": "Excepción resuelta",
  "exceptionReason": "Razón de la excepción",
  "exceptionReasons": {
    "damaged": "Artículo dañado",
    "full": "Caja muy llena",
    "miscount": "Error de conteo",
    "missing": "Artículo faltante",
    "negativeQuantity": "Cantidad negativa",
    "offline": "Fuera de línea",
    "otherReason": "Otro",
    "pickShort": "Picking short"
  },
  "exceptionStatus": "Estatus de excepción",
  "exceptionTypes": {
    "DAMAGED_PRODUCT": "Dañado",
    "MISSING_PRODUCT": "Faltante",
    "WORKFLOW_EXCEPTION": "Otro"
  },
  "exceptions": "Excepciones",
  "exceptionsMissing": "No se utilizan todas las piezas del kit. Informe de los problemas antes de marcar el proyecto como completo.",
  "exceptionsNotFound": "No se encontraron excepciones",
  "exceptionsToday": "Excepciones hoy",
  "exceptionsTooltip": "Se han reportado excepciones para este trabajo.",
  "expected": "Previsto",
  "expectedBalance": "Saldo previsto",
  "expectedShipDate": "Fecha de envío prevista",
  "expired": "Expirada",
  "externalId": "ID externo",
  "fai": "FAI",
  "faiErrorMessage": "La primera inspección del artículo debe completarse antes de marcar el proyecto como completo.",
  "faiErrorTitle": "Se requiere la primera inspección del artículo",
  "faiStatusComplete": "Primera inspección del artículo: completa",
  "faiStatusIncomplete": "Primera inspección del artículo: incompleta",
  "feedback": "Retroalimentación",
  "feedbackAction": "Dar retroalimentación",
  "feedbackHeading": "Denos retroalimentación",
  "feedbackRequest": "Responda a algunas preguntas sobre su experiencia en el uso de The Bridge para informar sobre las mejoras y las nuevas funciones.",
  "fieldGreaterThanOrEqualToZero": "{field} debe ser mayor o igual a 0",
  "fieldRequired": "Se requiere un valor para {field}",
  "fileTooLarge": "Este archivo es muy grande. Debe ser menor de 5MB.",
  "fileTypeNotSupported": "Este tipo de archivo no es compatible. El tipo de archivo debe ser: .gif, .jpg o .png.",
  "filterContainers": "Filtrar contenedores por nombre o descripción",
  "filterDevices": "Filtrar dispositivo por nombre",
  "filterEmployees": "Filtrar empleados por nombre, correo electrónico, credencial o etiqueta",
  "filterExceptions": "Filtrar excepciones por ID del pedido, placa o placa inicial",
  "filterInboundReturns": "Filtrar devoluciones por ID del pedido o ID de monitoreo",
  "filterInboundShipments": "Filtrar envíos ID de recepción o nombre de tienda",
  "filterLocations": "Filtrar ubicaciones por dirección",
  "filterOrders": "Filtrar pedidos por ID del pedido",
  "filterOutboundJobs": "Filtrar los trabajos de salida por placa, ID del pedido o ID del chuck",
  "filterProducts": "Filtrar producto por ID, SKU o descripción de artículo",
  "filterReplenJobs": "Filtrar trabajos por placa, ID del producto o ubicación de destino",
  "filterSortWalls": "Filtrar paredes de clasificación por ID del lote",
  "filterSpecialProjects": "Filtrar proyectos especiales por ID",
  "findThisChuck": "Encontrar este chuck",
  "firstArticleInspection": "Primera inspección del artículo",
  "floorView": "Vista de planta",
  "frequentlyUsedTags": "Etiquetas de uso frecuente",
  "fullName": "Nombre completo",
  "fullness": "Llenura",
  "fullnessTypes": {
    "empty": "Vacío",
    "full": "Lleno",
    "lessThanFiftyPercentFull": "Lleno a menos del 50 %",
    "lessThanTenPercentFull": "Lleno a menos del 10 %"
  },
  "generateBarcode": "Generar código de barras",
  "goBack": "Ir atrás",
  "handheld": "Portátil",
  "handhelds": "Portátiles",
  "hasValidationErrors": "Corrija los campos marcados en rojo.",
  "height": "Altura",
  "hideDetails": "Ocultar detalles",
  "history": "Historia",
  "hours": "Horas",
  "identifier": "Identificador",
  "identifiers": "Identificadores",
  "idle": "Inactivo",
  "image": "Imagen",
  "imageURL": "URL de imagen",
  "imageUploadError": "Las siguientes imágenes no han podido ser cargadas:",
  "images": "Imágenes",
  "inProgress": "En proceso",
  "inTransit": "En tránsito",
  "inUse": "En uso",
  "inactive": "Inactivo",
  "inactiveEmployees": "Empleados inactivos",
  "inbound": "De entrada",
  "inboundShipment": "Envíos de entrada",
  "inboundShipments": "Envío de entrada",
  "induct": "Introducir",
  "inducted": "Introducido",
  "inductedAt": "Introducido",
  "initialJob": "Trabajo inicial",
  "initialLicensePlate": "Placa inicial",
  "inspection": "Inspección",
  "inspectionCount": "Conteo de inspección",
  "instructions": "Instrucciones",
  "instructionsNotFound": "Instrucciones no disponibles",
  "internalNote": "Nota interna",
  "internalNotes": "Notas internas",
  "interrupted": "Interrumpido",
  "invalidDateFormat": "Formato de fecha inválido",
  "invalidImage": "Imagen inválida",
  "invalidTimeRange": "Rango de tiempo inválido",
  "inventory": "Inventario",
  "inventoryTracking": "Monitoreo de inventario",
  "inventoryTrackingStates": {
    "all": "Todos los contenedores",
    "tracked": "Contenedores monitoreados como inventario",
    "untracked": "Contenedores no monitoreados como inventario"
  },
  "issue": "Problema",
  "issueNotesAndAttachmentsMessage": "Las notas y los anexos serán visibles para el comerciante cuando se cierre la transferencia de entrada.",
  "issueReasons": {
    "MissingItem": "Artículo faltante",
    "UnknownItem": "Artículo desconocido"
  },
  "issues": "Problemas",
  "item": "Artículo",
  "itemPicked": "Artículo {externalId} recolectado en {address}",
  "itemPickedWithException": "Artículo {externalId} recolectado en {address} con excepción",
  "itemRepicked": "Artículo {externalId} recolectado nuevamente en {address}",
  "itemRepickedWithException": "Artículo {externalId} recolectado nuevamente en {address} con excepción",
  "jobAssigned": "Trabajo {jobId} asignado en la introducción",
  "jobBasedOnBatchingJob": "Este trabajo se crea para picking de los artículos pendientes en función de un trabajo por lotes.",
  "jobCanceled": "Trabajo cancelado",
  "jobCreated": "Trabajo creado",
  "jobExceptionsCleared": "Las excepciones reportadas para este trabajo han sido resueltas.",
  "jobExceptionsReported": "Se han reportado excepciones para este trabajo.",
  "jobHasUnpickedItems": "Este trabajo tiene artículos sin recoger",
  "jobInterruptedHint": "Este trabajo ya no está activo porque el chuck se cerró inesperadamente.",
  "jobStatus": "Estatus del trabajo",
  "jobs": "Trabajos",
  "justUpdated": "Actualizado recientemente",
  "keepEditing": "Conservar edición",
  "keepJob": "Conservar trabajo",
  "keepOrder": "Conservar pedido",
  "keepOrders": "Conservar pedidos",
  "keepSortWall": "Conservar pared de clasificación",
  "kit": "Kit",
  "kitIssues": {
    "damagedProduct": "Dañado",
    "missingProduct": "Faltante",
    "otherIssue": "Otro"
  },
  "kitsRequired": "Kits requeridos",
  "labelPrinted": "Etiquetas impresas",
  "labels": "Etiquetas",
  "labelsPrinted": "Etiquetas impresas",
  "last30Days": "Últimos 30 días",
  "last60Days": "Últimos 60 días",
  "last90Days": "Últimos 90 días",
  "lastActive": "Última vez activo",
  "lastCalibrated": "Última vez calibrado",
  "lastHour": "Última hora",
  "lastScan": "Último escaneo",
  "late": "Retrasado",
  "length": "Largo",
  "licensePlate": "Placa",
  "line": "Línea",
  "lines": "Líneas",
  "linesNotFound": "No se encontraron líneas",
  "linesPicked": "Líneas recolectadas",
  "linesReplenished": "Líneas reabastecidas",
  "loadMore": "Cargar más",
  "loading": "Cargando",
  "location": "Ubicación",
  "locationAvailable": "Ubicación disponible",
  "locationDetails": "Detalles de ubicación",
  "locationHistory": "Historial de ubicación",
  "locationInformation": "Información de ubicación",
  "locationNotFound": "Ubicación de almacenamiento no encontrada",
  "locationType": "Tipo de ubicación",
  "locationTypes": {
    "bin": "Recipiente",
    "cart": "Carrito",
    "damaged": "Recipiente de lo dañado",
    "dock": "Atracadero",
    "external": "Externo",
    "mfp": "Chuck",
    "pallet": "Tarima",
    "rack": "Estante",
    "reserve": "Reservar",
    "slot": "Espacio",
    "staging": "Transición",
    "unknown": "Desconocido",
    "warehouse": "Almacén"
  },
  "locations": "Ubicaciones",
  "locationsEmptyTitle": "Vacía de ubicaciones",
  "locationsNotFound": "No se encontraron ubicaciones",
  "logWork": "Registrar trabajo",
  "loggedOff": "Desconectado",
  "loginBarcodesDescription": "El código de barras de inicio de sesión se utiliza para acceder a los dispositivos 6RS y debe ser único por empleado.",
  "logsNotFound": "No se encontraron registros",
  "lpn": "N.° de placa",
  "manageExceptions": "Administrar excepciones",
  "manageExceptionsForOrder": "Administrar excepciones para este pedido",
  "manuallyResolved": "Resuelto manualmente",
  "markAsArrived": "Marcar como llegado",
  "markAsComplete": "Marcar como completo",
  "markResolved": "Marcar como resuelto",
  "maxCharacter500": "Máximo de 500 caracteres",
  "meetingPoint": "Punto de encuentro",
  "model": "Modelo",
  "motionStates": {
    "charging": "Cargando",
    "docking": "Atracando",
    "idle": "Inactivo",
    "loading": "Cargando",
    "paused": "En pausa",
    "traveling": "Viajando"
  },
  "multiple": "Múltiple",
  "name": "Nombre",
  "network": "Red",
  "new": "Nuevo",
  "newContainer": "Nuevo contenedor",
  "newProjects": "Nuevos proyectos",
  "noAccess": "Sin acceso",
  "noDataFound": "No se encontraron datos",
  "noJobsToAllocate": "No hay trabajos para adjudicar a la pared de clasificación móvil.",
  "noNotesToDisplay": "Sin notas que mostrar",
  "noOrdersFound": "No se encontraron pedidos",
  "noOutboundJobsFound": "No se encontraron trabajos salientes",
  "noReplenJobsFound": "No se encontraron trabajos de reabastecimiento",
  "noReturnShipmentsFound": "No se encontraron envíos de devolución",
  "noReturnedInventoryFound": "No se encontró inventario devuelto",
  "noSortWallsFound": "No se encontraron paredes de clasificación",
  "noWorkLogged": "No se registró trabajo.",
  "notAuthorized": "No está autorizado para ver esta página",
  "notInUse": "No está en uso",
  "notReceived": "No recibido",
  "notes": "Notas",
  "notesAndAttatchmentsVisible": "Las notas y los anexos serán visibles para el comerciante cuando se complete la inspección de devolución.",
  "offline": "Fuera de línea",
  "oops": "¡Ups!",
  "optional": "Opcional",
  "order": "Pedido",
  "orderAccepted": "Pedido aceptado",
  "orderCancelled": "Pedido cancelado",
  "orderCompleted": "Pedido completado",
  "orderHistory": "Historial de pedidos",
  "orderId": "ID del pedido",
  "orderNotFound": "Pedido no encontrado",
  "orderPicked": "Pedido recolectado",
  "orderProgress": "Progreso del pedido",
  "orderReceived": "Pedido recibido",
  "orderSorted": "Pedido clasificado",
  "orders": "Pedidos",
  "ordersCollected": "Pedidos recolectados",
  "ordersPacked": "Pedidos empaquetados",
  "originLocation": "Ubicación de origen",
  "originalJob": "Trabajo original",
  "originalOrder": "Pedido original",
  "otherType": "Otro",
  "outbound": "Saliente",
  "outboundJobHistory": "Historial de trabajos salientes",
  "outboundJobNotFound": "Trabajo saliente no encontrado",
  "outboundJobs": "Trabajos salientes",
  "overview": "Vista general",
  "packStation": "Estación de empaquetado",
  "packStations": "Estaciones de empaquetado",
  "packageType": "Tipo de empaque",
  "packed": "Empaquetado",
  "packedBy": "Empaquetado por",
  "packing": "Empaquetando",
  "packingDetails": "Detalles de empaquetado",
  "packingEndedAt": "Empaquetado finalizado",
  "packingStartedAt": "Empaquetado iniciado",
  "packoutCompleted": "Área de empaquetado completa",
  "packoutCompletedAt": "Área de empaquetado completa",
  "packoutRequestId": "ID de solicitud de área de empaquetado",
  "packoutRequired": "Área de empaquetado requerido",
  "parts": "Partes",
  "password": "Contraseña",
  "passwordConfirmation": "Confirmar contraseña",
  "passwordsShouldMatch": "Las contraseñas deben coincidir",
  "pasteImageUrl": "Pegar URL de la imagen",
  "paused": "En pausa",
  "pending": "Pendiente",
  "percentFull": "{percent} lleno",
  "performance": "Desempeño",
  "phoneNumber": "Número telefónico",
  "pickStrategies": {
    "bulk": "Pedido por volumen",
    "consolidation": "Se debe enviar completo",
    "discrete": "Pedido único",
    "group": "Permitir envíos separados",
    "singles": "Lote de única línea",
    "sortation": "Picking en pared de clasificación"
  },
  "pickStrategy": "Estrategia de picking",
  "picked": "Picked",
  "pickedOfTotal": "{picked} de {total}",
  "picking": "Picking",
  "pickingCompletedAt": "Picking completado",
  "pickingContainers": "Picking de contenedores",
  "pickingDetails": "Detalles de picking",
  "pickingExceptions": "Excepciones de picking",
  "pickingJobs": "Trabajos de picking",
  "pickingJobsNotFound": "No se encontraron trabajos de picking",
  "pickingLabel": "Etiqueta de picking",
  "pickingProgress": "Progreso de picking",
  "plannedRemovals": "Eliminaciones planeadas",
  "pleaseCorrectForm": "Por favor, corrija los siguientes artículos:",
  "possibleResolution": "Resolución posible",
  "printLabel": "Imprimir etiqueta",
  "problemLoadingHistory": "Hay un problema cargando este historial",
  "problemLoadingPage": "Hay un problema cargando esta página",
  "proceed": "Proceder",
  "product": "Producto",
  "productAttributes": "Atributos del producto",
  "productDetails": "Detalles del producto",
  "productImage": "Imagen del producto",
  "productIsContainer": "Este producto es un contenedor de envío",
  "productType": "Tipo de producto",
  "productTypes": {
    "packaging": "Consumible de área de empaquetado",
    "product": "Inventario vendible"
  },
  "productsDamagedOrMissing": "¿Hubo algún producto dañado o faltante?",
  "productsNotFound": "No se encontraron productos",
  "profile": "Perfil",
  "progress": "Progreso",
  "projectId": "ID de proyecto",
  "projectNotFound": "Proyecto no encontrado",
  "quantity": "Cantidad",
  "quantityAdjusted": "Cantidad ajustada a {quantity}",
  "reactivateEmployee": "Reactivar empleado",
  "readyToPack": "Listo para empaquetar",
  "readyToSort": "Listo para clasificar",
  "reason": "Razón",
  "reasonForFailure": "Razón del fallo",
  "received": "Recibido",
  "receivedAt": "Recibido",
  "receiving": "Recepción",
  "receivingId": "ID de recepción",
  "receivingIssue": "Problema de recepción",
  "receivingStartedAt": "Recepción iniciada",
  "receivingTime": "Hora de recepción",
  "regarding": "Al respecto",
  "reloadPage": "Recargar esta página",
  "remaining": "Restante",
  "remainingCapacity": "Capacidad restante",
  "remove": "Eliminar",
  "removeAttachmentFailed": "Ocurrió un error al eliminar el anexo",
  "removeImage": "Eliminar imagen",
  "replenExceptionTypes": {
    "DamagedProduct": "Producto dañado",
    "LocationTooFull": "Ubicación demasiado llena",
    "MissingProduct": "Producto faltante",
    "Unknown": "Excepción desconocida",
    "WrongProduct": "Producto incorrecto"
  },
  "replenishJobs": "Trabajos de reabastecimiento",
  "report": "Informar",
  "reportIssues": "Informar problemas",
  "reportKittingIssues": "Informe de problemas cuando marque el proyecto como completado",
  "reprintLabel": "Reimprima etiqueta",
  "requestedAt": "Solicitado",
  "required": "Requerido",
  "reserved": "Reservado",
  "resolutionJob": "Trabajo de resolución",
  "resolutionJobAssigned": "Trabajo de resolución {jobId} asignado en el proceso de introducción",
  "resolutionJobs": "Trabajos de resolución",
  "resolutionJobsCreated": {
    "one": "Hay artículos sin elegir. {count} trabajo de resolución ha sido creado para procesar los artículos sin elegir.",
    "other": "Hay artículos sin elegir. {count} trabajos de resolución han sido creados para procesar los artículos sin elegir."
  },
  "resolutionLicensePlate": "Placa de resolución",
  "resolvable": "Se puede resolver",
  "resolvedToday": "Resuelto hoy",
  "resolving": "En resolución",
  "resourceIsRequired": "Se requiere {resource}",
  "result": "Resultado",
  "returnDetails": "Detalles de devolución",
  "returnIssues": "Problemas de devolución",
  "returnItemsToLocation": "Devolver los artículos a {location}",
  "returnShipmentByExternalId": "Devolver {externalId}",
  "returnShipments": "Devolver envíos",
  "returnType": "Tipo de devolución",
  "returnTypes": {
    "RMA": "Devolución de comprador",
    "RTS": "RTS"
  },
  "returnedInventory": "Inventario devuelto",
  "returns": "Devoluciones",
  "role": "Rol",
  "roles": "Roles",
  "rows": "Filas",
  "save": "Guardar",
  "saveError": "Hubo un problema al guardar sus cambios. Intente recargar la página.",
  "saveNoteFailed": "Ocurrió un error al guardar la nota",
  "searchBulkOrders": "Buscar por ID del lote, tienda, SKU o código de barras del producto",
  "searchFloorView": "Buscar por nombre del chuck",
  "searchName": "Buscar por nombre",
  "searchProduct": "Buscar productos por ID de artículo, SKU o descripción",
  "select": "Seleccionar",
  "selectProduct": "Seleccione un producto",
  "sellable": "Vendible",
  "service": "Servicio",
  "shipment": "Envío",
  "shipmentNotFound": "Envío de entrada no encontrado",
  "shipmentsWithIssues": "Envíos con problemas",
  "shippingContainers": "Enviando contenedores",
  "shippingNoticesNotFound": "No se encontraron notificaciones de envío",
  "showChuckList": "Mostrar lista de chucks",
  "showDetails": "Mostrar detalles",
  "signIn": "Iniciar sesión",
  "signOut": "Cerrar sesión",
  "sku": "SKU",
  "skusRequired": "SKUs requeridos",
  "sla": "Acuerdo de nivel de servicio (SLA)",
  "slot": "Espacio",
  "sort": "Clasificar",
  "sortByBatteryLevel": "Clasificar por nivel de batería",
  "sortByIdleTime": "Clasificar por tiempos muertos",
  "sortOptions": {
    "actArrivalTimeAsc": "Tiempo real de llegada (ascendente)",
    "actArrivalTimeDsc": "Tiempo real de llegada (descendente)",
    "addressAsc": "Dirección (A a la Z)",
    "batchIdAsc": "ID del lote (ascendente)",
    "batchIdDesc": "ID del lote (descendente)",
    "batteryLevelAsc": "Nivel de batería (ascendente)",
    "batteryLevelDesc": "Nivel de batería (descendente)",
    "containerNameAsc": "Nombre de contenedor (A-Z)",
    "containerNameDesc": "Nombre de contenedor (A-Z)",
    "cutoffAsc": "Corte (menos reciente)",
    "cutoffDesc": "Corte (más reciente)",
    "dateCreatedAsc": "Fecha de creación (más antigua)",
    "dateCreatedDesc": "Fecha de creación (más reciente)",
    "dateReceivedAsc": "Fecha de recepción (más antigua)",
    "dateReceivedDesc": "Fecha de recepción (más reciente)",
    "estArrivalTimeAsc": "Tiempo estimado de llegada (ascendente)",
    "estArrivalTimeDsc": "Tiempo estimado de llegada (descendente)",
    "expectedUnitsAsc": "Unidades previstas (ascendente)",
    "expectedUnitsDesc": "Unidades previstas (descendente)",
    "expectedUnitsPerShipmentAsc": "Unidades previstas por envío (ascendente)",
    "expectedUnitsPerShipmentDsc": "Unidades previstas por envío (descendente) ",
    "lastUsedAsc": "Menos activo recientemente",
    "lastUsedDesc": "Más activo recientemente",
    "linesPerShipmentAsc": "Líneas por envío (ascendente)",
    "linesPerShipmentDsc": "Líneas por envío (descendente)",
    "nameAsc": "Nombre (A-Z)",
    "nameDesc": "Nombre (Z-A)",
    "numOfIssuesAsc": "Número de problemas (ascendente)",
    "numOfIssuesDsc": "Número de problemas (descendente)",
    "orderNumberAsc": "Número de pedido (ascendente)",
    "orderNumberDesc": "Número de pedido (descendente)",
    "progressAsc": "Progreso (ascendente)",
    "progressDesc": "Progreso (descendente)",
    "projectIdAsc": "ID del proyecto (ascendente)",
    "projectIdDesc": "ID del proyecto (descendente)",
    "receivingIdAsc": "ID de recepción (ascendente)",
    "receivingIdDsc": "ID de recepción (descendente)",
    "storeAsc": "Tienda (A-Z)",
    "storeDsc": "Tienda (Z-A)",
    "addressDesc": "Dirección (Z a la A)",
    "fullnessAsc": "Llenura (baja a alta)",
    "fullnessDesc": "Llenura (alta a baja)",
    "quantityAsc": "Cantidad (baja a alta)",
    "quantityDesc": "Cantidad (alta a baja)"
  },
  "sortWall": "Pared de clasificación",
  "sortWallByName": "Pared de clasificación {name}",
  "sortWallCanceled": "Pared de clasificación cancelada",
  "sortWallId": "ID único de pared de clasificación",
  "sortWallKiosks": "Kioscos de pared de clasificación",
  "sortWallNotCreated": "Pared de clasificación no creada",
  "sortWallNotFound": "Pared de clasificación no encontrada",
  "sortWalls": "Paredes de clasificación",
  "sortationDetails": "Detalles de clasificación",
  "sortationExceptions": "Excepciones de clasificación",
  "sortationRequired": "Se requiere clasificación",
  "sorted": "Clasificado",
  "sorting": "Clasificando",
  "sourceLocation": "Ubicación de fuente",
  "specialInstructions": "Instrucciones especiales",
  "specialProject": "Proyecto especial",
  "specialProjectByExternalId": "Proyecto especial {externalId}",
  "specialProjectModalMessage": "Revise el resumen del proyecto antes de marcarlo como completo:",
  "specialProjectModalTitle": "Revise los detalles antes de marcarlo como completo:",
  "specialProjectModalWarning": "Una vez guardado, los detalles no pueden ser editados.",
  "specialProjectTypes": {
    "cycleCount": "Conteo de ciclo",
    "inventoryTransferToMerchant": "Transferencia de inventario a comerciante",
    "inventoryTransferToNode": "Transferencia de inventario a centro de cumplimiento",
    "kitting": "Equipamiento de kit",
    "otherType": "Otro",
    "qualityControl": "Inspección de control de calidad",
    "relabelling": "Reetiquetando",
    "repackaging": "Reempaquetado",
    "returns": "Devoluciones",
    "prekit": "Kit previo"
  },
  "specialProjects": "Proyectos especiales",
  "specialProjectsNotFound": "No se encontraron proyectos especiales",
  "stagingId": "ID de transición",
  "stagingLocations": "Áreas de transición",
  "startDate": "Fecha de inicio",
  "startTime": "Hora de inicio",
  "status": "Estatus",
  "storageContainers": "Contenedores de almacenamiento",
  "store": "Tienda",
  "submitted": "Enviado",
  "subsection": "Subsección",
  "subsectionDimensions": "Dimensiones de subsección",
  "subsectionHeight": "Altura de subsección",
  "subsectionLength": "Largo de subsección",
  "subsectionWidth": "Ancho de subsección",
  "suggestedBatches": "Lotes sugeridos",
  "summaryOfIssues": "Sumario de problemas",
  "support": "Soporte",
  "supportTicket": "Ticket de soporte",
  "supportTicketStatus": "Estatus de ticket de soporte",
  "supportedLocales": {
    "en-US": "Inglés",
    "es-MX": "Español (México)"
  },
  "suspectedDiscrepancies": "Discrepancias sospechadas",
  "suspectedDiscrepancy": "Discrepancia sospechada",
  "tag": "Etiqueta",
  "tags": "Etiquetas",
  "takeoff": "Despegue",
  "takeoffLocation": "Ubicación de retirada",
  "task": "Tarea",
  "technicalProblem": "Un problema técnico impidió que cargara esta página ({problem}). Intente refrescar la página o navegar a otra.",
  "terminalOnWheels": "Terminal sobre ruedas",
  "thisEmployeeHasBridgeAccess": "Este empleado tiene acceso a The Bridge.",
  "time": "Tiempo",
  "timeLogged": "Tiempo registrado",
  "timeSpent": "Tiempo usado",
  "timeline": "Línea de tiempo",
  "timestampInfo": "Todas las marcas de tiempo están en {timezone}",
  "timestamps": "Marcas de tiempo",
  "today": "Hoy",
  "tools": "Herramientas",
  "total": "Total",
  "totalCapacity": "Capacidad total",
  "totalJobs": "Trabajos totales",
  "totalLength": "Largo total",
  "totalLines": "Líneas totales",
  "totalOrders": "Pedidos totales",
  "totalRequired": "Total requerido",
  "totalTimeLogged": "Tiempo total registrado",
  "totalUnits": "Unidades totales",
  "totalUnitsRequired": "Unidades totales requeridas",
  "totalWidth": "Ancho total",
  "toteRemoved": "Caja retirada en {takeoffDestination}",
  "toteScanned": "Caja escaneada en {mfpId}",
  "totes": "Cajas",
  "trackAsInventory": "Monitorear como inventario",
  "trackContainerAsInventory": "Monitorear este contenedor como inventario",
  "trackJobs": "Monitorear trabajos",
  "trackingId": "ID de monitoreo",
  "trackingInformation": "Información de monitoreo",
  "trackingLink": "Enlace de monitoreo",
  "trackingNumber": "Número de monitoreo",
  "type": "Tipo",
  "unassigned": "Sin asignación",
  "unavailable": "No disponible",
  "uniqueJobId": "ID de trabajo único",
  "units": "Unidades",
  "unitsMoved": "Unidades movidas",
  "unitsPacked": "Unidades empaquetadas",
  "unitsPerKit": "Unidades por kit",
  "unitsPicked": "Unidades recolectadas",
  "unitsReceived": "Unidades recibidas",
  "unitsReplenished": "Unidades reabastecidas",
  "unitsRequired": "Unidades requeridas",
  "unitsStaged": "Unidades en transición",
  "unitsWithException": "Unidades con excepción",
  "unknown": "Desconocido",
  "unknownError": "Error desconocido",
  "unnamed": "Sin nombre",
  "unresolvable": "No se puede resolver",
  "unsavedChanges": "Cambios no guardados",
  "unsellable": "No vendible",
  "updateNoteFailed": "Hubo un error al actualizar la nota",
  "updatedAt": "Actualizado",
  "upload": "Cargar",
  "uploadAttachmentFailed": "Hubo un error al cargar los anexos",
  "useContainerNameAsExternalId": "Usar nombre de contenedor para el ID externo (recomendado)",
  "used": "Usados",
  "validations": {
    "isDefined": "Se requiere este valor",
    "isEmail": "Este valor debe ser una dirección válida de correo electrónico",
    "isNotEmpty": "Se requiere este valor",
    "isPositive": "Este valor debe ser un número positivo",
    "isRequired": "Se requiere este valor",
    "isUuid": "Este valor debe ser un UUID",
    "isUniqueValue": "Este valor ya está en uso",
    "matches": "Este valor tiene un patrón inválido",
    "maxLength": "Este valor es demasiado largo",
    "minLength": "Este valor es demasiado corto",
    "min": "Este valor es demasiado bajo",
    "isValidValue": "Este valor no es válido",
    "isEnum": "Este valor no es válido",
    "isUUID": "Este valor debe ser un UUID"
  },
  "viewActionLog": "Ver registro de acción",
  "viewAllItems": "Ver todos los artículos",
  "viewAllLocations": "Ver todas las ubicaciones",
  "viewBatchingJob": "Ver trabajo de loteado {jobId}",
  "viewBulkOrders": "Ver pedidos",
  "viewContainer": "Ver detalles de contenedor",
  "viewInstructions": "Ver instrucciones",
  "viewInventoryLevels": "Ver niveles de inventario",
  "viewOrders": {
    "one": "Ver pedido",
    "other": "Ver {count} pedidos"
  },
  "viewRelatedJobs": "Ver trabajos relacionados",
  "viewResolutionJob": "Ver trabajos de resolución {jobId}",
  "viewWorkAreas": {
    "one": "Ver {count} área de trabajo",
    "other": "Ver {count} áreas de trabajo"
  },
  "volume": "Volumen",
  "wallCreated": "Pared creada",
  "wallId": "ID de pared",
  "weight": "Peso",
  "wholeContainer": "Contenedor entero",
  "width": "Ancho",
  "withinXDays": "En {days} días",
  "workArea": "Área de trabajo",
  "workAreas": "Áreas de trabajo",
  "workLog": "Registro de trabajo",
  "workLogDeleted": "Registro de trabajo eliminado",
  "workLogMissing": "No se registró trabajo. Registre el trabajo antes de marcar el proyecto como completo.",
  "workLogTaskHelperText": "Ingrese un nombre para esta tarea. Por ejemplo, \"Inventario movido\".",
  "workLogTypes": {
    "inventoryMovement": "Movimiento de inventario",
    "otherType": "Otro",
    "sopReview": "Revisión SOP",
    "stationCleanup": "Limpieza de estación",
    "stationSetup": "Configuración de estación"
  },
  "workLogged": "Trabajo registrado",
  "workNotStarted": "El trabajo aún no se ha iniciado para este proyecto",
  "workflow": "Flujo de trabajo",
  "workflowPointDisabled": "Punto de flujo de trabajo deshabilitado",
  "workflowPointEnabled": "Punto de flujo de trabajo habilitado",
  "workflowTypes": {
    "amnesty": "Amnesty Put",
    "move": "Movimiento",
    "picking": "Picking",
    "replen": "Reabastecimiento"
  },
  "xOfY": "{x} de {y}",
  "xOfYCompleted": "{x} de {y} completados",
  "xOfYUnits": "{x} de {y} unidades",
  "yes": "Sí",
  "asnType": "Tipo de ASN",
  "condition": "Condición",
  "confirmJobHistoryLength": "La información de los trabajos de más de {count} días demorará algo de tiempo en recuperarse.  ¿Desea continuar?",
  "confirmOrderHistoryLength": "La información de los pedidos de más de {count} días demorará algo de tiempo en recuperarse.  ¿Desea continuar?",
  "countASNs": {
    "one": "{count} evento ASN",
    "other": "{count} eventos ASN"
  },
  "inboundArrivingToday": "Ingreso que llega hoy",
  "internalTransfersArrivingToday": "Transferencias internas que llegan hoy",
  "internalTransfer": "Transferencia interna",
  "loadingSlow": "la información de los pedidos de más de {count} días puede demorar un rato",
  "merchant": "Comerciante",
  "unexpectedInventory": "Inventario inesperado",
  "unknownItem": "Artículo desconocido",
  "viewMoreJobs": "Ver más trabajos",
  "viewMoreOrders": "Ver más pedidos",
  "workOrderType": "Tipo de pedido",
  "workOrderTypes": {
    "ecommerce": "Comercio electrónico",
    "transfer": "Transferencia"
  },
  "chuckFaults": {
    "BACK_EMERGENCY_STOP": "Parada de emergencia trasera",
    "BATTERY_LOW": "Batería baja",
    "BUMP_SENSOR": "Sensor de golpes",
    "FRONT_EMERGENCY_STOP": "Parada de emergencia delantera",
    "HARD_STOP": "Parada fuerte",
    "NAVIGATION_ANY": "Fallo de navegación",
    "PAUSE": "Pausa de emergencia"
  },
  "chuckResolutions": {
    "NONE": "Ninguno",
    "REBOOT": "Reinicio",
    "FATAL": "Fatal",
    "RELEASE": "Liberar la parada de emergencia",
    "PAUSE": "Pausar",
    "CHECK_CABLE": "Verificar el cable",
    "CALIBRATE": "Calibrar",
    "CONTACT_SUPPORT": "Contactar con soporte",
    "NETWORK_CONNECTION": "Verificar la red",
    "LOCALIZE": "Localizar",
    "CHARGE": "Cargar",
    "RELOCATE": "Reubicar",
    "CHECK_CHARGE_PORT": "Verificar el puerto de carga",
    "LOCALIZE_HOME_LOCATIONS": "Localizar las ubicaciones iniciales",
    "LOCALIZE_APRIL_TAGS": "Localizar las etiquetas de abril",
    "PAIR_BLUETOOTH": "Emparejar Bluetooth"
  },
  "chuckPhases": {
    "booting": "Arranque",
    "induct": "Introducir",
    "offline": "Fuera de línea",
    "picking": "Picking",
    "prepick": "Picking previo",
    "prereplen": "Reabastecimiento previo",
    "replen": "Reabastecimiento",
    "replenInduct": "Introducción del reabastecimiento",
    "restage": "Reorganización"
  },
  "arrivingLate": "Llegada retrasada",
  "arrivingWithinDays": {
    "one": "Llegada dentro de {count} día",
    "other": "Llegada dentro de {count} días"
  },
  "countResults": {
    "one": "{count} resultado",
    "other": "{count} resultados"
  },
  "orderReviewSummary": "Revise el resumen del pedido antes de marcar el pedido como completo",
  "orderReviewTitle": "Revise el pedido y proceda a marcarlo como completo",
  "orderReviewWarning": "Una vez marcado como completo, este pedido no se puede editar",
  "returnToOrderDetails": "Vuelva a los detalles del pedido",
  "shippedShortBannerHeading": "Pedido cerrado manualmente",
  "shippedShortBannerBody": "Este pedido se marcó manualmente como completo, pero no se seleccionaron todos los artículos.",
  "transferDetails": "Detalles de transferencia",
  "back": "Atrás",
  "changePassword": "Cambiar contraseña",
  "checkYourEmail": "Verifique su correo electrónico",
  "choosePasswordForEmail": "Elija una contraseña para {email}",
  "confirmNewPassword": "Confirme la nueva contraseña",
  "emailNotRecognized": "La dirección de correo electrónico \"{emailAddress}\" no es válida o podría no ser reconocida por nuestro sistema. Contacte a su administrador para pedir asistencia.",
  "enterEmailToResetPassword": "Ingrese su correo electrónico para restablecer su contraseña",
  "forgotPassword": "¿Olvidó la contraseña?",
  "forgotYourPassword": "¿Olvidó su contraseña?",
  "invalidEmail": "Correo electrónico no válido",
  "newPassword": "Nueva contraseña",
  "passwordAccepted": "Contraseña aceptada",
  "passwordsDoNotMatch": "Las contraseñas no coinciden",
  "passwordPolicy": "Su contraseña debe tener al menos 8 caracteres de largo e incluir al menos dos de los siguientes elementos: una letra en mayúscula, una letra en minúscula y un número.",
  "passwordRejected": "Contraseña no válida",
  "passwordResetLinkSentToEmail": "Se envió un enlace para restablecer su contraseña a {email}",
  "passwordsMatch": "Las contraseñas coinciden",
  "resetPassword": "Restablecer contraseña",
  "resetPasswordEmailSent": "Enviamos un correo electrónico a {email} que incluye un enlace para restablecer su contraseña.",
  "sendLink": "Enviar enlace",
  "setUpAccount": "Configurar cuenta",
  "signInToTheBridge": "Iniciar sesión en The Bridge"
}
