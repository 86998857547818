import { Banner, Button, Modal, Stack, TextContainer, TextStyle } from '@shopify/polaris';
import {
	KittingProject as KittingProjectApi,
	SpecialProject as SpecialProjectApi,
	MutationResponse,
	SpecialProjectType,
} from '@sixriver/fulfillment-api-schema';
import { useCallback, useMemo, useState } from 'react';
import { CombinedError, useMutation } from 'urql';

import { MARK_AS_COMPLETE_MUTATION, REPORT_ISSUES_MUTATION } from './SpecialProjects.graphql';
import { TimeLogged } from './TimeLogged';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

export interface SpecialProjectReviewModalProps {
	isOpen: boolean;
	project: SpecialProjectApi;
	onClose: () => void;
}

// Based on type KitCompletionErrors in ps-goals-handlers-common
enum KitCompletionError {
	noKitProduct = 'NO_KIT_PRODUCT',
	kitNeedsInspection = 'KIT_NEEDS_INSPECTION',
	exceptionsMissing = 'EXCEPTIONS_MISSING',
}

export function SpecialProjectReviewModal({
	isOpen: isModalOpen,
	onClose,
	project,
}: SpecialProjectReviewModalProps) {
	const { messages } = useLocalization();

	const [errorBannerText, setErrorBannerText] = useState<string>('');

	// Mutations
	const [, markAsCompleteMutation] = useMutation<
		{ markAsComplete: MutationResponse },
		{ id: string }
	>(MARK_AS_COMPLETE_MUTATION);

	// Mutations
	const [, reportIssues] = useMutation<{ reportIssues: MutationResponse }>(REPORT_ISSUES_MUTATION);

	const onCloseModal = useCallback(() => {
		setErrorBannerText('');
		onClose();
	}, [onClose]);

	const showIssuesText = project.type === SpecialProjectType.Kitting;

	const issueLinkText = project?.hasIssues ? messages.editIssues : messages.reportIssues;

	const issues = useMemo(() => {
		switch (project.type) {
			case SpecialProjectType.Kitting:
				return (project as KittingProjectApi).componentProducts
					.map((product) => {
						return product.exceptions.map((exception) => {
							return {
								productId: product.id,
								eachQuantity: exception.eachQuantity,
								reason: exception.reason,
							};
						});
					})
					.flat();
			default:
				return [];
		}
	}, [project]);

	const updateProjectStatus = useCallback(async () => {
		function determineError(error: CombinedError | undefined): string {
			if (!error) {
				return messages.saveError;
			}

			const errorCode: KitCompletionError =
				error?.graphQLErrors[0]?.extensions?.response.body.error.details.error.errorCode;

			switch (errorCode) {
				case KitCompletionError.exceptionsMissing:
					return messages.exceptionsMissing;
				case KitCompletionError.noKitProduct:
					return messages.workLogMissing;
				default:
					return messages.saveError;
			}
		}

		const { error, data } = await markAsCompleteMutation(
			{ id: project.id },
			{ additionalTypenames: ['KittingProject', 'PreKitException', 'WorkOrderProject'] },
		);

		if (data?.markAsComplete.success) {
			onCloseModal();
		} else {
			setErrorBannerText(determineError(error));
		}
	}, [markAsCompleteMutation, onCloseModal, project.id, messages]);

	const onMarkAsComplete = useCallback(async () => {
		if (project) {
			const { data: issueData } = await reportIssues(
				{ id: project.id, exceptions: issues },
				{ additionalTypenames: ['KittingProject', 'PreKitException'] },
			);

			if (issueData?.reportIssues.success) {
				updateProjectStatus();
			} else {
				setErrorBannerText(messages.saveError);
			}
		}
	}, [issues, project, reportIssues, updateProjectStatus, messages]);

	return (
		<Modal
			open={isModalOpen}
			onClose={onCloseModal}
			title={messages.specialProjectModalTitle}
			primaryAction={{ content: messages.markAsComplete, onAction: onMarkAsComplete }}
			secondaryActions={[
				{
					content: messages.close,
					onAction: onCloseModal,
				},
			]}
		>
			<Modal.Section>
				<TextContainer>
					{errorBannerText ? (
						<Banner status="critical">{errorBannerText}</Banner>
					) : (
						<Banner status="warning">{messages.specialProjectModalWarning}</Banner>
					)}
					<p>{messages.specialProjectModalMessage}</p>
					<Stack vertical spacing="extraTight">
						<TextStyle>
							{messages.workLogged}: <TimeLogged timeInMs={project?.totalTimeLogged ?? 0} />
						</TextStyle>
						<Button plain url={routes.specialProjectWorkLogged(project.id)} onClick={onCloseModal}>
							{messages.editWorkLog}
						</Button>
					</Stack>
					{showIssuesText ? (
						<Stack vertical spacing="extraTight">
							{project?.hasIssues ? (
								<IssueList project={project as KittingProjectApi} />
							) : (
								<IssuesQuestion />
							)}
							<Button
								plain
								url={routes.specialProjectReportIssues(project.id)}
								onClick={onCloseModal}
							>
								{issueLinkText}
							</Button>
						</Stack>
					) : null}
				</TextContainer>
			</Modal.Section>
		</Modal>
	);
}

function IssuesQuestion(): JSX.Element {
	const { messages } = useLocalization();

	return <TextStyle>{messages.productsDamagedOrMissing}</TextStyle>;
}

interface Props {
	project: KittingProjectApi;
}

function IssueList({ project }: Props): JSX.Element {
	const { messages } = useLocalization();

	const productsWithIssues = project.componentProducts.filter(
		(component) => component.exceptions.length > 0,
	);

	const kitIssues = productsWithIssues.map((product) => {
		const productIssues = product.exceptions
			.map((exception) => {
				const reason = {
					damagedProduct: messages.kitIssues.damagedProduct,
					missingProduct: messages.kitIssues.missingProduct,
					other: messages.kitIssues.otherIssue,
				}[exception.reason];

				return `${exception.eachQuantity} ${reason}`;
			})
			.join(', ');

		return productIssues ? `${messages.sku} ${product.name}: ${productIssues}` : '';
	});

	return (
		<Stack vertical spacing="extraTight">
			<TextStyle>Issues reported:</TextStyle>
			{kitIssues.map((issue, index) => {
				return <TextStyle key={index}>{issue}</TextStyle>;
			})}
		</Stack>
	);
}
