import { Card, Icon, Stack, TextContainer, TextStyle } from '@shopify/polaris';
import { AlertMinor } from '@shopify/polaris-icons';
import { Return } from '@sixriver/fulfillment-api-schema';

import styles from './Progress.module.css';
import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

export function Progress({ inboundReturn }: { inboundReturn?: Return }) {
	const { messages, translate } = useLocalization();

	const uniqueIssues = [
		...Array.from(
			new Set(
				inboundReturn?.issues.map(
					(issue) =>
						messages.issueReasons[issue.reason as unknown as keyof typeof messages['issueReasons']],
				),
			),
		),
	];

	const issueCount = uniqueIssues.length ?? 0;

	return (
		<Card title={messages.progress}>
			<Card.Section>
				<Stack distribution="fillEvenly">
					<Stack.Item>
						<TextContainer>
							<TextStyle variation="subdued">{messages.received}</TextStyle>
						</TextContainer>
						<TextContainer spacing="loose">
							{inboundReturn && inboundReturn?.totalActualUnitQuantity >= 0 ? (
								<TextStyle>
									{translate(messages.countUnits, {
										count: inboundReturn?.totalActualUnitQuantity || 0,
									})}
								</TextStyle>
							) : (
								<NoData />
							)}{' '}
							{inboundReturn && inboundReturn?.totalActualLineQuantity >= 0 ? (
								<TextStyle variation="subdued">
									{translate(messages.countSkus, {
										count: inboundReturn?.totalActualLineQuantity || 0,
									})}
								</TextStyle>
							) : (
								<NoData />
							)}
						</TextContainer>
					</Stack.Item>
					<Stack.Item>
						<TextContainer>
							<TextStyle variation="subdued">{messages.expected}</TextStyle>
						</TextContainer>
						<TextContainer spacing="loose">
							{inboundReturn && inboundReturn?.totalExpectedUnitQuantity >= 0 ? (
								<TextStyle>
									{translate(messages.countUnits, {
										count: inboundReturn?.totalExpectedUnitQuantity || 0,
									})}
								</TextStyle>
							) : (
								<NoData />
							)}{' '}
							{inboundReturn && inboundReturn?.totalExpectedLineQuantity >= 0 ? (
								<TextStyle variation="subdued">
									{translate(messages.countSkus, {
										count: inboundReturn?.totalExpectedLineQuantity || 0,
									})}
								</TextStyle>
							) : (
								<NoData />
							)}
						</TextContainer>
					</Stack.Item>
					<Stack.Item>
						<Stack spacing="extraTight">
							<TextContainer>
								<TextStyle variation="subdued">{messages.issues}</TextStyle>
							</TextContainer>
							{issueCount > 0 && (
								<div className={styles.iconBadgeContainer}>
									<Icon source={AlertMinor} color="warning" />
									{issueCount}
								</div>
							)}
						</Stack>
						{uniqueIssues.length > 0 ? <p>{uniqueIssues.join(', ')}</p> : <NoData />}
					</Stack.Item>
					<Stack.Item />
				</Stack>
			</Card.Section>
		</Card>
	);
}
