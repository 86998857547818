import {
	Autocomplete,
	Button,
	Form,
	FormLayout,
	Heading,
	Link,
	Modal,
	Stack,
	TextContainer,
	TextField,
	TextStyle,
	Thumbnail,
} from '@shopify/polaris';
import { ImageMajor } from '@shopify/polaris-icons';
import { useDebouncedValue } from '@shopify/react-hooks';
import {
	JobFlowRulesInput,
	Product,
	ProductSummaryPage,
	SuggestedBulkOrder,
} from '@sixriver/fulfillment-api-schema';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'urql';

import styles from './CreateBatchModal.module.css';
import { FormFeedback } from 'components/FormFeedback';
import { useForm } from 'hooks/useForm';
import { useLocalization } from 'hooks/useLocalization';
import { useToast } from 'hooks/useToast';
import {
	CREATE_BATCH_MUTATION,
	PRODUCTS_QUERY,
	PRODUCT_QUERY,
} from 'pages/BulkOrders/BulkOrders.graphql';
import * as routes from 'routes';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	batch?: SuggestedBulkOrder;
}

export function CreateBatchModal({ isOpen, onClose, batch }: Props) {
	const { messages } = useLocalization();
	const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

	const [{ error: createBatchError }, createBatchMutation] = useMutation<
		{ createBatch: Response },
		{ input: JobFlowRulesInput }
	>(CREATE_BATCH_MUTATION);

	const { editForm, discardForm, input, feedback, validations } = useForm<JobFlowRulesInput>(
		{ assetTypeId: '', batchId: '' },
		createBatchError,
	);
	const debouncedSearchText = useDebouncedValue(input.assetTypeId);

	if (batch?.assetTypes) {
		const assetTypeId = batch.assetTypes[0].id;
		if (selectedOptions.length === 0) {
			selectedOptions.push(assetTypeId);
		}
		if (!input.assetTypeId) {
			input.assetTypeId = assetTypeId;
		}
	}
	if (!isOpen && selectedOptions.length) {
		selectedOptions.pop();
		input.assetTypeId = '';
	}

	const [{ data, fetching }] = useQuery<{
		products: ProductSummaryPage;
	}>({
		query: PRODUCTS_QUERY,
		pause: selectedOptions.length > 0,
		variables: {
			searchText: debouncedSearchText,
		},
	});

	const products = data?.products.results || [];
	const autocompleteOptions = products.map((product) => {
		return {
			label: `${product.name} - ${product.customerIdentifier} - ${product.description}`,
			value: product.id,
		};
	});

	const [{ data: productData }] = useQuery<{
		product: Product;
	}>({
		query: PRODUCT_QUERY,
		pause: selectedOptions.length === 0,
		variables: {
			id: selectedOptions[0],
		},
	});

	const updateSelection = useCallback(
		(selected) => {
			setSelectedOptions(selected);
			editForm({ assetTypeId: selected[0] });
		},
		[editForm],
	);

	const resetProduct = () => {
		setSelectedOptions([]);
		editForm({ assetTypeId: '' });
	};

	const { showToast } = useToast();

	const onSubmit = async (input: JobFlowRulesInput) => {
		const { error } = await createBatchMutation({ input });
		if (!error) {
			showToast(messages.batchCreated);
			onModalClose();
		} else showToast(error?.message);
	};

	const onModalClose = () => {
		setSelectedOptions([]);
		discardForm();
		onClose();
	};

	const textField = (
		<Autocomplete.TextField
			name="assetTypeId"
			label={messages.product}
			placeholder={messages.searchProduct}
			value={input.assetTypeId}
			onChange={(assetTypeId) => editForm({ assetTypeId })}
			error={validations.assetTypeId}
			autoComplete="off"
		/>
	);

	return (
		<Modal
			open={isOpen}
			onClose={onModalClose}
			title={messages.createBatch}
			primaryAction={{
				content: messages.create,
				onAction: () => onSubmit(input),
			}}
			secondaryActions={[
				{
					content: messages.cancel,
					onAction: onModalClose,
				},
			]}
		>
			<FormFeedback feedback={feedback} />
			<Modal.Section>
				<TextContainer>{messages.createBatchDescription}</TextContainer>
			</Modal.Section>
			<Modal.Section>
				<Form onSubmit={() => onSubmit(input)}>
					<FormLayout>
						<Heading>
							{batch?.ordersCollectedCount ? messages.product : messages.addProduct}
						</Heading>
						{selectedOptions.length ? (
							<Stack>
								<Stack.Item>
									<Thumbnail source={productData?.product.image || ImageMajor} alt="" />
								</Stack.Item>
								<Stack.Item fill>
									<p>
										<Link url={routes.product(productData?.product.id)}>
											{productData?.product.customerIdentifier}
										</Link>
									</p>
									<p>
										<TextStyle variation="subdued">{`${messages.sku}: ${productData?.product.name}`}</TextStyle>
									</p>
									<p>
										<TextStyle variation="subdued">{`${messages.description}: ${productData?.product.description}`}</TextStyle>
									</p>
								</Stack.Item>
								{!batch && (
									<Stack.Item>
										<Button plain onClick={resetProduct}>
											{messages.remove}
										</Button>
									</Stack.Item>
								)}
							</Stack>
						) : (
							<Autocomplete
								options={autocompleteOptions}
								selected={selectedOptions}
								onSelect={updateSelection}
								loading={fetching}
								textField={textField}
								emptyState={[]}
							/>
						)}
						<Heading>{messages.assignBatchId}</Heading>
						<TextField
							autoComplete="off"
							name="batchId"
							label={messages.batchId}
							placeholder={messages.batchId}
							value={input.batchId}
							onChange={(batchId) => editForm({ batchId })}
							error={validations.batchId}
						></TextField>
						<TextField
							autoComplete="off"
							name="description"
							label={messages.internalNote}
							placeholder={messages.enterNote}
							value={input.description ?? undefined}
							onChange={(description) => editForm({ description })}
							multiline={4}
							maxLength={500}
							showCharacterCount
						></TextField>
					</FormLayout>
				</Form>
			</Modal.Section>
			{batch && (
				<Modal.Section>
					<div className={styles.ordersCollectedHeader}>
						{messages.ordersCollected.toUpperCase()}
					</div>
					<div className={styles.ordersCollected}>{batch?.ordersCollectedCount}</div>
					<p style={{ marginTop: '20px' }}>
						<TextStyle variation="subdued">{messages.collectBatchDescription}</TextStyle>
					</p>
				</Modal.Section>
			)}
		</Modal>
	);
}
