import { Button, DisableableAction, Icon, Layout, TextStyle, Tooltip } from '@shopify/polaris';
import { DeleteMinor, EditMinor } from '@shopify/polaris-icons';
import {
	MutationResponse,
	SpecialProjectType,
	WorkLog as WorkLogApi,
	WorkLogPage,
} from '@sixriver/fulfillment-api-schema';
import { capitalize } from 'lodash';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

import {
	ADD_TIME_LOG_MUTATION,
	EDIT_TIME_LOG_MUTATION,
	WORK_LOG_QUERY,
} from './SpecialProjects.graphql';
import { TimeLogged } from './TimeLogged';
import { WorkLogAddEditModal } from './WorkLogAddEditModal';
import { WorkLogDeleteModal } from './WorkLogDeleteModal';
import { WorkLogEmptyState } from './WorkLogEmptyState';
import { Column, DataTable } from 'components/DataTable';
import { DateTime } from 'components/DateTime';
import { Error } from 'components/Error';
import { getPageSize } from 'helpers/page-size';
import { useLocalization } from 'hooks/useLocalization';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { useToast } from 'hooks/useToast';

interface Props {
	queryPollInterval: number;
	queryPause?: boolean;
	projectId: string;
	projectType?: SpecialProjectType | null;
	isProjectComplete?: boolean;
	totalTime: number;
}

export function WorkLog({
	queryPollInterval,
	queryPause,
	projectId,
	projectType,
	totalTime,
	isProjectComplete,
}: Props) {
	const { messages, translate } = useLocalization();

	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [selectedWorkLogItem, setSelectedWorkLogItem] = useState<WorkLogApi | undefined>(undefined);
	const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
	const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);

	const { showToast } = useToast();

	// Data
	const [{ fetching, data, error: queryError }] = usePollingQuery<{ getWorkLog: WorkLogPage }>({
		query: WORK_LOG_QUERY,
		pollInterval: queryPollInterval,
		pause: queryPause,
		variables: {
			id: projectId,
			cursor: paginationCursors[0],
			limit: getPageSize(),
		},
	});

	const { cursor, results } = data?.getWorkLog || { results: [] };

	// Mutations
	const [{ error: editTimeLogError }, editTimeLog] =
		useMutation<{ editTimeLog: MutationResponse }>(EDIT_TIME_LOG_MUTATION);

	const [{ error: addTimeLogError }, addTimeLog] =
		useMutation<{ addTimeLog: MutationResponse }>(ADD_TIME_LOG_MUTATION);

	// Functions
	const onDeleteClick = useCallback((logItem: WorkLogApi) => {
		setIsDeleteModalOpen(true);
		setSelectedWorkLogItem(logItem);
	}, []);

	const onDeleteModalClose = useCallback(() => {
		setIsDeleteModalOpen(false);
		setSelectedWorkLogItem(undefined);
	}, []);

	const onDelete = useCallback(async () => {
		if (selectedWorkLogItem) {
			const { id, timeLogged } = selectedWorkLogItem;
			const { data } = await editTimeLog(
				{ goalId: projectId, originalEventId: id, time: adjustTimeToZero(timeLogged) },
				{ additionalTypenames: ['WorkLogPage', 'WorkLog', 'KittingProject', 'WorkOrderProject'] },
			);
			onDeleteModalClose();

			if (data?.editTimeLog.success) {
				showToast(messages.workLogDeleted);
			}
		}
	}, [editTimeLog, onDeleteModalClose, projectId, selectedWorkLogItem, showToast, messages]);

	const onEditClick = useCallback((logItem: WorkLogApi) => {
		setIsEditModalOpen(true);
		setSelectedWorkLogItem(logItem);
	}, []);

	const onAddEditModalClose = useCallback(() => {
		setIsEditModalOpen(false);
		setSelectedWorkLogItem(undefined);
		setIsAddModalOpen(false);
	}, []);

	const onAddClick = useCallback(() => {
		setIsAddModalOpen(true);
		setSelectedWorkLogItem(undefined);
	}, []);

	const onSave = useCallback(
		async (updatedLogItem: Partial<WorkLogApi>) => {
			if (isEditModalOpen && selectedWorkLogItem) {
				const { id } = selectedWorkLogItem;
				const { data } = await editTimeLog(
					{
						goalId: projectId,
						originalEventId: id,
						time: determineTimeAdjustment(
							selectedWorkLogItem.timeLogged,
							updatedLogItem.timeLogged,
						),
					},
					{ additionalTypenames: ['WorkLogPage', 'WorkLog', 'KittingProject', 'WorkOrderProject'] },
				);
				onAddEditModalClose();

				if (data?.editTimeLog.success) {
					showToast(translate(messages.dataUpdated, { name: messages.workLog }) as string);
				}
			} else if (isAddModalOpen) {
				if (updatedLogItem.user) {
					const { data } = await addTimeLog(
						{
							goalId: projectId,
							task: updatedLogItem.task,
							time: updatedLogItem.timeLogged,
							happenedAt: updatedLogItem.dateLogged,
							userId: updatedLogItem.user.id,
						},
						{
							additionalTypenames: ['WorkLogPage', 'WorkLog', 'KittingProject', 'WorkOrderProject'],
						},
					);
					onAddEditModalClose();

					if (data?.addTimeLog.success) {
						showToast(messages.workLogged);
					}
				}
			}
		},
		[
			addTimeLog,
			editTimeLog,
			isAddModalOpen,
			isEditModalOpen,
			onAddEditModalClose,
			projectId,
			selectedWorkLogItem,
			showToast,
			messages,
			translate,
		],
	);

	// Table setup
	const columns: Column[] = [
		{ type: 'text', heading: messages.associate },
		{ type: 'text', heading: messages.date },
		{ type: 'text', heading: messages.task },
		{ type: 'numeric', heading: messages.timeLogged },
		{ type: 'numeric', heading: messages.edit },
		{ type: 'numeric', heading: messages.delete },
	];

	const rows = results
		.filter((item) => item.timeLogged > 0)
		.map((logItem) => {
			const { id, user, dateLogged, task, timeLogged } = logItem;
			return [
				<TextStyle key={`${id}-associate`}>{user?.name ?? user?.id}</TextStyle>,
				<DateTime key={`${id}-date`} date={dateLogged} />,
				<TextStyle key={`${id}-task`}>{capitalize(task)}</TextStyle>,
				<TimeLogged key={`${id}-time`} timeInMs={timeLogged} />,
				<EditButton
					key={`${id}-edit`}
					action={onEditClick}
					logItem={logItem}
					isProjectComplete={isProjectComplete}
				/>,
				<DeleteButton
					key={`${id}-delete`}
					action={onDeleteClick}
					logItem={logItem}
					isProjectComplete={isProjectComplete}
				/>,
			];
		});
	const footer = ['', '', '', <TimeLogged key="totalTime" timeInMs={totalTime} />, '', ''];
	const totalTitle = {
		singular: messages.totalTimeLogged,
		plural: messages.totalTimeLogged,
	};

	const error = queryError || editTimeLogError || addTimeLogError;

	const cardActions: DisableableAction[] = [
		{ content: messages.logWork, onAction: onAddClick, disabled: isProjectComplete },
	];

	if (error) {
		return <Error graphQLError={error} />;
	}

	return (
		<>
			<Layout>
				<Layout.Section>
					<DataTable
						cardActions={cardActions}
						columns={columns}
						rows={rows}
						emptyStateMarkup={<WorkLogEmptyState onClick={() => setIsAddModalOpen(true)} />}
						title={messages.workLog}
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
						pageInfo={{ endCursor: cursor }}
						loading={fetching}
						totals={rows.length ? footer : []}
						showTotalsInFooter={!!rows.length}
						totalsName={totalTitle}
					/>
				</Layout.Section>
			</Layout>
			<WorkLogDeleteModal
				isOpen={isDeleteModalOpen}
				onClose={onDeleteModalClose}
				onDelete={onDelete}
			/>

			<WorkLogAddEditModal
				isOpen={isEditModalOpen || isAddModalOpen}
				onClose={onAddEditModalClose}
				onSave={onSave}
				logItem={selectedWorkLogItem}
				isEditMode={isEditModalOpen}
				projectType={projectType}
			/>
		</>
	);
}

interface IconActionProps {
	logItem: WorkLogApi;
	action: (logItem: WorkLogApi) => void;
	isProjectComplete?: boolean;
}

function EditButton({ logItem, action, isProjectComplete }: IconActionProps): JSX.Element {
	return (
		<Button
			icon={<Icon source={EditMinor} />}
			plain
			primary
			onClick={() => action(logItem)}
			disabled={isProjectComplete}
		>
			{' '}
		</Button>
	);
}

function DeleteButton({ logItem, action, isProjectComplete }: IconActionProps): JSX.Element {
	const { messages } = useLocalization();

	const buttonMarkup: JSX.Element = (
		<Button
			icon={<Icon source={DeleteMinor} />}
			plain
			primary
			onClick={() => action(logItem)}
			disabled={logItem.isSystemGenerated || isProjectComplete}
		>
			{' '}
		</Button>
	);

	if (logItem.isSystemGenerated) {
		return (
			<Tooltip content={messages.deleteDisabledWorkLogHelpText} active={false}>
				{buttonMarkup}
			</Tooltip>
		);
	}

	return buttonMarkup;
}

function adjustTimeToZero(time: number): number {
	return time * -1;
}

function determineTimeAdjustment(originalTime: number, desiredTime?: number): number {
	if (desiredTime) {
		return desiredTime - originalTime;
	}

	return originalTime;
}
