import { Page, Layout, Tabs, Stack, Badge, Modal, TextContainer } from '@shopify/polaris';
import { DuplicateMinor } from '@shopify/polaris-icons';
import {
	QueryPickingByProjectionIdArgs,
	Picking,
	PickingJobExceptionStatus,
	MutationCancelPickingJobArgs,
	JobAllocationMethod,
	WorkflowEvent,
	Sortation,
} from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useMutation } from 'urql';

import { PickingLinesTable, PackingLinesTable } from './LinesTable';
import { PICKING_QUERY, PICKING_EVENTS_QUERY, CANCEL_JOB_MUTATION } from './OutboundJob.graphql';
import { PackingDetails } from './PackingDetails';
import { PickingDetails } from './PickingDetails';
import { PickingProgress } from './PickingProgress';
import { getRelatedJobs } from './RelatedJobs';
import { SortationDetails } from './SortationDetails';
import { TransferDetails } from './TransferDetails';
import { AutoRefresh } from 'components/AutoRefresh';
import { Error } from 'components/Error';
import { ErrorBanner } from 'components/ErrorBanner';
import { OutboundJobStatusBadge } from 'components/OutboundJobStatusBadge';
import { Timeline } from 'components/Timeline';
import { useWorkflowEvents } from 'components/Timeline/Timeline.hooks';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { countLineExceptions, isExceptionActionable } from 'helpers/exception';
import { useAuth } from 'hooks/useAuth';
import { useConfig } from 'hooks/useConfig';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { UserRole } from 'providers/AuthProvider';
import * as routes from 'routes';

export interface RouteMatchParams {
	outboundJobId: string;
}

export function OutboundJob() {
	const {
		params: { outboundJobId },
	} = useRouteMatch<RouteMatchParams>();
	const { messages } = useLocalization();

	const { isUserAllowed } = useAuth();

	const history = useHistory();

	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	const projectionId = outboundJobId;

	const [{ fetching: jobFetching, error: jobError, data: jobData }] = usePollingQuery<
		{ pickingByProjectionId: Picking },
		QueryPickingByProjectionIdArgs
	>({
		query: PICKING_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			projectionId,
		},
	});

	const [{ fetching: eventsFetching, error: eventsError, data: eventsData }] = usePollingQuery<
		{ jobEvents: WorkflowEvent[] },
		QueryPickingByProjectionIdArgs
	>({
		query: PICKING_EVENTS_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			projectionId,
		},
	});

	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

	const closeCancelModal = () => {
		setIsCancelModalOpen(false);
	};

	const openCancelModal = () => {
		setIsCancelModalOpen(true);
	};

	const [{ fetching: cancelLoading }, cancelMutation] = useMutation<
		{ cancelPickingJob: Response },
		MutationCancelPickingJobArgs
	>(CANCEL_JOB_MUTATION);

	const { config } = useConfig();

	const fetching = jobFetching || eventsFetching;

	const error = jobError || eventsError;

	const job = jobData?.pickingByProjectionId;

	const relatedJobs = getRelatedJobs(job);

	const events = useWorkflowEvents(eventsData?.jobEvents || [], 'JOB');

	const numberOfExceptions = countLineExceptions(job?.lines);

	const { copyToClipboard } = useCopyToClipboard();

	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);

	const cancelJob = async () => {
		setIsBannerErrorVisible(false);

		try {
			const { error } = await cancelMutation({ projectionId: outboundJobId });

			closeCancelModal();

			if (error) {
				throw error;
			}

			history.push(routes.outboundJobs());
		} catch (e) {
			setIsBannerErrorVisible(true);
		}
	};

	const tabs = [
		{
			id: 'overview',
			content: messages.overview,
		},
		{
			id: 'history',
			content: messages.history,
		},
	];

	const { tab } = useFilters(['tab']);

	const setFilters = useSetFilters();

	const tabIndex = tab === tabs[1].id ? 1 : 0;

	const sortWall = (job?.servicing || []).find(
		(servicing) => servicing.__typename === 'Sortation',
	) as Sortation;

	const isPackingMode = config?.packoutEnabled && !!job?.packout;

	const isSortationMode = config?.sortationEnabled && !!sortWall;

	if (error) {
		return <Error graphQLError={error} />;
	}

	if (!fetching && !job) {
		return <Error heading={messages.outboundJobNotFound} />;
	}

	return (
		<>
			<Page
				title={job?.stagingIdentifier || job?.id}
				titleMetadata={
					<Stack spacing="extraTight">
						<OutboundJobStatusBadge pickingStatus={job?.pickingStatus} isEmptyIfNoData />
						{job?.exception === PickingJobExceptionStatus.Cleared ? (
							<Badge status="success">{messages.exceptionCleared}</Badge>
						) : numberOfExceptions &&
						  isExceptionActionable(config?.packoutEnabled, config?.healingEnabled, job) &&
						  config?.healingEnabled ? (
							<Badge status="critical">{messages.exception}</Badge>
						) : null}
					</Stack>
				}
				secondaryActions={[
					{
						content: messages.copyLicensePlate,
						icon: DuplicateMinor,
						onAction: job?.container?.barcode
							? () => copyToClipboard(job?.container?.barcode)
							: undefined,
						disabled: !job?.container?.barcode,
					},
					{
						content: messages.cancelJob,
						onAction: openCancelModal,
						disabled:
							!isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]) || !job?.isCancelable,
					},
				]}
			>
				<AutoRefresh
					pollingEnabled={pollingEnabled}
					togglePolling={togglePolling}
					discriminatorData={jobData}
				/>
				<Tabs
					tabs={tabs}
					selected={tabIndex}
					onSelect={(tabIndex) => {
						setFilters([{ key: 'tab', value: tabs[tabIndex].id }]);
					}}
				>
					<Layout>
						<Layout.Section>
							<ErrorBanner
								isVisible={isBannerErrorVisible}
								onDismiss={() => {
									setIsBannerErrorVisible(false);
								}}
							/>
						</Layout.Section>
						{tab === 'history' ? (
							<Layout.Section>
								<Timeline
									title={messages.outboundJobHistory}
									events={events}
									loading={eventsFetching}
									error={eventsError}
								/>
							</Layout.Section>
						) : (
							<>
								{config?.inventoryEnabled && (
									<Layout.Section>
										<>
											<TransferDetails loading={fetching} job={job} />
										</>
									</Layout.Section>
								)}
								<Layout.Section>
									<>
										{isPackingMode ? (
											<PackingDetails
												loading={fetching}
												job={job}
												relatedJobs={relatedJobs}
												isHealingEnabled={config?.healingEnabled}
												cutoff={job?.expectedShipDate}
											/>
										) : null}
										<PickingDetails
											loading={fetching}
											job={job}
											relatedJobs={relatedJobs}
											isPackoutEnabled={config?.packoutEnabled}
											isConsolidationEnabled={config?.jobAllocationMethods.includes(
												JobAllocationMethod.Consolidation,
											)}
											isWorkAreaEnabled={config?.workAreasEnabled}
											isSortationEnabled={config?.sortationEnabled}
											condensed={isPackingMode}
										/>
									</>
								</Layout.Section>
								<Layout.Section>
									{isSortationMode ? (
										<SortationDetails loading={fetching} sortWall={sortWall} lines={job?.lines} />
									) : isPackingMode ? null : (
										<PickingProgress
											loading={fetching}
											job={job}
											isExceptionActionable={isExceptionActionable(
												config?.packoutEnabled,
												config?.healingEnabled,
												job,
											)}
										/>
									)}
								</Layout.Section>
								<Layout.Section>
									{!fetching ? (
										isPackingMode ? (
											<PackingLinesTable
												jobId={outboundJobId}
												isInventoryEnabled={config?.inventoryEnabled}
											/>
										) : (
											<PickingLinesTable
												jobId={outboundJobId}
												isInventoryEnabled={config?.inventoryEnabled}
											/>
										)
									) : null}
								</Layout.Section>
							</>
						)}
						<Layout.Section>
							<TimezoneFooter />
						</Layout.Section>
					</Layout>
				</Tabs>
			</Page>
			<Modal
				open={isCancelModalOpen}
				onClose={closeCancelModal}
				title={messages.cancelJob}
				primaryAction={{
					content: messages.cancelJob,
					onAction: cancelJob,
					destructive: true,
					loading: cancelLoading,
				}}
				secondaryActions={[
					{
						content: messages.keepJob,
						onAction: closeCancelModal,
					},
				]}
			>
				<Modal.Section>
					<TextContainer>
						<p>{messages.confirmCancelJobs}</p>
					</TextContainer>
				</Modal.Section>
			</Modal>
		</>
	);
}
