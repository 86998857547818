import { Card, Link, Stack, TextContainer, TextStyle } from '@shopify/polaris';
import { ShippingNotice } from '@sixriver/fulfillment-api-schema';

import styles from './Details.module.css';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { useLocalization } from 'hooks/useLocalization';

export function Details({ shippingNotice }: { shippingNotice?: ShippingNotice }) {
	const { messages, translate } = useLocalization();

	// Compute
	const receiveStartedAt: Date | undefined = (shippingNotice?.inboundLines || [])
		.filter((l) => !!l.receiveStartedAt)
		.map((l) => l.receiveStartedAt)
		.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())?.[0];

	// Render
	return (
		<Card title={messages.details}>
			<Card.Section>
				<Card.Subsection>
					<Stack>
						<div className={styles.detailsLabels}>
							<TextContainer>
								<TextStyle variation="strong">{messages.store}</TextStyle>
							</TextContainer>
							<TextContainer>
								<TextStyle variation="strong">{messages.receivingId}</TextStyle>
							</TextContainer>
							<TextContainer>
								<TextStyle variation="strong">{messages.asnType}</TextStyle>
							</TextContainer>
						</div>
						<div className={styles.detailsData}>
							{/* TODO: add merchant url when available */}
							<TextContainer>
								{shippingNotice?.merchant ? (
									<TextStyle>{shippingNotice?.merchant}</TextStyle>
								) : (
									<NoData />
								)}
							</TextContainer>
							<TextContainer>
								{shippingNotice?.labelUrl ? (
									<Link url={shippingNotice?.labelUrl} removeUnderline external={true}>
										{shippingNotice?.externalId}
									</Link>
								) : (
									<NoData />
								)}
							</TextContainer>
							<TextContainer>
								<TextStyle>
									{shippingNotice?.internalTransfer ? messages.internalTransfer : messages.inbound}
								</TextStyle>
							</TextContainer>
						</div>
					</Stack>
				</Card.Subsection>
				<Card.Subsection>
					<Stack>
						<div className={styles.detailsLabels}>
							<TextContainer>
								<TextStyle variation="strong">{translate(messages.receivingStartedAt)}</TextStyle>
							</TextContainer>
						</div>
						<div className={styles.detailsData}>
							<TextContainer>
								{receiveStartedAt ? (
									<div className={styles.detailsTimestamps}>
										<DateTime date={receiveStartedAt} />
										<RelativeDateTime date={receiveStartedAt} />
									</div>
								) : (
									<NoData />
								)}
							</TextContainer>
						</div>
					</Stack>
				</Card.Subsection>
			</Card.Section>
		</Card>
	);
}
