import { Card, Button } from '@shopify/polaris';
import { Picking, PickingJobStatus, Sortation } from '@sixriver/fulfillment-api-schema';

import { RelatedJobsButton } from '../RelatedJobs';
import styles from './PickingDetails.module.css';
import { CardDetails } from 'components/CardDetails';
import { CopyButton } from 'components/CopyButton';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { getMfpUrl } from 'helpers/mfp';
import { useLocalization } from 'hooks/useLocalization';
import { usePickStrategyName } from 'hooks/usePickStrategies';
import { useSortationJobsCount } from 'hooks/useSortationJobsCount';

interface Props {
	loading?: boolean;
	job?: Picking;
	relatedJobs?: Picking[];
	isPackoutEnabled?: boolean;
	isConsolidationEnabled?: boolean;
	isWorkAreaEnabled?: boolean;
	condensed?: boolean;
	isSortationEnabled?: boolean;
}

export function PickingDetails({
	loading,
	job,
	relatedJobs,
	isPackoutEnabled,
	isConsolidationEnabled,
	isWorkAreaEnabled,
	condensed,
	isSortationEnabled,
}: Props) {
	const { messages } = useLocalization();

	const getPickStrategyName = usePickStrategyName();

	const sortWall = isSortationEnabled
		? ((job?.servicing || []).find((servicing) => servicing.__typename === 'Sortation') as
				| Sortation
				| undefined)
		: undefined;
	const jobsCount = useSortationJobsCount(sortWall);

	if (condensed) {
		return (
			<Card title={messages.pickingDetails} sectioned>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.lpn,
							content: job?.container?.barcode ? (
								<CopyButton text={job?.container?.barcode} />
							) : null,
						},
						{
							label: messages.pickStrategy,
							content: job?.type ? getPickStrategyName(job.type) : null,
						},
					]}
					secondary={[
						{
							label: messages.chuck,
							content: job?.mfp?.name,
						},
						{
							label: messages.container,
							content: job?.container?.name,
						},
						{
							label: messages.takeoff,
							content: job?.takeoffDestination,
						},
					]}
				/>
			</Card>
		);
	}

	return (
		<Card title={messages.pickingDetails}>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.pickStrategy,
							content: job?.type ? getPickStrategyName(job.type) : null,
						},
						...(isConsolidationEnabled
							? [
									{
										label: messages.stagingId,
										content: job?.stagingIdentifier,
									},
									{
										label: messages.totalJobs,
										content: (relatedJobs || []).length,
									},
							  ]
							: []),
						...(isSortationEnabled
							? [
									{
										label: messages.totalJobs,
										content: jobsCount || <NoData />,
									},
							  ]
							: []),
						...(isPackoutEnabled
							? [
									{
										label: messages.packoutRequired,
										content: messages.yes,
									},
							  ]
							: []),
						...(isWorkAreaEnabled
							? [
									{
										label: messages.workArea,
										content: job?.workAreas?.map((workArea) => workArea.name).join(', '),
									},
							  ]
							: []),
						...(isSortationEnabled
							? [
									{
										label: messages.sortationRequired,
										content: messages.yes,
									},
							  ]
							: []),
					]}
					secondary={[
						{
							label: messages.createdAt,
							content: (
								<>
									<DateTime date={job?.timeline?.createdAt} />
									<RelativeDateTime date={job?.timeline?.createdAt} />
								</>
							),
						},
						{
							label: messages.updatedAt,
							content: <DateTime date={job?.timeline?.updatedAt} />,
						},
						{
							label: messages.carrierCutoff,
							content: (
								<>
									<DateTime date={job?.expectedShipDate} />
									<RelativeDateTime
										date={job?.expectedShipDate}
										mode={
											[
												PickingJobStatus.Packed,
												PickingJobStatus.Picked,
												PickingJobStatus.Sorted,
											].includes(job?.pickingStatus as PickingJobStatus)
												? undefined
												: 'deadline'
										}
									/>
								</>
							),
						},
						...(isSortationEnabled
							? [
									{
										label: messages.pickingCompletedAt,
										content: <DateTime date={job?.timeline?.pickedAt} />,
									},
							  ]
							: [
									{
										label: messages.completedAt,
										content: <DateTime date={job?.timeline?.completedAt} />,
									},
							  ]),
					]}
				/>
				<div className={styles.button}>
					<RelatedJobsButton relatedJobs={relatedJobs || []} label={messages.viewRelatedJobs} />
				</div>
			</Card.Section>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.chuck,
							content: job?.mfp?.name,
						},
						{
							label: messages.takeoff,
							content: job?.takeoffDestination,
						},
					]}
					secondary={[
						{
							label: messages.container,
							content: job?.container?.name,
						},
					]}
				/>
				<div className={styles.button}>
					<Button plain url={getMfpUrl(job?.mfp?.name)} disabled={!job?.mfp?.name}>
						{messages.findThisChuck}
					</Button>
				</div>
			</Card.Section>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.uniqueJobId,
							content: job?.taskCoordinatorIdentifier ? (
								<CopyButton text={job.taskCoordinatorIdentifier} />
							) : null,
						},
					]}
				/>
			</Card.Section>
		</Card>
	);
}
