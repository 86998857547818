import { Card } from '@shopify/polaris';
import { BulkOrder } from '@sixriver/fulfillment-api-schema';

import { CardDetails } from 'components/CardDetails';
import { DateTime } from 'components/DateTime';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	loading?: boolean;
	order?: BulkOrder;
}

export function Details({ loading = false, order }: Props) {
	const { messages } = useLocalization();
	return (
		<Card title={messages.details}>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: 'ID',
							content: order?.lpn,
						},
						{
							label: messages.store,
							content: order?.merchant,
						},
						{
							label: messages.earliestCutoff,
							content: <DateTime date={order?.expectedShipDate}></DateTime>,
						},
					]}
				/>
			</Card.Section>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.createdAt,
							content: <DateTime date={order?.createdAt}></DateTime>,
						},
						{
							label: messages.updatedAt,
							content: <DateTime date={order?.updatedAt}></DateTime>,
						},
					]}
				/>
			</Card.Section>
		</Card>
	);
}
