import { Link, Tooltip, TextStyle } from '@shopify/polaris';
import { Picking, PickingJobExceptionStatus } from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';

import {
	SortWallPickingJobsViews,
	SortWallPickingJobsViewStateMap,
} from './SortWallPickingJobsTableViews';
import { DataTable, Column } from 'components/DataTable';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { OutboundJobStatusBadge } from 'components/OutboundJobStatusBadge';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { countLineExceptions } from 'helpers/exception';
import { calculateUnits } from 'helpers/order';
import { paginate } from 'helpers/page-info';
import { getPageSize } from 'helpers/page-size';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface Props {
	pickingJobs?: Picking[];
	loading?: boolean;
}

const VIEW = 'pickingView';

export function SortWallPickingJobsTable({ pickingJobs, loading }: Props) {
	const { messages, translate } = useLocalization();

	const { [VIEW]: view = SortWallPickingJobsViews.All } = useFilters([VIEW]);

	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const filteredPickingJobs = pickingJobs?.filter((pickingJob) =>
		SortWallPickingJobsViewStateMap[view as SortWallPickingJobsViews].includes(
			pickingJob.pickingStatus,
		),
	);

	const counts = Object.keys(SortWallPickingJobsViewStateMap).reduce<
		{ [key in SortWallPickingJobsViews]: number }
	>(
		(counts, view) => ({
			...counts,
			[view]:
				pickingJobs?.filter((pickingJob) =>
					SortWallPickingJobsViewStateMap[view as SortWallPickingJobsViews].includes(
						pickingJob.pickingStatus,
					),
				)?.length || 0,
		}),
		{} as { [key in SortWallPickingJobsViews]: number },
	);

	const { itemsInCurrentPage: pickingJobsInCurrentPage, pageInfo } = paginate({
		itemsList: filteredPickingJobs || [],
		pageSize: getPageSize(),
		paginationCursors,
	});

	const setFilters = useSetFilters();

	const columns: Column[] = [
		{
			type: 'text',
			heading: messages.licensePlate,
		},
		{
			type: 'text',
			heading: messages.createdAt,
		},
		{
			type: 'text',
			heading: messages.units,
		},
		{
			type: 'text',
			heading: messages.lines,
		},
		{
			type: 'text',
			heading: messages.progress,
		},
		{
			type: 'text',
			heading: messages.exceptions,
		},
		{
			type: 'text',
			heading: messages.device,
		},
	];

	const rows = (pickingJobsInCurrentPage || []).map((pickingJob) => {
		const { id, timeline, lines, container, pickingStatus, exception, mfp, packout } = pickingJob;

		const { picked: unitsPicked, total: unitsTotal } = calculateUnits(lines);
		const doneLines = (lines || []).reduce(
			(done, line) => (line.quantity === line.doneQuantity ? (done += 1) : done),
			0,
		);

		const numberOfExceptions = countLineExceptions(lines);

		return [
			<Link url={routes.outboundJob(id)} key="lpn">
				{container?.barcode}
			</Link>,
			<div key="date-created">
				<DateTime date={timeline?.createdAt} />
				<RelativeDateTime date={timeline?.createdAt} />
			</div>,
			<div key="units">
				{translate(messages.pickedOfTotal, {
					picked: unitsPicked,
					total: unitsTotal,
				})}
			</div>,
			<div key="lines">
				{translate(messages.pickedOfTotal, {
					picked: doneLines,
					total: lines.length,
				})}
			</div>,
			<OutboundJobStatusBadge key="progress" pickingStatus={pickingStatus} />,
			exception === PickingJobExceptionStatus.Cleared ? (
				<Tooltip content={messages.jobExceptionsCleared}>
					<TextStyle variation="positive">{messages.cleared}</TextStyle>
				</Tooltip>
			) : numberOfExceptions === 0 ? (
				<NoData />
			) : (
				<Tooltip content={messages.jobExceptionsReported}>
					<TextStyle variation="negative">
						{translate(messages.countExceptions, {
							count: numberOfExceptions,
						})}
					</TextStyle>
				</Tooltip>
			),
			!packout && mfp?.name ? (
				<Link url={`${routes.floorView()}/?chuck=${mfp.name}`}>{mfp.name}</Link>
			) : (
				<NoData />
			),
		];
	});

	return (
		<DataTable
			title={messages.pickingJobs}
			loading={loading}
			columns={columns}
			rows={rows}
			emptyStateHeading={messages.pickingJobsNotFound}
			pageInfo={pageInfo}
			paginationCursors={paginationCursors}
			setPaginationCursors={setPaginationCursors}
			views={[
				{
					label: messages.all,
					metaLabel: counts.All,
					id: SortWallPickingJobsViews.All,
				},
				{
					label: messages.picking,
					metaLabel: counts.Picking,
					id: SortWallPickingJobsViews.Picking,
				},
				{
					label: messages.picked,
					metaLabel: counts.ReadyToSort,
					id: SortWallPickingJobsViews.ReadyToSort,
				},
				{
					label: messages.sorting,
					metaLabel: counts.Sorting,
					id: SortWallPickingJobsViews.Sorting,
				},
				{
					label: messages.sorted,
					metaLabel: counts.Sorted,
					id: SortWallPickingJobsViews.Sorted,
				},
				{
					label: messages.readyToPack,
					metaLabel: counts.ReadyToPack,
					id: SortWallPickingJobsViews.ReadyToPack,
				},
				{
					label: messages.exceptions,
					metaLabel: counts.Exceptions,
					id: SortWallPickingJobsViews.Exceptions,
				},
			]}
			viewKey={VIEW}
			setFilters={setFilters}
			selectedView={view}
			hideFilters
		/>
	);
}
