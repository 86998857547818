import { Button, Navigation } from '@shopify/polaris';
import {
	ChatMajor,
	InventoryMajor,
	DnsSettingsMajor,
	DataVisualizationMajor,
	RiskMajor,
	CircleRightMajor,
	CircleLeftMajor,
	ProfileMajor,
	ProductsMajor,
	LocationMajor,
	DigitalMediaReceiverMajor,
	ChecklistAlternateMajor,
} from '@shopify/polaris-icons';
import { JobAllocationMethod } from '@sixriver/fulfillment-api-schema';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './Nav.module.css';
import { useAuth } from 'hooks/useAuth';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';
import { UserRole } from 'providers/AuthProvider';
import * as routes from 'routes';

export function Nav() {
	const { messages } = useLocalization();
	const { pathname } = useLocation();
	const { isUserAllowed } = useAuth();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	const { config } = useConfig();

	const isSelected = (paths: string[]) => {
		return paths.some((path) => pathname.startsWith(path));
	};

	const main = [
		{
			url: routes.orders(),
			label: messages.outbound,
			icon: CircleLeftMajor,
			selected: isSelected([
				routes.orders(),
				routes.outboundJobs(),
				routes.bulkOrders(),
				routes.sortWalls(),
			]),
			subNavigationItems: [
				{
					url: routes.orders(),
					label: messages.orders,
					selected: isSelected([routes.orders()]),
				},
				{
					url: routes.outboundJobs(),
					label: messages.outboundJobs,
					selected: isSelected([routes.outboundJobs()]),
				},
				...(config?.jobAllocationMethods.includes(JobAllocationMethod.BulkOrders)
					? [
							{
								url: routes.bulkOrders(),
								label: messages.bulkOrders,
								selected: isSelected([routes.bulkOrders()]),
							},
					  ]
					: []),
				...(config?.sortationEnabled
					? [
							{
								url: routes.sortWalls(),
								label: messages.sortWalls,
								selected: isSelected([routes.sortWalls()]),
							},
					  ]
					: []),
			],
		},
		...(config?.receivingEnabled || config?.returnsEnabled || config?.replenishmentEnabled
			? [
					{
						url: config?.receivingEnabled
							? routes.inboundShipments()
							: config?.returnsEnabled
							? routes.inboundReturns()
							: routes.replenishJobs(),
						label: messages.inbound,
						icon: CircleRightMajor,
						selected: isSelected([
							routes.inboundReturns(),
							routes.inboundShipments(),
							routes.replenishJobs(),
						]),
						subNavigationItems: [
							...(config?.receivingEnabled
								? [
										{
											url: routes.inboundShipments(),
											label: messages.inboundShipments,
											selected: isSelected([routes.inboundShipments()]),
										},
								  ]
								: []),
							...(config?.returnsEnabled
								? [
										{
											url: routes.inboundReturns(),
											label: messages.returns,
											selected: isSelected([routes.inboundReturns()]),
										},
								  ]
								: []),
							...(config?.replenishmentEnabled
								? [
										{
											url: routes.replenishJobs(),
											label: messages.replenishJobs,
											selected: isSelected([routes.replenishJobs()]),
										},
								  ]
								: []),
						],
					},
			  ]
			: []),
		{
			url: routes.products(),
			label: config?.inventoryEnabled ? messages.inventory : messages.products,
			icon: ProductsMajor,
			selected: isSelected([
				routes.containers(),
				routes.products(),
				routes.countJobs(),
				routes.moveJobs(),
				...(config?.inventoryEnabled ? [routes.locations()] : []),
			]),
			subNavigationItems: [
				...(config?.inventoryEnabled
					? [
							{
								url: routes.products(),
								label: messages.products,
								selected: isSelected([routes.products()]),
							},
							{
								url: routes.locations(),
								label: messages.locations,
								selected: isSelected([routes.locations()]),
							},
							{
								url: routes.containers(),
								label: messages.containers,
								selected: isSelected([routes.containers()]),
							},
					  ]
					: []),
				...(config?.countJobsUrl
					? [
							{
								url: routes.countJobs(),
								label: messages.dashCountJobs,
								selected: isSelected([routes.countJobs()]),
							},
					  ]
					: []),
				...(config?.moveJobsUrl
					? [
							{
								url: routes.moveJobs(),
								label: messages.dashMoveJobs,
								selected: isSelected([routes.moveJobs()]),
							},
					  ]
					: []),
			],
		},
		...(config?.inventoryEnabled
			? [
					{
						url: routes.specialProjects(),
						label: messages.specialProjects,
						icon: ChecklistAlternateMajor,
						selected: isSelected([routes.specialProjects()]),
					},
			  ]
			: [
					{
						url: routes.locations(),
						label: messages.locations,
						icon: LocationMajor,
						selected: isSelected([routes.locations()]),
					},
			  ]),
		...(isUserAllowed([UserRole.Admin])
			? [
					{
						url: routes.employees(),
						label: messages.employees,
						icon: ProfileMajor,
						selected: isSelected([routes.employees()]),
					},
			  ]
			: []),
		{
			url: routes.devices(),
			label: messages.devices,
			icon: DigitalMediaReceiverMajor,
			selected: isSelected([routes.devices()]),
		},
		...(config?.healingEnabled
			? [
					{
						url: routes.exceptions(),
						label: messages.exceptions,
						icon: RiskMajor,
						selected: isSelected([routes.exceptions()]),
					},
			  ]
			: []),
	];

	const tools = [
		{
			url: routes.floorView(),
			label: messages.floorView,
			icon: InventoryMajor,
			selected: isSelected([routes.floorView()]),
		},
		{
			url: routes.analytics(),
			label: messages.analytics,
			icon: DataVisualizationMajor,
			selected: isSelected([routes.analytics()]),
		},
		{
			url: routes.support(),
			label: messages.support,
			icon: ChatMajor,
			selected: isSelected([routes.support()]),
		},
		{
			url: routes.allocationRules(),
			label: messages.allocationRules,
			icon: DnsSettingsMajor,
			selected: isSelected([routes.allocationRules()]),
		},
	];

	return config ? (
		<Navigation location="/">
			<Navigation.Section items={main} />
			<Navigation.Section title={messages.tools} items={tools} />
			<div className={styles.feedbackLinkContainer}>
				<Button primary external url="https://www.surveymonkey.com/r/6RSBridgeFeedback">
					{messages.feedback}
				</Button>
			</div>
			<small className={styles.version}>{REACT_APP_VERSION || 'DEVELOPMENT'}</small>
		</Navigation>
	) : null;
}
