import gql from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from 'graphql/page-info';

export const ORDERS_QUERY = gql`
	query Orders(
		$after: String
		$first: Int
		$statuses: [OrderState!]!
		$searchText: String
		$orderBy: OrderOrderByFields
		$orderByDirection: OrderByDirection
		$pickTypes: [PickStrategy!]
		$expectedShipDateFrom: DateTime
		$expectedShipDateTo: DateTime
		$jobUpdatedAtFrom: DateTime
		$lateOnly: Boolean
		$projectionIds: [String!]
		$hasCompletedAt: Boolean
		$jobCreatedAtFrom: DateTime
		$jobCreatedAtTo: DateTime
		$workAreaIds: [String!]
		$jobFlowRuleLpn: String
		$workOrderTypes: [WorkOrderTypes!]
		$bulkOrderProjectionId: String
	) {
		orders(
			after: $after
			first: $first
			statuses: $statuses
			searchText: $searchText
			orderBy: $orderBy
			orderByDirection: $orderByDirection
			pickTypes: $pickTypes
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			jobUpdatedAtFrom: $jobUpdatedAtFrom
			lateOnly: $lateOnly
			projectionIds: $projectionIds
			hasCompletedAt: $hasCompletedAt
			jobCreatedAtFrom: $jobCreatedAtFrom
			jobCreatedAtTo: $jobCreatedAtTo
			workAreaIds: $workAreaIds
			jobFlowRuleLpn: $jobFlowRuleLpn
			workOrderTypes: $workOrderTypes
			bulkOrderProjectionId: $bulkOrderProjectionId
		) {
			edges {
				node {
					id
					type
					status
					customerIdentifier
					customers {
						name
					}
					expectedShipDate
					timeline {
						createdAt
					}
					lines {
						id
						status
						quantity
						doneQuantity
						timeline {
							completedAt
						}
					}
					exception {
						status
					}
					servicedBy {
						... on Picking {
							lines {
								status
							}
							packout {
								id
							}
						}
						... on Sortation {
							id
							wall
						}
					}
					healPlan {
						status
					}
					workAreas {
						id
						name
					}
					tag
					jobFlowRuleLpn
					workOrderTypes
					destinationNode
					bulkOrderProjectionId
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;

export const FILTERED_COUNTS_QUERY = gql`
	query CountFilteredOrders(
		$statuses: [OrderState!]!
		$searchText: String
		$pickTypes: [PickStrategy!]
		$expectedShipDateFrom: DateTime
		$expectedShipDateTo: DateTime
		$jobUpdatedAtFrom: DateTime
		$lateOnly: Boolean
		$projectionIds: [String!]
		$hasCompletedAt: Boolean
	) {
		FilteredTotalsCount: ordersCount(
			statuses: $statuses
			searchText: $searchText
			pickTypes: $pickTypes
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			jobUpdatedAtFrom: $jobUpdatedAtFrom
			lateOnly: $lateOnly
			projectionIds: $projectionIds
			hasCompletedAt: $hasCompletedAt
		) {
			count
		}
	}
`;

export const COUNTS_QUERY = gql`
	query CountOrders(
		$jobUpdatedAtFrom: DateTime
		$expectedShipDateFrom: DateTime
		$expectedShipDateTo: DateTime
		$searchText: String
		$pickTypes: [PickStrategy!]
		$projectionIds: [String!]
		$hasCompletedAt: Boolean
		$jobCreatedAtFrom: DateTime
		$jobCreatedAtTo: DateTime
		$workAreaIds: [String!]
		$jobFlowRuleLpn: String
		$bulkOrderProjectionId: String
	) {
		StaticTotalCount: ordersCount(
			statuses: [Unassigned, BeingServiced, InProgress, Sorting, Packing, Packable, Offline]
			workAreaIds: $workAreaIds
			jobCreatedAtFrom: $jobCreatedAtFrom
			jobCreatedAtTo: $jobCreatedAtTo
		) {
			count
		}
		StaticUnassignedCount: ordersCount(
			statuses: [Unassigned, BeingServiced]
			workAreaIds: $workAreaIds
			jobCreatedAtFrom: $jobCreatedAtFrom
			jobCreatedAtTo: $jobCreatedAtTo
		) {
			count
		}

		StaticInProgressCount: ordersCount(
			statuses: [InProgress, Sorting, Packing, Packable]
			workAreaIds: $workAreaIds
			jobCreatedAtFrom: $jobCreatedAtFrom
			jobCreatedAtTo: $jobCreatedAtTo
		) {
			count
		}
		StaticCompletedTodayCount: ordersCount(
			statuses: [Done, Sorted, Packed]
			workAreaIds: $workAreaIds
			jobUpdatedAtFrom: $jobUpdatedAtFrom
			hasCompletedAt: $hasCompletedAt
			jobCreatedAtFrom: $jobCreatedAtFrom
			jobCreatedAtTo: $jobCreatedAtTo
		) {
			count
		}
		UnassignedCount: ordersCount(
			searchText: $searchText
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			statuses: [Unassigned, BeingServiced]
			pickTypes: $pickTypes
			projectionIds: $projectionIds
			jobCreatedAtFrom: $jobCreatedAtFrom
			jobCreatedAtTo: $jobCreatedAtTo
			workAreaIds: $workAreaIds
			jobFlowRuleLpn: $jobFlowRuleLpn
			bulkOrderProjectionId: $bulkOrderProjectionId
		) {
			count
		}
		InProgressCount: ordersCount(
			searchText: $searchText
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			statuses: [InProgress, Sorting, Packing, Packable]
			pickTypes: $pickTypes
			projectionIds: $projectionIds
			jobCreatedAtFrom: $jobCreatedAtFrom
			jobCreatedAtTo: $jobCreatedAtTo
			workAreaIds: $workAreaIds
			jobFlowRuleLpn: $jobFlowRuleLpn
			bulkOrderProjectionId: $bulkOrderProjectionId
		) {
			count
		}
		CompletedTodayCount: ordersCount(
			searchText: $searchText
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			statuses: [Done, Sorted, Packed]
			pickTypes: $pickTypes
			projectionIds: $projectionIds
			jobUpdatedAtFrom: $jobUpdatedAtFrom
			hasCompletedAt: $hasCompletedAt
			jobCreatedAtFrom: $jobCreatedAtFrom
			jobCreatedAtTo: $jobCreatedAtTo
			workAreaIds: $workAreaIds
			jobFlowRuleLpn: $jobFlowRuleLpn
			bulkOrderProjectionId: $bulkOrderProjectionId
		) {
			count
		}
		ExceptionsCount: ordersCount(
			searchText: $searchText
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			statuses: [Exception]
			pickTypes: $pickTypes
			projectionIds: $projectionIds
			jobCreatedAtFrom: $jobCreatedAtFrom
			jobCreatedAtTo: $jobCreatedAtTo
			workAreaIds: $workAreaIds
			jobFlowRuleLpn: $jobFlowRuleLpn
			bulkOrderProjectionId: $bulkOrderProjectionId
		) {
			count
		}
		ExceptionsTodayCount: ordersCount(
			searchText: $searchText
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			statuses: [Exception]
			pickTypes: $pickTypes
			projectionIds: $projectionIds
			jobUpdatedAtFrom: $jobUpdatedAtFrom
			jobCreatedAtFrom: $jobCreatedAtFrom
			jobCreatedAtTo: $jobCreatedAtTo
			workAreaIds: $workAreaIds
			jobFlowRuleLpn: $jobFlowRuleLpn
			bulkOrderProjectionId: $bulkOrderProjectionId
		) {
			count
		}
		LateCount: ordersCount(
			searchText: $searchText
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			statuses: [Unassigned, InProgress, AwaitingInventory, Sorting, Packable, Packing]
			pickTypes: $pickTypes
			projectionIds: $projectionIds
			workAreaIds: $workAreaIds
			lateOnly: true
			jobCreatedAtFrom: $jobCreatedAtFrom
			jobCreatedAtTo: $jobCreatedAtTo
			jobFlowRuleLpn: $jobFlowRuleLpn
			bulkOrderProjectionId: $bulkOrderProjectionId
		) {
			count
		}
		AwaitingInventoryCount: ordersCount(
			searchText: $searchText
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			statuses: [AwaitingInventory]
			pickTypes: $pickTypes
			projectionIds: $projectionIds
			jobCreatedAtFrom: $jobCreatedAtFrom
			jobCreatedAtTo: $jobCreatedAtTo
			workAreaIds: $workAreaIds
			jobFlowRuleLpn: $jobFlowRuleLpn
			bulkOrderProjectionId: $bulkOrderProjectionId
		) {
			count
		}
		CancelledCount: ordersCount(
			searchText: $searchText
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			statuses: [Cancelled]
			pickTypes: $pickTypes
			projectionIds: $projectionIds
			jobCreatedAtFrom: $jobCreatedAtFrom
			jobCreatedAtTo: $jobCreatedAtTo
			jobUpdatedAtFrom: $jobUpdatedAtFrom
			workAreaIds: $workAreaIds
			jobFlowRuleLpn: $jobFlowRuleLpn
			bulkOrderProjectionId: $bulkOrderProjectionId
		) {
			count
		}
		InterruptedCount: ordersCount(
			searchText: $searchText
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			statuses: [Offline]
			pickTypes: $pickTypes
			projectionIds: $projectionIds
			jobCreatedAtFrom: $jobCreatedAtFrom
			jobCreatedAtTo: $jobCreatedAtTo
			workAreaIds: $workAreaIds
			jobFlowRuleLpn: $jobFlowRuleLpn
			bulkOrderProjectionId: $bulkOrderProjectionId
		) {
			count
		}
	}
`;

export const CUTOFF_DATES_QUERY = gql`
	query CutoffForOrders {
		cutoffForOrders {
			dates
		}
	}
`;

export const CANCEL_ORDERS_MUTATION = gql`
	mutation CancelOrders(
		$after: String
		$first: Int
		$statuses: [OrderState!]!
		$searchText: String
		$pickTypes: [PickStrategy!]
		$expectedShipDateFrom: DateTime
		$expectedShipDateTo: DateTime
		$jobUpdatedAtFrom: DateTime
		$lateOnly: Boolean
		$projectionIds: [String!]
		$hasCompletedAt: Boolean
	) {
		cancelOrders(
			after: $after
			first: $first
			statuses: $statuses
			searchText: $searchText
			pickTypes: $pickTypes
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			jobUpdatedAtFrom: $jobUpdatedAtFrom
			lateOnly: $lateOnly
			projectionIds: $projectionIds
			hasCompletedAt: $hasCompletedAt
		) {
			success
			referenceId
		}
	}
`;
