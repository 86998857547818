import { Select, SelectProps } from '@shopify/polaris';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

type Props<T> = UseControllerProps<T> &
	Omit<SelectProps, 'onChange'> & { onChange?: SelectProps['onChange'] };

export function FormSelect<T extends FieldValues>({
	name,
	control,
	rules,
	defaultValue,
	onChange,
	...props
}: Props<T>) {
	const {
		field: { value, onChange: onChageField, onBlur },
	} = useController({
		name,
		control,
		rules,
		defaultValue,
	});

	return (
		<Select
			{...props}
			name={name}
			value={value}
			onBlur={onBlur}
			onChange={(...args) => {
				if (onChange) {
					onChange(...args);
				}
				onChageField(...args);
			}}
		/>
	);
}
