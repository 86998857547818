import { Page, Layout, Modal, Stack, TextContainer } from '@shopify/polaris';
import { PrintMajor } from '@shopify/polaris-icons';
import {
	MutationCancelSortWallArgs,
	MutationPrintSortationLabelsArgs,
	QuerySortationByProjectionIdArgs,
	Sortation,
	SortationStatus,
} from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useMutation } from 'urql';

import { SORT_WALL_QUERY, CANCEL_JOB_MUTATION, REPRINT_LABELS_MUTATION } from './SortWall.graphql';
import { SortWallBreakdown } from './SortWallBreakdown';
import { SortWallDetails } from './SortWallDetails';
import { SortWallLinesTable } from './SortWallLinesTable';
import { SortWallOrdersTable } from './SortWallOrdersTable';
import { SortWallPickingJobsTable } from './SortWallPickingJobsTable';
import { AutoRefresh } from 'components/AutoRefresh';
import { Error } from 'components/Error';
import { ErrorBanner } from 'components/ErrorBanner';
import { SortationStatusBadge } from 'components/SortationStatusBadge';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { usePrintSortWallLabel } from 'hooks/usePrintLabelButton';
import { useToast } from 'hooks/useToast';
import * as routes from 'routes';

export function SortWall() {
	const { messages, translate } = useLocalization();

	const {
		params: { sortWallId },
	} = useRouteMatch<{ sortWallId: string }>();

	const history = useHistory();

	const { showToast } = useToast();

	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	const projectionId = sortWallId;

	const [{ fetching: sortWallFetching, error: sortWallError, data: sortWallData }] =
		usePollingQuery<{ sortationByProjectionId: Sortation }, QuerySortationByProjectionIdArgs>({
			query: SORT_WALL_QUERY,
			pollInterval: queryPollInterval,
			variables: {
				projectionId,
			},
		});

	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

	const closeCancelModal = () => {
		setIsCancelModalOpen(false);
	};

	const openCancelModal = () => {
		setIsCancelModalOpen(true);
	};

	const [{ fetching: cancelLoading }, cancelMutation] = useMutation<
		{ cancelSortWall: Response },
		MutationCancelSortWallArgs
	>(CANCEL_JOB_MUTATION);

	const [{ fetching: printLoading }, printMutation] = useMutation<
		{ printSortationLabels: Response },
		MutationPrintSortationLabelsArgs
	>(REPRINT_LABELS_MUTATION);

	const { config } = useConfig();

	const sortWall = sortWallData?.sortationByProjectionId;

	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);

	const { buttonText: printLabelButtonText, onLabelPrintSuccess } = usePrintSortWallLabel({
		id: sortWallId,
		disabled: sortWallFetching,
	});

	const printLabels = async () => {
		setIsBannerErrorVisible(false);

		try {
			const { error } = await printMutation({ projectionId: sortWallId });

			if (error) {
				throw error;
			}

			onLabelPrintSuccess();

			showToast(messages.labelsPrinted);
		} catch (e) {
			setIsBannerErrorVisible(true);
		}
	};

	const cancelJob = async () => {
		setIsBannerErrorVisible(false);

		try {
			const { error } = await cancelMutation({ projectionId: sortWallId });

			closeCancelModal();

			if (error) {
				throw error;
			}

			showToast(messages.sortWallCanceled);
			history.push(routes.sortWalls());
		} catch (e) {
			setIsBannerErrorVisible(true);
		}
	};

	if (sortWallError) {
		return <Error graphQLError={sortWallError} />;
	}

	if (!sortWallFetching && !sortWall) {
		return <Error heading={messages.sortWallNotFound} />;
	}

	return (
		<>
			<Page
				title={
					sortWallFetching
						? ''
						: (translate(messages.sortWallByName, { name: sortWall?.wall }) as string)
				}
				titleMetadata={
					<Stack spacing="extraTight">
						<SortationStatusBadge
							sortationStatus={sortWall?.status as SortationStatus}
							isEmptyIfNoData
						/>
					</Stack>
				}
				secondaryActions={[
					{
						content: printLabelButtonText,
						icon: PrintMajor,
						onAction: printLabels,
						loading: printLoading,
						disabled: sortWallFetching,
					},
					{
						content: messages.cancelWall,
						onAction: openCancelModal,
						disabled: !sortWall?.isCancelable || sortWallFetching,
					},
				]}
			>
				<AutoRefresh
					pollingEnabled={pollingEnabled}
					togglePolling={togglePolling}
					discriminatorData={sortWallData}
				/>

				<Layout>
					<Layout.Section>
						<ErrorBanner
							isVisible={isBannerErrorVisible}
							onDismiss={() => {
								setIsBannerErrorVisible(false);
							}}
						/>
					</Layout.Section>

					<Layout.Section>
						<SortWallDetails loading={sortWallFetching} sortWall={sortWall} />
					</Layout.Section>

					<Layout.Section>
						<SortWallBreakdown loading={sortWallFetching} sortWall={sortWall} />
					</Layout.Section>

					<Layout.Section>
						<SortWallLinesTable
							lines={sortWallData?.sortationByProjectionId?.lines}
							isInventoryEnabled={config?.inventoryEnabled}
							loading={sortWallFetching}
						/>
					</Layout.Section>

					<Layout.Section>
						<SortWallOrdersTable
							orders={sortWallData?.sortationByProjectionId?.customerOrders}
							loading={sortWallFetching}
						/>
					</Layout.Section>

					<Layout.Section>
						<SortWallPickingJobsTable
							pickingJobs={sortWallData?.sortationByProjectionId?.pickingJobs}
							loading={sortWallFetching}
						/>
					</Layout.Section>

					<Layout.Section>
						<TimezoneFooter />
					</Layout.Section>
				</Layout>
			</Page>
			<Modal
				open={isCancelModalOpen}
				onClose={closeCancelModal}
				title={messages.cancelSortWall}
				primaryAction={{
					content: messages.cancelSortWall,
					onAction: cancelJob,
					destructive: true,
					loading: cancelLoading,
				}}
				secondaryActions={[
					{
						content: messages.keepSortWall,
						onAction: closeCancelModal,
					},
				]}
			>
				<Modal.Section>
					<TextContainer>
						<p>{messages.cancelSortWallConfirm}</p>
					</TextContainer>
				</Modal.Section>
			</Modal>
		</>
	);
}
