/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Form, FormLayout, Modal } from '@shopify/polaris';
import { useDebouncedValue } from '@shopify/react-hooks';
import {
	SpecialProjectType,
	UserActivityType,
	UserConnection,
	WorkLog as WorkLogApi,
} from '@sixriver/fulfillment-api-schema';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'urql';

import { EMPLOYEES_QUERY } from './SpecialProjects.graphql';
import { FormAutoCompleteTextField } from 'components/FormFields/FormAutoCompleteTextField';
import { FormSelect } from 'components/FormFields/FormSelect';
import { FormTextField } from 'components/FormFields/FormTextField';
import { MILLISECONDS_PER_HOUR } from 'helpers/time';
import { useLocalization } from 'hooks/useLocalization';
import { formatIsoDate } from 'utils/date';

interface AddEditWorkLogForm {
	associateName: string;
	date: string;
	task: string;
	time: string;
}

interface LabelValue {
	label: string;
	value: string;
}

interface WorkLogAddEditModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSave: (updatedLogItem: Partial<WorkLogApi>) => void;
	isEditMode?: boolean;
	logItem?: WorkLogApi;
	projectType?: SpecialProjectType | null;
}

// TODO this component has too many callback and memoization functions to the point
// that I couldn't confidently update it to support the localization hook. I disabled
// the exhaustive-deps check. Suggest refactoring. --a.m.
export function WorkLogAddEditModal({
	isOpen: isModalOpen,
	onClose,
	onSave,
	logItem,
	isEditMode,
	projectType,
}: WorkLogAddEditModalProps) {
	const { messages, translate } = useLocalization();
	const [searchText, setSearchText] = useState('');
	const [selectedOptions, setSelectedOptions] = useState<LabelValue[]>([]);
	const [options, setOptions] = useState<LabelValue[]>([]);
	const debouncedSearchText = useDebouncedValue(searchText);

	const { control, handleSubmit, formState, setValue, reset } = useForm<AddEditWorkLogForm>({
		mode: 'onBlur',
	});

	const { isDirty: isSaveEnabled, errors } = formState;

	// queries
	const [{ data }] = useQuery<{
		users: UserConnection;
	}>({
		query: EMPLOYEES_QUERY,
		variables: {
			type: UserActivityType.Active,
			searchText: debouncedSearchText,
		},
	});

	const autocompleteOptions: LabelValue[] = useMemo(() => {
		if (searchText === '') {
			return [];
		}
		const activeEmployees = data?.users.edges || [];

		return activeEmployees.map((employee) => {
			return {
				label: employee.node.name ?? messages.unnamed,
				value: employee.node.id?.toString(),
			};
		});
	}, [data?.users.edges, searchText]);

	useEffect(() => {
		setOptions(autocompleteOptions);
	}, [autocompleteOptions]);

	useEffect(() => {
		if (logItem?.user?.name) {
			setSelectedOptions([{ label: logItem.user.name, value: logItem.user.id }]);
			setOptions([{ label: logItem.user.name, value: logItem.user.id }]);
		}
	}, [logItem?.user?.id, logItem?.user?.name]);

	// validation functions
	const getFieldRequiredErrorMessage = (fieldName: keyof AddEditWorkLogForm) => {
		return translate(messages.resourceIsRequired, { resource: messages[fieldName] }) as string;
	};

	const greaterThanOrEqualToZero = useCallback(
		(value: string, fieldName: keyof AddEditWorkLogForm): boolean | string => {
			return (parseFloat(value) >= 0 ||
				translate(messages.fieldGreaterThanOrEqualToZero, {
					field: messages[fieldName],
				})) as string;
		},
		[],
	);

	const validUserFromList = useCallback(
		(value: string): boolean | string => {
			if (isEditMode) {
				return true;
			}

			return autocompleteOptions.some((option) => option.label === value)
				? true
				: messages.associateNotFound;
		},
		[autocompleteOptions, isEditMode],
	);

	// functions
	const onModalClose = useCallback(() => {
		reset();
		setSelectedOptions([]);
		setSearchText('');
		onClose();
	}, [onClose, reset]);

	const submitForm = handleSubmit((data: AddEditWorkLogForm) => {
		const user = selectedOptions.find((option) => option.label === data.associateName);

		if (user) {
			const updatedLogItem: Partial<WorkLogApi> = {
				dateLogged: convertDatePickerToDate(data.date),
				timeLogged: parseFloat(data.time) * MILLISECONDS_PER_HOUR,
				task: data.task,
				user: {
					id: user.value,
					// Partial<> doesn't apply to child objects, so putting a dummy value here for now
					locale: 'N/A',
				},
			};

			onSave(updatedLogItem);
			onModalClose();
		}
	});

	// Autocomplete input setup
	const updateText = useCallback((value) => {
		setSearchText(value);
	}, []);

	const updateSelection = useCallback(
		(selected: string[]) => {
			const selectedOptions = selected
				.map((selectedItem) => {
					return options.find((option) => {
						return RegExp(selectedItem).exec(option.value);
					});
				})
				.filter((option) => !!option) as LabelValue[];
			const selectedValues = selectedOptions.map((option) => option.label).join(', ');

			setSelectedOptions(selectedOptions);
			setValue('associateName', selectedValues, { shouldDirty: true, shouldValidate: true });
		},
		[options, setValue],
	);

	const textField = (
		<FormAutoCompleteTextField
			name="associateName"
			label={messages.associate}
			placeholder={messages.searchName}
			control={control}
			onChange={updateText}
			disabled={isEditMode}
			autoComplete="off"
			defaultValue={logItem?.user?.name ?? ''}
			rules={{
				required: {
					value: true,
					message: getFieldRequiredErrorMessage('associateName'),
				},
				validate: {
					fromList: (value) => validUserFromList(value),
				},
			}}
			error={errors.associateName?.message}
		/>
	);

	const timeInputElement = useMemo(() => {
		return (
			<FormTextField
				key="time"
				name="time"
				type="number"
				label={messages.timeSpent}
				control={control}
				suffix={messages.hours.toLocaleLowerCase()}
				autoComplete="off"
				inputMode="numeric"
				placeholder="0.00"
				min={0}
				step={0.01}
				rules={{
					required: {
						value: true,
						message: getFieldRequiredErrorMessage('time'),
					},
					validate: {
						gteZero: (value) => greaterThanOrEqualToZero(value, 'time'),
					},
				}}
				error={errors.time?.message}
				defaultValue={logItem ? (logItem.timeLogged / MILLISECONDS_PER_HOUR).toFixed(2) : ''}
			/>
		);
	}, [control, errors.time?.message, greaterThanOrEqualToZero, logItem]);

	const taskOptions = useMemo(() => {
		switch (projectType) {
			case SpecialProjectType.Kitting: {
				const options: LabelValue[] = [
					messages.workLogTypes.inventoryMovement,
					messages.workLogTypes.stationSetup,
					messages.workLogTypes.sopReview,
					messages.workLogTypes.stationCleanup,
					messages.workLogTypes.otherType,
				].map((msg) => {
					return { label: msg, value: msg };
				});

				if (logItem && !options.find((task) => task.value === logItem.task)) {
					options.push({ label: logItem.task, value: logItem.task });
				}

				return options;
			}

			default:
				return [];
		}
	}, [logItem, projectType]);

	const formLayoutAfterFirstRow = useMemo(() => {
		const useSelectBoxFormat = taskOptions.length > 0;

		return useSelectBoxFormat ? (
			<FormLayout.Group condensed>
				<FormSelect
					name="task"
					label={messages.task}
					control={control}
					options={taskOptions}
					disabled={isEditMode}
					rules={{
						required: {
							value: true,
							message: getFieldRequiredErrorMessage('task'),
						},
					}}
					error={errors.task?.message}
					placeholder={messages.select}
					defaultValue={logItem?.task ?? ''}
				/>
				{timeInputElement}
			</FormLayout.Group>
		) : (
			<FormLayout>
				<FormLayout.Group condensed>{timeInputElement}</FormLayout.Group>
				<FormLayout.Group>
					<FormTextField
						name="task"
						label={messages.task}
						control={control}
						rules={{
							required: {
								value: true,
								message: getFieldRequiredErrorMessage('task'),
							},
						}}
						error={errors.task?.message}
						disabled={isEditMode}
						autoComplete="off"
						maxLength={100}
						minLength={1}
						multiline={true}
						showCharacterCount
						placeholder={messages.workLogTaskHelperText}
						defaultValue={logItem?.task ?? ''}
					/>
				</FormLayout.Group>
			</FormLayout>
		);
	}, [control, errors.task?.message, isEditMode, logItem?.task, taskOptions, timeInputElement]);

	return (
		<Modal
			open={isModalOpen}
			onClose={onModalClose}
			title={messages.logWork}
			primaryAction={{
				content: messages.save,
				onAction: submitForm,
				disabled: !isSaveEnabled,
			}}
			secondaryActions={[
				{
					content: messages.cancel,
					onAction: onModalClose,
				},
			]}
		>
			<Modal.Section>
				<Form onSubmit={submitForm}>
					<FormLayout>
						<FormLayout.Group condensed>
							<Autocomplete
								options={options}
								selected={selectedOptions.map((option) => option.label)}
								onSelect={updateSelection}
								textField={textField}
							/>
							<FormTextField
								autoComplete="on"
								key="date"
								name="date"
								type="date"
								label={messages.date}
								control={control}
								disabled={isEditMode}
								defaultValue={formatIsoDate(logItem ? new Date(logItem?.dateLogged) : new Date())}
							/>
						</FormLayout.Group>
						{formLayoutAfterFirstRow}
					</FormLayout>
				</Form>
			</Modal.Section>
		</Modal>
	);
}

function convertDatePickerToDate(dateString: string): Date {
	const newDate = new Date();
	const [year, month, day] = dateString.split('-');

	newDate.setFullYear(parseInt(year, 10));
	newDate.setMonth(parseInt(month, 10) - 1);
	newDate.setDate(parseInt(day, 10));

	return newDate;
}
