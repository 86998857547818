import { Page } from '@shopify/polaris';
import { MutationResponse, StorageLocationInput } from '@sixriver/fulfillment-api-schema';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from 'urql';

import { StorageLocationForm } from './Location.form';
import { ADD_NEW_LOCATION_MUTATION } from './Locations.graphql';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

const formData: StorageLocationInput = {
	address: '',
	containerTypeId: undefined,
	x: 0,
	y: 0,
	z: 0,
	description: '',
	externalAisleId: '',
	id: '',
	type: undefined,
};

export function AddStorageLocation(): JSX.Element {
	const { messages } = useLocalization();

	// Routing
	const history = useHistory();

	// Mutations
	const [{ error: addStorageLocationError }, addStorageLocationMutation] = useMutation<
		{ addLocation: MutationResponse },
		{ input: StorageLocationInput }
	>(ADD_NEW_LOCATION_MUTATION);

	// Methods
	const onSubmit = useCallback(
		async (input: StorageLocationInput) => {
			const { data } = await addStorageLocationMutation({ input });
			if (data?.addLocation?.referenceId) {
				history.push(routes.locations());
			}
		},
		[addStorageLocationMutation, history],
	);

	// Render
	return (
		<Page
			title={messages.addLocation}
			breadcrumbs={[{ content: messages.locations, url: routes.locations() }]}
			fullWidth
		>
			<StorageLocationForm
				mode="add"
				data={formData}
				onSubmit={onSubmit}
				error={addStorageLocationError}
			/>
		</Page>
	);
}
