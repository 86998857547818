import { Banner, Card, ContextualSaveBar, Form, FormLayout, Page } from '@shopify/polaris';
import {
	GoalStates,
	KittingProject as KittingProjectApi,
	MutationResponse,
	PreKitException,
	PreKitExceptionReason,
} from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import { Product } from './Product';
import { RouteMatchParams } from './SpecialProject';
import { SpecialProjectReviewModal } from './SpecialProjectReviewModal';
import { REPORT_ISSUES_MUTATION, SPECIAL_PROJECT_QUERY } from './SpecialProjects.graphql';
import { FormTextField } from 'components/FormFields/FormTextField';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface FormData {
	[index: string]: string;
}

export function ReportIssues() {
	const { messages } = useLocalization();

	const {
		params: { projectId },
	} = useRouteMatch<RouteMatchParams>();

	const history = useHistory();

	const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);

	// Data
	const [{ data }] = useQuery<{ specialProject: KittingProjectApi }, { id: string }>({
		query: SPECIAL_PROJECT_QUERY,
		variables: {
			id: projectId,
		},
	});

	const project = data?.specialProject;

	// Mutations
	const [{ error }, reportIssues] =
		useMutation<{ reportIssues: MutationResponse }>(REPORT_ISSUES_MUTATION);

	const { control, handleSubmit, reset, formState, getValues } = useForm({
		mode: 'onBlur',
	});

	const { isDirty: isSaveEnabled } = formState;
	const isSaveableState =
		project?.status && [GoalStates.Ready, GoalStates.Running].includes(project?.status);

	const submitForm = handleSubmit(async (data: FormData) => {
		if (project) {
			const apiValues = Object.entries(data)
				.map(([key, values]) => {
					return Object.entries(values).map(([issueKey, issueValue]) => {
						return {
							reason: issueKey,
							eachQuantity: issueValue ? parseInt(issueValue, 10) : undefined,
							productId: key,
						};
					});
				})
				.flat()
				.filter((issues) => issues.eachQuantity);

			const { data: issuesData } = await reportIssues(
				{ id: project.id, exceptions: apiValues },
				{ additionalTypenames: ['KittingProject', 'PreKitException'] },
			);

			if (!error && issuesData?.reportIssues.success) {
				setIsReviewModalOpen(true);
				reset(getValues());
			}
		}
	});

	const onModalClose = () => {
		setIsReviewModalOpen(false);
		history.push(routes.specialProject(projectId));
	};

	const discardForm = () => {
		reset();

		history.push(routes.specialProject(projectId));
	};

	return (
		<>
			<Page
				fullWidth
				breadcrumbs={[
					{
						content: messages.specialProject,
						url: routes.specialProject(projectId),
					},
				]}
				title={messages.reportIssues}
			>
				<Form onSubmit={submitForm}>
					{error ? <Banner status="critical">{messages.saveError}</Banner> : undefined}
					<Card title={messages.reportIssues}>
						<Card.Section title="Parts"></Card.Section>
						{project?.componentProducts.map((product) => {
							return (
								<Card.Section key={product.id}>
									<FormLayout>
										<FormLayout.Group condensed>
											<Product product={product} />

											<FormTextField
												key={`${product.id}.missing`}
												name={`${product.id}.${PreKitExceptionReason.MissingProduct}`}
												type="number"
												label={messages.kitIssues.missingProduct}
												control={control}
												suffix={messages.units}
												min={0}
												autoComplete="off"
												inputMode="numeric"
												defaultValue={
													product.exceptions
														? determineDefaultValue(
																product.exceptions,
																PreKitExceptionReason.MissingProduct,
														  )
														: ''
												}
												placeholder="0"
												disabled={!isSaveableState}
											/>
											<FormTextField
												key={`${product.id}.damaged`}
												name={`${product.id}.${PreKitExceptionReason.DamagedProduct}`}
												type="number"
												label={messages.kitIssues.damagedProduct}
												control={control}
												suffix={messages.units}
												min={0}
												autoComplete="off"
												inputMode="numeric"
												defaultValue={
													product.exceptions
														? determineDefaultValue(
																product.exceptions,
																PreKitExceptionReason.DamagedProduct,
														  )
														: ''
												}
												placeholder="0"
												disabled={!isSaveableState}
											/>
											<FormTextField
												key={`${product.id}.other`}
												name={`${product.id}.${PreKitExceptionReason.Other}`}
												type="number"
												label={messages.kitIssues.otherIssue}
												control={control}
												suffix={messages.units}
												min={0}
												autoComplete="off"
												inputMode="numeric"
												defaultValue={
													product.exceptions
														? determineDefaultValue(product.exceptions, PreKitExceptionReason.Other)
														: ''
												}
												placeholder="0"
												disabled={!isSaveableState}
											/>
										</FormLayout.Group>
									</FormLayout>
								</Card.Section>
							);
						})}
					</Card>
				</Form>
				{isSaveEnabled && (
					<ContextualSaveBar
						fullWidth={false}
						alignContentFlush={false}
						message={messages.unsavedChanges}
						saveAction={{
							content: messages.report,
							onAction: submitForm,
							loading: false,
							disabled: false,
						}}
						discardAction={{
							content: messages.discard,
							onAction: discardForm,
						}}
					/>
				)}
			</Page>
			{project ? (
				<SpecialProjectReviewModal
					isOpen={isReviewModalOpen}
					onClose={onModalClose}
					project={project}
				/>
			) : undefined}
		</>
	);
}

function determineDefaultValue(
	exceptions: PreKitException[],
	reason: PreKitExceptionReason,
): string {
	const exception = exceptions.find((exception) => exception.reason === reason);

	if (!exception) {
		return '';
	}

	return exception.eachQuantity.toString();
}
