import { Link, FilterInterface, AppliedFilterInterface, TextStyle } from '@shopify/polaris';
import {
	PickingConnection,
	PickingJobOrderByFields,
	OrderByDirection,
	PickingJobStatus,
} from '@sixriver/fulfillment-api-schema';

import { OutboundJobsTableExceptions } from './OutboundJobsTableExceptions';
import {
	DataTable,
	Column,
	PaginationCursors,
	SetPaginationCursors,
	DataTableView,
} from 'components/DataTable';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { OutboundJobStatusBadge } from 'components/OutboundJobStatusBadge';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { WorkOrderType } from 'components/WorkOrderType/WorkOrderType';
import { calculateUnits } from 'helpers/order';
import { useConfig } from 'hooks/useConfig';
import { SetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { usePickStrategies, usePickStrategyName } from 'hooks/usePickStrategies';
import * as routes from 'routes';

interface Props {
	loading?: boolean;
	data?: PickingConnection;
	paginationCursors: PaginationCursors;
	setPaginationCursors: SetPaginationCursors;
	views: DataTableView[];
	query?: string;
	setFilters: SetFilters;
	selectedView?: string;
	sortValue?: string;
	filters: FilterInterface[];
	appliedFilters?: AppliedFilterInterface[];
	totalCount?: number;
}

export function OutboundJobsTable({
	loading = false,
	data,
	paginationCursors,
	setPaginationCursors,
	views,
	query,
	setFilters,
	selectedView,
	sortValue,
	filters,
	appliedFilters,
	totalCount,
}: Props) {
	const { messages, translate } = useLocalization();

	const { config } = useConfig();

	const availablePickStrategies = usePickStrategies(config?.jobAllocationMethods || []);

	const { pageInfo, edges = [] } = data || {};

	const columns: Column[] = [
		{
			type: 'text',
			heading: messages.licensePlate,
		},
		{
			type: 'text',
			heading: messages.createdAt,
		},
		{
			type: 'text',
			heading: messages.carrierCutoff,
		},
		{
			type: 'text',
			heading: messages.units,
		},
		{
			type: 'text',
			heading: messages.lines,
		},
		...(config?.inventoryEnabled
			? [
					{
						type: 'text' as const,
						heading: messages.workOrderType,
					},
			  ]
			: []),
		{
			type: 'text',
			heading: messages.progress,
		},
		{
			type: 'text',
			heading: messages.exceptions,
		},
		...(availablePickStrategies.length > 1
			? [
					{
						type: 'text' as const,
						heading: messages.pickStrategy,
					},
			  ]
			: []),
		{
			type: 'text',
			heading: messages.device,
		},
		...(config?.workAreasEnabled
			? [
					{
						type: 'text' as const,
						heading: messages.workArea,
					},
			  ]
			: []),
	];

	const getPickStrategyName = usePickStrategyName();

	const rows = (edges || []).map((pickingJob) => {
		const {
			id,
			timeline,
			lines,
			expectedShipDate,
			container,
			pickingStatus,
			type,
			mfp,
			packout,
			workAreas,
			destinationNode,
		} = pickingJob.node;

		const { picked: unitsPicked, total: unitsTotal } = calculateUnits(lines);
		const doneLines = (lines || []).reduce(
			(done, line) => (line.quantity === line.doneQuantity ? (done += 1) : done),
			0,
		);

		const deviceName = packout ? packout?.packStation?.name : !packout && mfp?.name;

		const row = [
			[
				<Link url={routes.outboundJob(id)} key={`${id}-lpn`} removeUnderline>
					{container?.barcode}
				</Link>,
				destinationNode ? (
					<div>
						<TextStyle variation="subdued">{`${messages.destination} ${destinationNode}`}</TextStyle>
					</div>
				) : null,
			],
			<div key={`${id}-date-created`}>
				<DateTime date={timeline?.createdAt} />
				<RelativeDateTime date={timeline?.createdAt} />
			</div>,
			<div key={`${id}-date-cutoff`}>
				<DateTime date={expectedShipDate} />
				<RelativeDateTime
					date={expectedShipDate}
					mode={
						[PickingJobStatus.Packed, PickingJobStatus.Picked, PickingJobStatus.Sorted].includes(
							pickingStatus,
						)
							? undefined
							: 'deadline'
					}
				/>
			</div>,
			<div key={`${id}-units`}>
				{translate(messages.pickedOfTotal, { picked: unitsPicked, total: unitsTotal })}
			</div>,
			<div key={`${id}-lines`}>
				{translate(messages.pickedOfTotal, { picked: doneLines, total: lines.length })}
			</div>,
			...(config?.inventoryEnabled
				? [<WorkOrderType key={`${id}-workOrderType`} order={pickingJob.node} />]
				: []),
			<OutboundJobStatusBadge key={`${id}-progress`} pickingStatus={pickingStatus} />,
			<OutboundJobsTableExceptions key={`${id}-exceptions`} pickingJob={pickingJob} />,
		];

		if (availablePickStrategies.length > 1) {
			row.push(type ? <div>{getPickStrategyName(type)}</div> : <NoData />);
		}

		row.push(<div>{deviceName || <NoData />}</div>);

		if (config?.workAreasEnabled) {
			row.push(
				workAreas.length ? <div>{workAreas.map((area) => area.name).join(', ')}</div> : <NoData />,
			);
		}

		return row;
	});

	return (
		<DataTable
			loading={loading}
			columns={columns}
			rows={rows}
			emptyStateHeading={messages.noOutboundJobsFound}
			pageInfo={pageInfo}
			paginationCursors={paginationCursors}
			setPaginationCursors={setPaginationCursors}
			views={views}
			query={query}
			queryPlaceholder={messages.filterOutboundJobs}
			setFilters={setFilters}
			selectedView={selectedView}
			sortChoices={[
				{
					label: messages.sortOptions.dateCreatedDesc,
					value: `${PickingJobOrderByFields.JobCreatedAt} ${OrderByDirection.Desc}`,
				},
				{
					label: messages.sortOptions.dateCreatedAsc,
					value: `${PickingJobOrderByFields.JobCreatedAt} ${OrderByDirection.Asc}`,
				},
				{
					label: messages.sortOptions.cutoffAsc,
					value: `${PickingJobOrderByFields.ExpectedShipDate} ${OrderByDirection.Asc}`,
				},
				{
					label: messages.sortOptions.cutoffDesc,
					value: `${PickingJobOrderByFields.ExpectedShipDate} ${OrderByDirection.Desc}`,
				},
			]}
			sortValue={sortValue}
			filters={filters}
			appliedFilters={appliedFilters}
			totalCount={totalCount}
		/>
	);
}
