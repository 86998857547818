import { User } from '@sixriver/fulfillment-api-schema';
import gql from 'graphql-tag';
import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'urql';

import { getCookieValue } from 'helpers/cookie';
import { useConfig } from 'hooks/useConfig';
import { useUserIQ } from 'hooks/useUserIQ/use-user-iq';

export enum UserRole {
	Admin = 'admin',
	BridgeAssociate = 'cfs_read_only',
	WarehouseManager = 'cfs_operator',
	EmployeeManager = 'user_admin',
	Integration = 'integration',
	ChuckOperator = 'chuck_operator',
	User = 'user',
}

type Login = (email: string, password: string, init?: RequestInit) => Promise<void>;
type Logout = (init?: RequestInit) => Promise<void>;
type isUserAllowed = (allowedRoles: string[]) => boolean;
type refreshToken = (init?: RequestInit | undefined) => Promise<void>;

export const AuthContext = createContext<
	| {
			isLoading: boolean;
			isAuthenticated: boolean;
			login: Login;
			logout: Logout;
			user?: User;
			isUserAllowed: isUserAllowed;
			refreshToken: refreshToken;
			authError: string | undefined;
	  }
	| undefined
>(undefined);

// TODO: cannot invoke useLocalization() from here, so there are a couple hard-coded English messages
export function AuthProvider({ children }: PropsWithChildren<{}>): JSX.Element {
	// State
	const [authenticated, setAuthenticated] = useState(
		getCookieValue('6RS-JWT-REFRESH') ? true : false,
	);
	const [authError, setAuthError] = useState<string | undefined>(undefined);

	// Custom hooks
	const { config } = useConfig(authenticated);
	const { startTracking } = useUserIQ(
		config?.siteId || '',
		config?.siteName,
		config?.userMetricsEnabled,
	);
	const location = useLocation();

	// Queries
	const [{ fetching, data }] = useQuery<{ me: User }>({
		query: gql`
			query {
				me {
					id
					name
					locale
					email
					roles
				}
			}
		`,
		pause: !authenticated,
	});
	const user = data?.me;

	// Methods
	const login: Login = async (email, password, init) => {
		const response = await fetch(`${process.env.REACT_APP_GATEKEEPER_API_URL}/auth/login/`, {
			...init,
			credentials: 'include',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email, password, skipRedirect: true }),
		});

		if (response.ok) {
			if (location.search !== '') {
				const redirectLoc = new URLSearchParams(location.search);
				const redirectTo = redirectLoc.get('original-uri') || window.location.origin;
				const matchOurURL = new RegExp('https://(.+).6river.(org|tech).*');
				if (matchOurURL.test(redirectTo)) {
					window.location.href = redirectTo;
				} else {
					window.location.href = window.location.origin;
				}
			} else {
				setAuthenticated(true);
			}
		} else {
			throw new Error('Unable to log in. Please try again.');
		}
	};

	const logout: Logout = async (init) => {
		await fetch(`${process.env.REACT_APP_GATEKEEPER_API_URL}/auth/logout/`, {
			...init,
			credentials: 'include',
			method: 'POST',
		});
		setAuthenticated(false);
	};

	const isUserAllowed: isUserAllowed = (allowedRoles) => {
		const userRoles = user?.roles || [];
		return userRoles.some((role) => allowedRoles.includes(role));
	};

	const refreshToken = useCallback(async (init?: RequestInit): Promise<void> => {
		if (!getCookieValue('6RS-JWT-REFRESH')) {
			setAuthenticated(false);
			return;
		}

		const refreshResponse = await fetch(
			`${process.env.REACT_APP_GATEKEEPER_API_URL}/auth/refresh/`,
			{
				...init,
				method: 'POST',
				credentials: 'include',
				headers: {
					Authorization: `Bearer ${getCookieValue('6RS-JWT-REFRESH')}`,
				},
			},
		);

		if (refreshResponse.ok) {
			setAuthenticated(true);
		} else {
			setAuthenticated(false);
		}
	}, []);

	const getIsAuthenticated = useCallback(
		async (signal: AbortSignal, done: () => void) => {
			try {
				await refreshToken({ signal });
			} catch {
				// tests will fail without this catch-block
			} finally {
				done();
			}
		},
		[refreshToken],
	);

	// Effects
	// reject access to non-bridge users
	useEffect(() => {
		if (
			user &&
			![
				UserRole.Admin,
				UserRole.BridgeAssociate,
				UserRole.EmployeeManager,
				UserRole.Integration,
				UserRole.WarehouseManager,
			].some((role) => user.roles?.includes(role))
		) {
			setAuthError('Please contact your administrator for assistance.');
			setAuthenticated(false);
		}
	}, [user]);

	// user tracking
	useEffect(() => {
		if (authenticated && user) {
			startTracking(user);
		}
	}, [authenticated, startTracking, user]);

	// Re-check if we are still authenticated on a defined interval
	useEffect(() => {
		const controller = new AbortController();
		const { signal } = controller;
		let timeoutId: NodeJS.Timeout | undefined;

		// Check if we are still authenticated
		// on a defined interval after
		// the previous call is done
		const createTimeout = () => {
			timeoutId = setTimeout(() => {
				getIsAuthenticated(signal, () => createTimeout());
			}, 1000 * 60 * 5); // 5-minute interval
		};

		// start auth check & timer
		getIsAuthenticated(signal, () => createTimeout());

		return () => {
			controller.abort();
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [getIsAuthenticated, refreshToken]);

	// Render
	return (
		<AuthContext.Provider
			value={{
				isLoading: fetching,
				isAuthenticated: authenticated,
				login,
				logout,
				user,
				isUserAllowed,
				refreshToken,
				authError,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}
