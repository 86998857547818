import { Spinner } from '@shopify/polaris';
import { useEffect } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import styles from './App.module.css';
import { Frame } from './Frame';
import { LoginRoute } from 'components/LoginRoute';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { Toast } from 'components/Toast';
import { useAuth } from 'hooks/useAuth';
import { cleanUpPrintLabelLocalStorage } from 'hooks/usePrintLabelButton';
import { ActionLog } from 'pages/ActionLog';
import { AllocationRules } from 'pages/AllocationRules';
import { Login, Logout } from 'pages/Auth';
import { ForgotPassword } from 'pages/Auth/ForgotPassword';
import { SetPassword } from 'pages/Auth/SetPassword';
import { BulkOrder } from 'pages/BulkOrder';
import { BulkOrders } from 'pages/BulkOrders';
import { Configuration } from 'pages/Configuration';
import { Containers } from 'pages/Containers';
import { AddContainer } from 'pages/Containers/ContainerAdd';
import { ContainerDetails } from 'pages/Containers/ContainerDetails';
import { EditContainer } from 'pages/Containers/ContainerEdit';
import { Count } from 'pages/Count';
import { Device, Devices } from 'pages/Devices';
import { Employees } from 'pages/Employees';
import { Employee } from 'pages/Employees/Employee';
import { AddEmployee } from 'pages/Employees/EmployeeAdd';
import { EmployeeEdit } from 'pages/Employees/EmployeeEdit';
import { Exception } from 'pages/Exception';
import { Exceptions } from 'pages/Exceptions';
import { FloorView } from 'pages/FloorView';
import { InboundReturn } from 'pages/InboundReturn';
import { InboundReturns } from 'pages/InboundReturns';
import { InboundShipment } from 'pages/InboundShipment';
import { InboundShipments } from 'pages/InboundShipments';
import { Location, Locations, AddStorageLocation, EditStorageLocation } from 'pages/Locations';
import { Move } from 'pages/Move';
import { Order } from 'pages/Order';
import { Orders } from 'pages/Orders';
import { OutboundJob } from 'pages/OutboundJob';
import { OutboundJobs } from 'pages/OutboundJobs';
import { ProductDetails } from 'pages/Product';
import { AddProduct } from 'pages/Product/AddProduct';
import { EditProduct } from 'pages/Product/EditProduct';
import { Products } from 'pages/Products';
import { ReplenishJob } from 'pages/ReplenishJob';
import { ReplenishJobs } from 'pages/ReplenishJobs';
import { SortWall } from 'pages/SortWall';
import { SortWalls } from 'pages/SortWalls';
import { ReportIssues } from 'pages/SpecialProjects/ReportIssues';
import { SpecialProject } from 'pages/SpecialProjects/SpecialProject';
import { SpecialProjects } from 'pages/SpecialProjects/SpecialProjects';
import { Support } from 'pages/Support';
import { Tableau } from 'pages/Tableau';
import { UserRole } from 'providers/AuthProvider';
import * as routes from 'routes';

declare global {
	const REACT_APP_VERSION: string;
}

export function App() {
	const { isLoading: isLoadingAuth } = useAuth();

	useEffect(() => {
		cleanUpPrintLabelLocalStorage();
	}, []);

	if (isLoadingAuth) {
		return (
			<div className={styles.spinner}>
				<Spinner />
			</div>
		);
	}

	const content = (
		<Switch>
			<ProtectedRoute exact path={routes.exceptions()}>
				<Exceptions />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.exception()}>
				<Exception />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.actionLog()}>
				<ActionLog />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.orders()}>
				<Orders />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.order()}>
				<Order />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.outboundJobs()}>
				<OutboundJobs />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.outboundJob()}>
				<OutboundJob />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.bulkOrders()}>
				<BulkOrders />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.bulkOrder()}>
				<BulkOrder />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.sortWalls()}>
				<SortWalls />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.sortWall()}>
				<SortWall />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.replenishJobs()}>
				<ReplenishJobs />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.replenishJob()}>
				<ReplenishJob />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.countJobs()}>
				<Count />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.moveJobs()}>
				<Move />
			</ProtectedRoute>
			<ProtectedRoute path={routes.addProduct()} exact>
				<AddProduct />
			</ProtectedRoute>
			<ProtectedRoute
				exact
				path={routes.editProduct()}
				allowedRoles={[UserRole.Admin, UserRole.WarehouseManager]}
			>
				<EditProduct />
			</ProtectedRoute>
			<ProtectedRoute
				exact
				path={routes.product()}
				allowedRoles={[UserRole.Admin, UserRole.WarehouseManager]}
			>
				<ProductDetails />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.products()}>
				<Products />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.employees()} allowedRoles={[UserRole.Admin]}>
				<Employees />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.editEmployee()} allowedRoles={[UserRole.Admin]}>
				<EmployeeEdit />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.addEmployee()} allowedRoles={[UserRole.Admin]}>
				<AddEmployee />
			</ProtectedRoute>

			<ProtectedRoute exact path={routes.employee()} allowedRoles={[UserRole.Admin]}>
				<Employee />
			</ProtectedRoute>

			<ProtectedRoute exact path={routes.device()}>
				<Device />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.devices()}>
				<Devices />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.floorView()}>
				<FloorView />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.allocationRules()}>
				<AllocationRules />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.support()}>
				<Support />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.config()}>
				<Configuration />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.analytics()}>
				<Tableau />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.inboundShipments()}>
				<InboundShipments />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.inboundShipment()}>
				<InboundShipment />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.inboundReturns()}>
				<InboundReturns />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.inboundReturn()}>
				<InboundReturn />
			</ProtectedRoute>
			<ProtectedRoute
				exact
				path={routes.addLocation()}
				allowedRoles={[UserRole.Admin, UserRole.WarehouseManager]}
			>
				<AddStorageLocation />
			</ProtectedRoute>
			<ProtectedRoute
				exact
				path={routes.editLocation()}
				allowedRoles={[UserRole.Admin, UserRole.WarehouseManager]}
			>
				<EditStorageLocation />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.location()}>
				<Location />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.locations()}>
				<Locations />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.specialProject()}>
				<SpecialProject />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.specialProjectReportIssues()}>
				<ReportIssues />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.specialProjects()}>
				<SpecialProjects />
			</ProtectedRoute>
			{/* Containers */}
			<ProtectedRoute exact path={routes.addContainer()}>
				<AddContainer />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.containers()}>
				<Containers />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.editContainer()}>
				<EditContainer />
			</ProtectedRoute>
			<ProtectedRoute exact path={routes.container()}>
				<ContainerDetails />
			</ProtectedRoute>

			{/* Action Log */}
			<ProtectedRoute exact path={routes.actionLog()}>
				<ActionLog />
			</ProtectedRoute>

			{/* Default route */}
			<Redirect from="/" to={routes.orders()} />
		</Switch>
	);

	return (
		<Switch>
			<LoginRoute exact path={routes.login()}>
				<Login />
			</LoginRoute>
			<Route exact path={routes.forgotPassword()}>
				<ForgotPassword />
			</Route>
			<Route exact path={routes.setPassword()}>
				<SetPassword />
			</Route>
			<ProtectedRoute exact path={routes.logout()}>
				<Logout />
			</ProtectedRoute>
			<ProtectedRoute path="/">
				<Frame>
					{content}
					<Toast />
				</Frame>
			</ProtectedRoute>
		</Switch>
	);
}
