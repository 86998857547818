import gql from 'graphql-tag';

export const INBOUND_RETURN_QUERY = gql`
	query inboundReturn($id: String!) {
		inboundReturn(id: $id) {
			id
			externalId
			returnStatus
			merchant
			returnType
			originalOrderId
			totalExpectedUnitQuantity
			totalExpectedLineQuantity
			totalActualUnitQuantity
			totalActualLineQuantity
			issues {
				id
				reason
			}
			returnLines {
				id
				expectedQuantity
				returnedQuantity
				sellableQuantity
				damagedQuantity
				product {
					id
					name
					image
					description
				}
			}
			shipment {
				estimatedArrivalDate
				actualArrivalDate
				shipmentTracking {
					carrierCode
					externalId
					trackingUrl
				}
			}
			comments
			externalComments
			uploads {
				id
			}
		}
	}
`;

export const CLOSE_INBOUND_RETURN_MUTATION = gql`
	mutation closeReturn($input: ReturnStatusInput!) {
		completeReturn(input: $input) {
			success
		}
	}
`;

export const UPDATE_RETURN_NOTE_MUTATION = gql`
	mutation updateReturnNote($input: ReturnNoteInput!) {
		updateReturnNote(input: $input) {
			success
		}
	}
`;

export const ADD_RETURN_UPLOADS_MUTATION = gql`
	mutation addReturnUploads($input: ReturnUploadInput!) {
		addReturnUploads(input: $input) {
			success
		}
	}
`;

export const REMOVE_RETURN_UPLOADS_MUTATION = gql`
	mutation deleteReturnUploads($input: ReturnUploadInput!) {
		deleteReturnUploads(input: $input) {
			success
		}
	}
`;
