import { Banner } from '@shopify/polaris';
import { OrderServicedBy } from '@sixriver/fulfillment-api-schema';

import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface Props {
	jobs: OrderServicedBy[];
}

export function ResolutionJobsBanner({ jobs }: Props): JSX.Element | null {
	const { messages, translate, formatNumber } = useLocalization();

	return jobs.length > 0 ? (
		<Banner
			status="warning"
			title={
				translate(messages.resolutionJobsCreated, {
					count: formatNumber(jobs.length),
				}) as string
			}
			action={
				jobs.length === 1
					? {
							url: routes.outboundJob(jobs[0].id),
							content: translate(messages.viewResolutionJob, {
								jobId: 'stagingIdentifier' in jobs[0] ? jobs[0].stagingIdentifier : jobs[0].id,
							}) as string,
					  }
					: undefined
			}
		/>
	) : null;
}
