import { TextStyle } from '@shopify/polaris';
import { ShippingNoticeIssueReason } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

export function InboundShipmentIssue({
	issue,
	quantity,
}: {
	issue?: ShippingNoticeIssueReason | null;
	quantity?: number | null;
}) {
	const { messages, translate } = useLocalization();

	return issue ? (
		<TextStyle variation="negative">
			{messages.asnIssueReason[issue]}
			{quantity ? ' (' + translate(messages.countUnits, { count: quantity }) + ')' : ''}
		</TextStyle>
	) : (
		<NoData />
	);
}
