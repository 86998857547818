import { User } from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';

import { formatIsoDate } from 'utils/date';

export function useUserIQ(siteId?: string, siteName?: string, enabled?: boolean) {
	const w: any = window;
	const [trackingUserId, setTrackingUserId] = useState<string | undefined>();

	const startTracking = (user: User): void => {
		if (enabled) {
			if (trackingUserId !== user.id) {
				w._uiq.push([
					'identify',
					user.id,
					{
						account_id: siteId,
						account_name: siteName,
						signup_date: user.createdAt ? formatIsoDate(user.createdAt) : undefined,
						roles: user.roles,
						bridge_version: process.env.REACT_APP_VERSION,
					},
				]);
				w._uiq.push(['startTracker']);
				setTrackingUserId(user.id);
			}
		}
	};

	return { startTracking };
}
