import { Card, DisplayText, Icon, Stack, TextStyle } from '@shopify/polaris';
import { ChevronRightMinor } from '@shopify/polaris-icons';
import React from 'react';

import styles from './Toteboard.module.css';

interface Props {
	items: Array<{
		label: string | React.ReactNode;
		primaryValue: React.ReactNode;
		secondaryValue?: JSX.Element;
		action?: () => void;
	}>;
	icon?: boolean;
}

export function Toteboard({ items, icon }: Props) {
	return (
		<div className={styles.toteboard}>
			<ul>
				{items.map((item) => {
					return (
						<li
							key={item.label as string}
							onClick={item.action}
							className={item.action ? styles.toteboardClickable : ''}
						>
							<Card>
								<Card.Section flush fullWidth>
									<Stack wrap={false} vertical={false} alignment="center">
										<Stack.Item fill>
											<Stack vertical spacing="tight" wrap>
												<TextStyle variation="subdued">{item.label}</TextStyle>
												<Stack alignment="baseline" wrap>
													<DisplayText size="medium">{item.primaryValue}</DisplayText>
													{item.secondaryValue && (
														<TextStyle variation="subdued">{item.secondaryValue}</TextStyle>
													)}
												</Stack>
											</Stack>
										</Stack.Item>
										<Stack.Item fill>
											<Stack distribution="trailing">
												{icon && <Icon source={ChevronRightMinor} color="base" />}
											</Stack>
										</Stack.Item>
									</Stack>
								</Card.Section>
							</Card>
						</li>
					);
				})}
			</ul>
		</div>
	);
}
