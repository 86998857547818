// Auth
export const login = () => '/login' as const;
export const logout = () => '/logout' as const;
export const forgotPassword = () => `/forgot-password` as const;
export const setPassword = () => `/password-set` as const;

// Config
export const config = () => '/config' as const;

// Exceptions
export const exceptions = () => '/exceptions' as const;
export const exception = (id = ':exceptionId') => `/exceptions/${id}` as const;

// Outbound
export const orders = () => '/orders' as const;
export const order = (id = ':orderId') => `/orders/${id}` as const;
export const actionLog = () => `/orders/action-log` as const;
export const outboundJobs = () => '/outbound-jobs' as const;
export const outboundJob = (id = ':outboundJobId') => `/outbound-jobs/${id}` as const;
export const bulkOrders = () => `/bulk-orders` as const;
export const bulkOrder = (id = ':bulkOrderId') => `/bulk-orders/${id}` as const;
export const sortWalls = () => `/sort-walls` as const;
export const sortWall = (id = ':sortWallId') => `/sort-walls/${id}` as const;

// Inbound
export const inboundReturns = () => `/inbound-returns` as const;
export const inboundReturn = (id = ':returnId') => `/inbound-returns/${id}` as const;
export const inboundShipments = () => '/inbound-shipments' as const;
export const inboundShipment = (id = ':inboundShipmentId') => `/inbound-shipments/${id}` as const;
export const replenishJobs = () => `/replenish-jobs` as const;
export const replenishJob = (id = ':replenishmentJobId') => `/replenish-jobs/${id}` as const;

// Devices
export const devices = () => '/devices' as const;
export const device = (id = ':deviceId') => `/devices/${id}` as const;

// Inventory
export const products = () => `/products` as const;
export const product = (id = ':productId') => `/products/${id}` as const;
export const addProduct = () => `/products/new` as const;
export const editProduct = (id = ':productId') => `/products/${id}/edit` as const;
export const locations = () => `/locations` as const;
export const location = (id = ':locationId') => `/locations/${id}` as const;
export const addLocation = () => `/locations/new` as const;
export const editLocation = (id = ':locationId') => `/locations/${id}/edit` as const;
export const containers = () => `/containers` as const;
export const container = (id = ':containerId') => `/containers/${id}` as const;
export const addContainer = () => `/containers/new` as const;
export const editContainer = (id = ':containerId') => `/containers/${id}/edit` as const;

// Employees
export const employees = () => `/employees` as const;
export const employee = (id = ':employeeId') => `/employees/${id}` as const;
export const addEmployee = () => `/employees/new` as const;
export const editEmployee = (id = ':employeeId') => `/employees/${id}/edit` as const;
export const inactiveEmployees = () => `/employees?selectedView=inactive` as const;

// Special projects
export const specialProjects = () => '/special-projects' as const;
export const specialProject = (id = ':projectId') => `/special-projects/${id}` as const;
export const specialProjectWorkLogged = (id = ':projectId') =>
	`/special-projects/${id}?tab=workLog` as const;
export const specialProjectReportIssues = (id = ':projectId') =>
	`/special-projects/${id}/reportIssues` as const;

// Tools, etc.
export const floorView = () => '/floor-view' as const;
export const allocationRules = () => '/allocation-rules' as const;
export const analytics = () => '/analytics' as const;
export const countJobs = () => `/count-jobs` as const;
export const moveJobs = () => `/move-jobs` as const;
export const support = () => '/support' as const;
