import { ShippingNoticeStatus } from '@sixriver/fulfillment-api-schema';

export enum InboundShipmentViews {
	All = 'All',
	Submitted = 'Submitted',
	InTransit = 'In transit',
	Arrived = 'Arrived',
	Receiving = 'Receiving',
	Closed = 'Closed',
	Cancelled = 'Cancelled',
}

export const InboundShipmentViewStateMap: {
	[key in InboundShipmentViews]: ShippingNoticeStatus[];
} = {
	[InboundShipmentViews.All]: [],
	[InboundShipmentViews.Submitted]: [ShippingNoticeStatus.Submitted],
	[InboundShipmentViews.InTransit]: [ShippingNoticeStatus.InTransit],
	[InboundShipmentViews.Arrived]: [ShippingNoticeStatus.Arrived],
	[InboundShipmentViews.Receiving]: [ShippingNoticeStatus.Receiving],
	[InboundShipmentViews.Closed]: [ShippingNoticeStatus.Completed],
	[InboundShipmentViews.Cancelled]: [ShippingNoticeStatus.Cancelled],
};
