import { ChuckProps, ChuckEffect, ChuckStatus } from '@sixriver/map-element';

import { Chuck } from './Chuck.type';
import { isCharging, isDwelling, isFaulty, isLowBattery, isPaused, isPicking } from './ChuckUtils';

/**
 * This function converts a Chuck into an object suitable for the <Map> component.
 */
export function asChuckIcon(chuck: Chuck): ChuckProps {
	let effect = undefined;
	let status = undefined;

	if (chuck.matched === true) {
		effect = 'emphasize';
	} else if (chuck.matched === false) {
		effect = 'subdue';
	} else {
		// no effect
	}

	if (isFaulty(chuck)) {
		status = 'faulty';
	} else if (isCharging(chuck)) {
		status = 'charging';
	} else if (isLowBattery(chuck)) {
		status = 'low-battery';
	} else if (isDwelling(chuck)) {
		status = 'dwelling';
	} else if (isPaused(chuck)) {
		status = 'paused';
	} else {
		status = 'okay';
	}

	return {
		id: chuck.id,
		name: chuck.name,
		picker: isPicking(chuck),
		x: chuck.telemetry?.x,
		y: chuck.telemetry?.y,
		orientation: chuck.telemetry?.orientation,
		// if the chuck moved more than 2 meters since the last update, do not animate
		animationDuration: (chuck.distance || 0) > 2 ? undefined : 1000,
		status: status as ChuckStatus,
		effect: effect as ChuckEffect,
	};
}
