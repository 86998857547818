import { Link, TextStyle } from '@shopify/polaris';
import { Device, DeviceType, Order, Packout, Sortation } from '@sixriver/fulfillment-api-schema';

import * as routes from 'routes';

interface RelatedOrdersProps {
	device: Device;
}

export default function RelatedOrders({ device }: RelatedOrdersProps): JSX.Element {
	const orders = getOrders(device);
	const projectionIds = orders.map((order: Order) => order.id);
	const params = new URLSearchParams();
	params.set('projectionIds', projectionIds.join(' '));
	const url = `${routes.orders()}?${params.toString()}`;
	const label = `${projectionIds.length}`;

	if (orders.length > 0) {
		return (
			<Link url={url} removeUnderline>
				{label}
			</Link>
		);
	}

	return <TextStyle variation="subdued">{label}</TextStyle>;
}

function getOrders(device: Device): Order[] {
	if (device.type === DeviceType.Chuck) {
		return device.orders;
	}

	if (device.type === DeviceType.PackoutTerminal) {
		return device.packouts.map((packout: Packout) => packout.orders).flat(1);
	}

	if (device.type === DeviceType.SortWall || device.type === DeviceType.SortationKiosk) {
		return device.sortations.map((sortation: Sortation) => sortation.customerOrders).flat(1);
	}

	return [];
}
