import { Page, Layout, Button } from '@shopify/polaris';
import { useDebouncedValue } from '@shopify/react-hooks';
import {
	Count,
	MutationResponse,
	QuerySortationsArgs,
	QuerySortationsCountArgs,
	SortationConnection,
} from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';
import { useMutation } from 'urql';

import {
	SORT_WALLS_QUERY,
	SORT_WALLS_COUNTS_QUERY,
	CREATE_WALL_MUTATION,
} from './SortWalls.graphql';
import { SortWallsTable } from './SortWallsTable';
import { SortWallsViews, SortWallsViewStateMap } from './SortWallsViews';
import { AutoRefresh } from 'components/AutoRefresh';
import { Error } from 'components/Error';
import { ErrorBanner } from 'components/ErrorBanner';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { getPageSize } from 'helpers/page-size';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { endOfDay, startOfDay } from 'helpers/time';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { useToast } from 'hooks/useToast';

export function SortWalls() {
	const { messages } = useLocalization();

	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const { view = SortWallsViews.All, query } = useFilters();

	const setFilters = useSetFilters();

	const searchTextQuery = useDebouncedValue(query) || '';

	const searchText = searchTextQuery.length >= MIN_QUERY_LENGTH ? searchTextQuery : undefined;

	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	const [{ fetching: countsFetching, data: countsData, error: countsError }] = usePollingQuery<
		{
			AllCount: Count;
			UnassignedCount: Count;
			PickingCount: Count;
			ReadyToSortCount: Count;
			SortingCount: Count;
			CompletedTodayCount: Count;
			ExceptionsCount: Count;
		},
		QuerySortationsCountArgs
	>({
		query: SORT_WALLS_COUNTS_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			searchText,
			statuses: SortWallsViewStateMap[view as SortWallsViews],
			completedAtFrom: startOfDay(),
			completedAtTo: endOfDay(),
		},
	});

	const [{ fetching: sortFetching, data: sortData, error: sortError }] = usePollingQuery<
		{ sortations: SortationConnection },
		QuerySortationsArgs
	>({
		query: SORT_WALLS_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			first: getPageSize(),
			after: paginationCursors[0],
			searchText,
			statuses: SortWallsViewStateMap[view as SortWallsViews],
			completedAtFrom: view === SortWallsViews.CompletedToday ? startOfDay() : undefined,
			completedAtTo: view === SortWallsViews.CompletedToday ? endOfDay() : undefined,
		},
	});

	const { showToast } = useToast();

	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);
	const [mutationErrorTitle, setMutationErrorTitle] = useState('');
	const [mutationErrorContent, setMutationErrorContent] = useState('');

	const [{ fetching: createWallLoading }, createWallMutation] = useMutation<{
		createSortWall: MutationResponse;
	}>(CREATE_WALL_MUTATION);

	const createWall = async () => {
		setIsBannerErrorVisible(false);
		setMutationErrorTitle('');
		setMutationErrorContent('');

		const { data, error } = await createWallMutation();

		if (data?.createSortWall?.success) {
			setIsBannerErrorVisible(false);
			showToast(messages.wallCreated);
			return;
		}

		setIsBannerErrorVisible(true);

		if (error?.message?.includes('No jobs to allocate to Mobile Sort Wall')) {
			setMutationErrorTitle(messages.sortWallNotCreated);
			setMutationErrorContent(messages.noJobsToAllocate);
		}
	};

	const {
		UnassignedCount = { count: 0 },
		PickingCount = { count: 0 },
		ReadyToSortCount = { count: 0 },
		SortingCount = { count: 0 },
		CompletedTodayCount = { count: 0 },
		ExceptionsCount = { count: 0 },
	} = countsData || {};

	const views = [
		{
			label: messages.all,
			id: SortWallsViews.All,
		},
		{
			label: messages.unassigned,
			metaLabel: UnassignedCount.count,
			id: SortWallsViews.Unassigned,
		},
		{
			label: messages.picking,
			metaLabel: PickingCount.count,
			id: SortWallsViews.Picking,
		},
		{
			label: messages.readyToSort,
			metaLabel: ReadyToSortCount.count,
			id: SortWallsViews.ReadyToSort,
		},
		{
			label: messages.sorting,
			metaLabel: SortingCount.count,
			id: SortWallsViews.Sorting,
		},
		{
			label: messages.completedToday,
			metaLabel: CompletedTodayCount.count,
			id: SortWallsViews.CompletedToday,
		},
		{
			label: messages.exceptions,
			metaLabel: ExceptionsCount.count,
			id: SortWallsViews.Exceptions,
		},
	];

	const error = sortError || countsError;

	const fetching = sortFetching || countsFetching;

	return error ? (
		<Error graphQLError={error} />
	) : (
		<Page
			fullWidth
			title={messages.sortWalls}
			primaryAction={
				<Button primary loading={createWallLoading} onClick={createWall}>
					{messages.createWall}
				</Button>
			}
		>
			<Layout>
				<Layout.Section>
					<ErrorBanner
						isVisible={isBannerErrorVisible}
						onDismiss={() => {
							setIsBannerErrorVisible(false);
						}}
						title={mutationErrorTitle}
						content={mutationErrorContent}
					/>
				</Layout.Section>
				<Layout.Section>
					<AutoRefresh
						pollingEnabled={pollingEnabled}
						togglePolling={togglePolling}
						discriminatorData={sortData}
					/>
				</Layout.Section>
				<Layout.Section>
					<SortWallsTable
						data={sortData}
						loading={fetching}
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
						views={views}
						selectedView={view}
						query={query}
						setFilters={setFilters}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
