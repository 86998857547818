import { Card } from '@shopify/polaris';
import { Picking, PickingJobStatus } from '@sixriver/fulfillment-api-schema';

import { CardDetails } from 'components/CardDetails';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { WorkOrderType } from 'components/WorkOrderType/WorkOrderType';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	loading?: boolean;
	job?: Picking;
}

export function TransferDetails({ loading, job }: Props) {
	const { messages } = useLocalization();

	return (
		<Card title={messages.transferDetails}>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.workOrderType,
							content: <WorkOrderType order={job} />,
						},
						{
							label: messages.expectedShipDate,
							content: (
								<>
									<DateTime date={job?.expectedShipDate} />
									<RelativeDateTime
										date={job?.expectedShipDate}
										mode={
											[
												PickingJobStatus.Packed,
												PickingJobStatus.Picked,
												PickingJobStatus.Sorted,
											].includes(job?.pickingStatus as PickingJobStatus)
												? undefined
												: 'deadline'
										}
									/>
								</>
							),
						},
					]}
					secondary={[
						{
							label: messages.destination,
							content: job?.destinationNode || <NoData />,
						},
					]}
				/>
			</Card.Section>
		</Card>
	);
}
