import gql from 'graphql-tag';

export const RETURNS_SUMMARIES_QUERY = gql`
	query ReturnsSumamries(
		$etaFrom: DateTime
		$etaTo: DateTime
		$limit: Int
		$merchants: [String!]
		$orderBy: ReturnsSummaryOrderByField
		$orderDirection: OrderByDirection
		$returnType: String
		$searchText: String
		$statuses: [ReturnStatus!]!
	) {
		returnsSummaries(
			etaFrom: $etaFrom
			etaTo: $etaTo
			limit: $limit
			merchants: $merchants
			orderBy: $orderBy
			orderDirection: $orderDirection
			returnType: $returnType
			searchText: $searchText
			statuses: $statuses
		) {
			results {
				id
				externalId
				jobExternalId
				merchant
				trackingNumber
				quantityExpected
				quantityReceived
				returnStatus
				eta
				returnType
			}
		}
	}
`;

export const RETURNS_MERCHANTS_QUERY = gql`
	query returnsMerchants {
		returnsMerchants
	}
`;

export const COUNTS_QUERY = gql`
	query returnsCount(
		$merchants: [String!]
		$createdAtFrom: DateTime
		$createdAtTo: DateTime
		$etaFrom: DateTime
		$etaTo: DateTime
		$searchText: String
		$returnType: String
	) {
		InTransitCount: returnsCount(
			merchants: $merchants
			createdAtFrom: $createdAtFrom
			createdAtTo: $createdAtTo
			etaFrom: $etaFrom
			etaTo: $etaTo
			searchText: $searchText
			returnType: $returnType
			statuses: [InTransit]
		) {
			count
		}
		InProgressCount: returnsCount(
			merchants: $merchants
			createdAtFrom: $createdAtFrom
			createdAtTo: $createdAtTo
			etaFrom: $etaFrom
			etaTo: $etaTo
			searchText: $searchText
			returnType: $returnType
			statuses: [Returning]
		) {
			count
		}
		ClosedCount: returnsCount(
			merchants: $merchants
			createdAtFrom: $createdAtFrom
			createdAtTo: $createdAtTo
			etaFrom: $etaFrom
			etaTo: $etaTo
			searchText: $searchText
			returnType: $returnType
			statuses: [Completed]
		) {
			count
		}
		CancelledCount: returnsCount(
			merchants: $merchants
			createdAtFrom: $createdAtFrom
			createdAtTo: $createdAtTo
			etaFrom: $etaFrom
			etaTo: $etaTo
			searchText: $searchText
			returnType: $returnType
			statuses: [Cancelled]
		) {
			count
		}
		CancelRequestedCount: returnsCount(
			merchants: $merchants
			createdAtFrom: $createdAtFrom
			createdAtTo: $createdAtTo
			etaFrom: $etaFrom
			etaTo: $etaTo
			searchText: $searchText
			returnType: $returnType
			statuses: [CancelRequested]
		) {
			count
		}
	}
`;
