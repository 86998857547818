import { Button, ChoiceList, Popover } from '@shopify/polaris';
import { useCallback, useMemo, useState } from 'react';

import { getDateDiffInDays, getMidnight, startOfDay } from 'helpers/time';
import { useLocalization } from 'hooks/useLocalization';

export enum OptionValues {
	'today' = 'today',
	'yesterday' = 'yesterday',
	'last3Days' = 'last3Days',
	'last7Days' = 'last7Days',
	'last30Days' = 'last30Days',
	'last60Days' = 'last60Days',
	'last90Days' = 'last90Days',
	'last180Days' = 'last180Days',
	'allTime' = 'allTime',
	'custom' = 'custom',
}

export interface OptionLabelValue {
	label: string;
	value: OptionValues;
}

export interface DateRange {
	start: Date | null;
	end: Date | null;
}

export const ALL_TIME = 365 * 20; // Using approimately 20 years ago as all time to differenciate between all time and undefined

interface Props {
	options: OptionValues[];
	selectedOption: OptionValues | Date;
	title?: String;
	onChange: ({ start, end }: DateRange, optionLabelValue: OptionLabelValue) => void;
}

export function DateRangeSelect({ options, selectedOption, title = '', onChange }: Props) {
	const { messages } = useLocalization();

	const [active, setActive] = useState(false);
	const toggleGlobalFilter = useCallback(() => setActive((active) => !active), []);

	const selectedDateRangeOption: OptionValues = useMemo(() => {
		if (selectedOption instanceof Date) {
			return getOptionFromDate(selectedOption);
		}
		return selectedOption;
	}, [selectedOption]);

	const activator = (
		<Button onClick={toggleGlobalFilter} disclosure>
			{messages.dateRangeOptions[selectedDateRangeOption]}
		</Button>
	);

	const optionList = options.map((option) => {
		return {
			label: messages.dateRangeOptions[option],
			value: option,
		};
	});

	function handleDateRangeSelectionChanged(values: OptionValues[]) {
		const selectedOptionValue = values.shift();
		if (selectedOptionValue) {
			const { startDate, endDate } = setDates(selectedOptionValue);

			toggleGlobalFilter();
			onChange(
				{ start: startDate, end: endDate },
				{ label: messages.dateRangeOptions[selectedOptionValue], value: selectedOptionValue },
			);
		}
	}

	return (
		<Popover active={active} activator={activator} fluidContent onClose={toggleGlobalFilter}>
			<div style={{ padding: '14px 18px' }}>
				<ChoiceList
					title={title}
					titleHidden={title === ''}
					choices={optionList}
					selected={[selectedDateRangeOption]}
					onChange={handleDateRangeSelectionChanged}
				/>
			</div>
		</Popover>
	);
}

function setDates(selection: OptionValues): { startDate: Date | null; endDate: Date | null } {
	const today = new Date();
	const startDate = new Date();
	const endDate = new Date();

	switch (selection) {
		case OptionValues.today:
			return { startDate, endDate };
		case OptionValues.yesterday:
			startDate.setDate(today.getDate() - 1);
			endDate.setDate(today.getDate() - 1);
			return { startDate, endDate };
		case OptionValues.last3Days:
			startDate.setDate(today.getDate() - 3);
			return { startDate, endDate };
		case OptionValues.last7Days:
			startDate.setDate(today.getDate() - 7);
			return { startDate, endDate };
		case OptionValues.last30Days:
			startDate.setDate(today.getDate() - 30);
			return { startDate, endDate };
		case OptionValues.last60Days:
			startDate.setDate(today.getDate() - 60);
			return { startDate, endDate };
		case OptionValues.last90Days:
			startDate.setDate(today.getDate() - 90);
			return { startDate, endDate };
		case OptionValues.last180Days:
			startDate.setDate(today.getDate() - 180);
			return { startDate, endDate };
		case OptionValues.allTime:
			startDate.setDate(today.getDate() - ALL_TIME);
			return { startDate, endDate };
		case OptionValues.custom:
		default:
			return { startDate: null, endDate: null };
	}
}

function getOptionFromDate(date: Date): OptionValues {
	// const today = startOfDay(new Date()).getDate();
	const today = getMidnight();
	const selectedStartDate = startOfDay(date);
	const dateDiffInDays = getDateDiffInDays(today, selectedStartDate);

	if (today.getTime() === selectedStartDate.getTime()) {
		return OptionValues.today;
	}

	if (dateDiffInDays === 1) {
		return OptionValues.yesterday;
	}
	if (dateDiffInDays === 3) {
		return OptionValues.last3Days;
	}

	if (dateDiffInDays === 7) {
		return OptionValues.last7Days;
	}

	if (dateDiffInDays === 30) {
		return OptionValues.last30Days;
	}

	if (dateDiffInDays === 60) {
		return OptionValues.last60Days;
	}

	if (dateDiffInDays === 90) {
		return OptionValues.last90Days;
	}

	if (dateDiffInDays === 180) {
		return OptionValues.last180Days;
	}

	if (dateDiffInDays === ALL_TIME) {
		return OptionValues.allTime;
	}

	return OptionValues.custom;
}
