import { Badge } from '@shopify/polaris';
import { OrderStatus } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

export function OrderStatusBadge({
	orderStatus,
	isEmptyIfNoData,
}: {
	orderStatus?: OrderStatus | null;
	isEmptyIfNoData?: boolean;
}) {
	const { messages } = useLocalization();

	return orderStatus === OrderStatus.Canceled ? (
		<Badge>{messages.canceled}</Badge>
	) : !orderStatus || orderStatus === OrderStatus.Unassigned ? (
		<Badge status="warning">{messages.unassigned}</Badge>
	) : orderStatus === OrderStatus.AssignedToBatch ? (
		<Badge status="warning">{messages.assignedToBatch}</Badge>
	) : orderStatus === OrderStatus.AssignedToWall ? (
		<Badge status="warning">{messages.assignedToWall}</Badge>
	) : orderStatus === OrderStatus.InProgress ? (
		<Badge status="info">{messages.inProgress}</Badge>
	) : orderStatus === OrderStatus.Expired ? (
		<Badge status="info">{messages.expired}</Badge>
	) : orderStatus === OrderStatus.Completed ? (
		<Badge>{messages.completed}</Badge>
	) : orderStatus === OrderStatus.AwaitingInventory ? (
		<Badge status="warning">{messages.awaitingInventory}</Badge>
	) : orderStatus === OrderStatus.Interrupted ? (
		<Badge status="warning">{messages.interrupted}</Badge>
	) : orderStatus === OrderStatus.Exception ? (
		<Badge status="critical">{messages.exception}</Badge>
	) : !isEmptyIfNoData ? (
		<NoData />
	) : null;
}
