import styles from './FloorView.module.css';
import { useLocalization } from 'hooks/useLocalization';

export type SortMethod = 'idleTime' | 'batteryLevel';

interface Props {
	sortMethod: SortMethod;
	onSort(sortMethod: SortMethod): void;
}

export function FullScreenOptions({ sortMethod, onSort }: Props) {
	const { messages } = useLocalization();

	return (
		<div className={styles.fsOptions}>
			<select value={sortMethod} onChange={(evt) => onSort(evt.target.value as SortMethod)}>
				<option value="idleTime">{messages.sortByIdleTime}</option>
				<option value="batteryLevel">{messages.sortByBatteryLevel}</option>
			</select>
			<button onClick={() => document.exitFullscreen()}>{messages.close}</button>
		</div>
	);
}
