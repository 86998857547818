import {
	Card,
	ContextualSaveBar,
	Form,
	FormLayout,
	TextField,
	Layout,
	Stack,
} from '@shopify/polaris';
import { ProductInput } from '@sixriver/fulfillment-api-schema';

import { FormFeedback } from 'components/FormFeedback';
import { ImageUpload } from 'components/ImageUpload/ImageUpload';
import { useConfig } from 'hooks/useConfig';
import { FormProps, useForm } from 'hooks/useForm';
import { useLocalization } from 'hooks/useLocalization';
import { toMillimeters, toCentimeters } from 'utils/number';

export function ProductForm({ mode, data, error, onSubmit }: FormProps<ProductInput>): JSX.Element {
	const { messages } = useLocalization();
	const { config } = useConfig();
	const { editForm, discardForm, input, dirty, feedback, validations } = useForm<ProductInput>(
		data,
		error,
	);

	return (
		<Form onSubmit={() => onSubmit(input)} noValidate>
			<FormFeedback feedback={feedback} />
			<Layout>
				<Layout.AnnotatedSection title={messages.productDetails}>
					<Card sectioned>
						<Stack vertical>
							<TextField
								autoComplete="off"
								name="customerIdentifier"
								label={messages.item}
								value={input.customerIdentifier}
								requiredIndicator
								disabled={mode === 'edit'}
								maxLength={1024}
								onChange={(customerIdentifier) => editForm({ customerIdentifier })}
								error={validations.customerIdentifier}
							/>
							<TextField
								autoComplete="off"
								name="name"
								label={config?.inventoryEnabled ? messages.sku : messages.name}
								value={input.name}
								requiredIndicator
								disabled={mode === 'edit'}
								maxLength={1024}
								onChange={(name) => editForm({ name })}
								error={validations.name}
							/>
							<TextField
								autoComplete="off"
								name="description"
								label={messages.description}
								value={input.description}
								requiredIndicator
								disabled={mode === 'edit'}
								maxLength={1024}
								onChange={(description) => editForm({ description })}
								error={validations.description}
							/>
							<ImageUpload
								imgUrl={input.image || undefined}
								resourceName={messages.productImage}
								onImgUrl={(image) => editForm({ image })}
							/>
						</Stack>
					</Card>
				</Layout.AnnotatedSection>
				<Layout.AnnotatedSection title={messages.productAttributes}>
					<Card sectioned>
						<Stack vertical>
							<FormLayout>
								<FormLayout.Group condensed>
									<TextField
										autoComplete="off"
										name="length"
										label={messages.length}
										value={toCentimeters(input.length || 0).toString()}
										requiredIndicator
										type="number"
										inputMode="numeric"
										suffix="cm"
										min="1"
										onChange={(length) => editForm({ length: toMillimeters(length) })}
										error={validations.length}
									/>
									<TextField
										autoComplete="off"
										name="width"
										label={messages.width}
										value={toCentimeters(input.width || 0).toString()}
										requiredIndicator
										type="number"
										inputMode="numeric"
										suffix="cm"
										min="1"
										onChange={(width) => editForm({ width: toMillimeters(width) })}
										error={validations.width}
									/>
									<TextField
										autoComplete="off"
										name="height"
										label={messages.height}
										value={toCentimeters(input.height || 0).toString()}
										requiredIndicator
										type="number"
										inputMode="numeric"
										suffix="cm"
										min="1"
										onChange={(height) => editForm({ height: toMillimeters(height) })}
										error={validations.height}
									/>
								</FormLayout.Group>
							</FormLayout>
							<TextField
								autoComplete="off"
								name="weight"
								label={messages.weight}
								value={input.weight.toString()}
								requiredIndicator
								type="number"
								inputMode="numeric"
								suffix={'g'}
								min="1"
								onChange={(weight) => editForm({ weight: parseInt(weight) || 0 })}
								error={validations.weight}
							/>
						</Stack>
					</Card>
				</Layout.AnnotatedSection>
			</Layout>
			{dirty ? (
				<ContextualSaveBar
					fullWidth={false}
					alignContentFlush={false}
					message={messages.unsavedChanges}
					saveAction={{
						content: messages.save,
						onAction: () => onSubmit(input),
					}}
					discardAction={{
						content: messages.discard,
						onAction: discardForm,
					}}
				/>
			) : null}
		</Form>
	);
}
