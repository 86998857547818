import { Card } from '@shopify/polaris';
import { Order, OrderStatus } from '@sixriver/fulfillment-api-schema';

import { CardDetails } from 'components/CardDetails';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { WorkOrderType } from 'components/WorkOrderType/WorkOrderType';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	loading?: boolean;
	order?: Order;
	isPackoutEnabled?: boolean;
	isInventoryEnabled?: boolean;
	isWorkAreaEnabled?: boolean;
}

export function Details({
	loading = false,
	order,
	isPackoutEnabled,
	isInventoryEnabled,
	isWorkAreaEnabled,
}: Props) {
	const { messages } = useLocalization();

	const primary = [];

	primary.push(
		...(isInventoryEnabled && order?.customers.length
			? [
					{
						label: messages.store,
						content: <>{order?.customers.map((customer) => customer.name).join(', ')}</>,
					},
			  ]
			: [
					{
						label: messages.store,
						content: <NoData />,
					},
			  ]),
		...(isInventoryEnabled
			? [
					{
						label: messages.workOrderType,
						content: <WorkOrderType order={order} />,
					},
					{
						label: messages.destination,
						content: order?.destinationNode || <NoData />,
					},
			  ]
			: []),
		{
			label: messages.received,
			content: (
				<>
					<DateTime date={order?.timeline?.createdAt} />
					<RelativeDateTime date={order?.timeline?.createdAt} />
				</>
			),
		},
		{
			label: messages.carrierCutoff,
			content: (
				<>
					<DateTime date={order?.expectedShipDate} />
					<RelativeDateTime
						date={order?.expectedShipDate}
						mode={order?.status === OrderStatus.Completed ? undefined : 'deadline'}
					/>
				</>
			),
		},
	);

	if (isWorkAreaEnabled) {
		primary.push({
			label: messages.workArea,
			content: <>{order?.workAreas?.map((workArea) => workArea.name).join(', ')}</>,
		});
	}

	const secondary = [
		{
			label: messages.updatedAt,
			content: <DateTime date={order?.timeline?.updatedAt} />,
		},
		{
			label: messages.pickingCompletedAt,
			content: <DateTime date={order?.timeline?.pickedAt} />,
		},
	];

	if (isPackoutEnabled) {
		secondary.push({
			label: messages.packoutCompletedAt,
			content: <DateTime date={order?.packoutCompletedAt} />,
		});
	}

	return (
		<Card title={messages.details} sectioned>
			<CardDetails loading={loading} primary={primary} secondary={secondary} />
		</Card>
	);
}
