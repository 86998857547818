import { Stack } from '@shopify/polaris';

import { useLocalization } from 'hooks/useLocalization';

interface Props {
	collected: number;
	packed: number;
}

export function OrdersCollected({ collected, packed }: Props) {
	const { messages, translate } = useLocalization();

	return (
		<Stack spacing="loose" wrap={false}>
			<div>{translate(messages.countCollected, { count: <b>{collected}</b> }, collected)}</div>
			<div>{translate(messages.countPacked, { count: <b>{packed}</b> }, packed)}</div>
		</Stack>
	);
}
