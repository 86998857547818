import {
	FilterInterface,
	AppliedFilterInterface,
	ChoiceList,
	Link,
	Icon,
	Stack,
	Tooltip,
} from '@shopify/polaris';
import { DiamondAlertMajor } from '@shopify/polaris-icons';
import {
	OrderByDirection,
	StorageLocationSummaryPage,
	StorageLocationOrderByFields,
	StorageLocationTypeCount,
	StorageLocationType,
	StorageLocationSummary,
	WorkArea,
} from '@sixriver/fulfillment-api-schema';

import { useLocationsTableFilters } from './use-locations-table-filters';
import { Column, DataTable, PaginationCursors, SetPaginationCursors } from 'components/DataTable';
import { LocationPeek } from 'components/LocationPeek';
import { NoData } from 'components/NoData';
import { boolStringToTriStateBool } from 'helpers/boolean';
import { getSummaryFullness, isPickableType } from 'helpers/location';
import { useConfig } from 'hooks/useConfig';
import { useFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { useLocationTabs } from 'hooks/useLocationTabs';
import * as routes from 'routes';

interface Props {
	data?: StorageLocationSummaryPage;
	counts?: StorageLocationTypeCount[];
	loading?: boolean;
	paginationCursors: PaginationCursors;
	setPaginationCursors: SetPaginationCursors;
	isInventoryEnabled: boolean;
	workAreas: WorkArea[];
}

function conditionalArrayItem<T>(include: boolean, item: T) {
	return include ? [item] : [];
}

export function LocationsTable({
	isInventoryEnabled,
	data,
	loading,
	counts = [],
	paginationCursors,
	setPaginationCursors,
	workAreas,
}: Props) {
	const { messages, formatNumber, formatPercent } = useLocalization();

	// Tabs
	const tabs = useLocationTabs(counts);

	// Filters
	const { filters, keys, setLocationTableFilter, setFilters } = useLocationsTableFilters();

	const { config } = useConfig();

	const { workArea } = useFilters(['workArea']);

	const selectedWorkAreas = workArea ? workArea.split(' ') : undefined;

	const isSlottedFilterParam = boolStringToTriStateBool(filters.isSlotted);

	const filtersMarkup: FilterInterface[] = [
		{
			key: keys.IS_CONFLICTED_KEY,
			label: messages.conflicts,
			filter: (
				<ChoiceList
					title=""
					titleHidden
					selected={[filters.isConflicted || 'none']}
					choices={[
						{
							label: messages.suspectedDiscrepancies,
							value: 'true',
						},
					]}
					onChange={(selected) => setLocationTableFilter('isConflicted', selected[0])}
				/>
			),
		},

		{
			key: keys.FULLNESS_KEY,
			label: messages.fullness,
			filter: (
				<ChoiceList
					title=""
					titleHidden
					selected={[filters.fullness || 'none']}
					choices={[
						{ label: messages.fullnessTypes.empty, value: '0' },
						{
							label: messages.fullnessTypes.lessThanTenPercentFull,
							value: '0.10',
						},
						{
							label: messages.fullnessTypes.lessThanFiftyPercentFull,
							value: '0.50',
						},
						{
							label: messages.fullnessTypes.full,
							value: '1',
						},
					]}
					onChange={(selected) => setLocationTableFilter('fullness', selected[0])}
				/>
			),
			shortcut: true,
		},
		// Status
		{
			key: keys.IS_SLOTTED_KEY,
			label: messages.status,
			filter: (
				<ChoiceList
					title=""
					titleHidden
					selected={[filters.isSlotted || 'none']}
					choices={[
						{
							label: messages.available,
							value: false.toString(),
						},
						{
							label: messages.inUse,
							value: true.toString(),
						},
					]}
					onChange={(selected) => setLocationTableFilter('isSlotted', selected[0])}
				/>
			),
		},
	];

	const appliedFilters: AppliedFilterInterface[] = [
		...conditionalArrayItem(filters.isConflicted !== undefined, {
			key: keys.IS_CONFLICTED_KEY,
			label: messages.suspectedDiscrepancies,
			onRemove: () => setLocationTableFilter(keys.IS_CONFLICTED_KEY, ''),
		}),
		...conditionalArrayItem(filters.fullness !== undefined, {
			key: keys.FULLNESS_KEY,
			label:
				filters.fullness === '1'
					? messages.fullnessTypes.full
					: filters.fullness === '0'
					? messages.fullnessTypes.empty
					: filters.fullness === '0.10'
					? messages.fullnessTypes.lessThanTenPercentFull
					: messages.fullnessTypes.lessThanFiftyPercentFull,
			onRemove: () => setLocationTableFilter(keys.FULLNESS_KEY, ''),
		}),
		...conditionalArrayItem(filters.isSlotted !== undefined, {
			key: keys.IS_SLOTTED_KEY,
			label:
				isSlottedFilterParam === true
					? messages.inUse
					: isSlottedFilterParam === false
					? messages.available
					: '',
			onRemove: () => setLocationTableFilter(keys.IS_SLOTTED_KEY, ''),
		}),
		...(selectedWorkAreas && selectedWorkAreas.length
			? [
					{
						key: 'workArea',
						label: workAreas
							.filter((area) => selectedWorkAreas.includes(area.id))
							.map((area, i) => area.name || `Work area ${i + 1}`)
							.join(', '),
						onRemove: () => {
							setFilters([{ key: 'workArea', value: '' }]);
						},
					},
			  ]
			: []),
	];

	// Sorting
	const sortChoices = [
		{
			label: messages.sortOptions.addressAsc,
			value: `${StorageLocationOrderByFields.Address} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.addressDesc,
			value: `${StorageLocationOrderByFields.Address} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.quantityAsc,
			value: `${StorageLocationOrderByFields.LiveQuantity} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.quantityDesc,
			value: `${StorageLocationOrderByFields.LiveQuantity} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.fullnessAsc,
			value: `${StorageLocationOrderByFields.FillFraction} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.fullnessDesc,
			value: `${StorageLocationOrderByFields.FillFraction} ${OrderByDirection.Desc}`,
		},
	];

	// Data
	const { results, cursor } = data || {};

	// Render
	const workAreaColumn: Column = {
		heading: messages.workArea,
		type: 'text',
		sortable: false,
	};

	const fmsColumns: Column[] = [
		{
			heading: messages.address,
			type: 'text',
			sortable: false,
		},
		{
			heading: messages.locationType,
			type: 'text',
			sortable: false,
		},
		...(config?.workAreasEnabled ? [workAreaColumn] : []),
		{
			heading: messages.sku,
			type: 'text',
			sortable: false,
		},
		{
			heading: messages.description,
			type: 'text',
			sortable: false,
		},
		{
			heading: messages.quantity,
			type: 'text',
			sortable: false,
		},
		{
			heading: messages.reserved,
			type: 'text',
			sortable: false,
		},
		{
			heading: messages.available,
			type: 'text',
			sortable: false,
		},
		{
			heading: messages.fullness,
			type: 'text',
			sortable: false,
		},
	];

	const fasColumns: Column[] = [
		{
			heading: messages.address,
			type: 'text',
			sortable: false,
		},
		...(config?.workAreasEnabled ? [workAreaColumn] : []),
		{
			heading: messages.capacityType,
			type: 'text',
			sortable: false,
		},
		{
			heading: messages.locationType,
			type: 'text',
			sortable: false,
		},
	];

	const fmsRows = (results || []).map((summary: StorageLocationSummary) => {
		const conflictReasons = summary.inventories.map((inv) => inv.conflictReasons).flat();
		const conflictReason =
			conflictReasons.length === 0
				? undefined
				: conflictReasons.length === 1
				? messages.discrepancyTypes[conflictReasons?.[0]]
				: messages.discrepancyTypes.Other;
		const isPickable = isPickableType(summary.type as StorageLocationType);
		const isSlotted = summary.numAssetTypes > 0;
		const liveCount = summary.liveQuantity;
		const reservedCount = Math.abs(summary.totalPlannedRemoveQuantity);
		const availableCount = liveCount - reservedCount;
		const fullness = getSummaryFullness(summary);

		return [
			// location
			<Link key={summary.id} url={routes.location(summary.id)} removeUnderline>
				{summary.address}
			</Link>,
			// Type
			summary.type ? messages.locationTypes[summary.type] : <NoData />,
			// Work Area
			...(config?.workAreasEnabled
				? [summary.workArea ? <div>{summary.workArea?.name}</div> : <NoData />]
				: []),
			// SKU
			summary.numAssetTypes > 1 ? (
				<LocationPeek summary={summary} />
			) : (
				summary.inventories[0]?.assetTypeExternalId || <NoData />
			),
			// Description
			summary.numAssetTypes > 1
				? messages.multiple
				: summary.inventories[0]?.assetTypeDescription || <NoData />,
			// Quantity
			<Stack key="qty" vertical={false}>
				<Stack.Item>{isSlotted ? formatNumber(liveCount) : <NoData />}</Stack.Item>
				{conflictReason ? (
					<Stack.Item>
						<Tooltip content={conflictReason}>
							<Icon source={DiamondAlertMajor} color="critical" />
						</Tooltip>
					</Stack.Item>
				) : null}
			</Stack>,
			// Reserved
			isSlotted && isPickable ? formatNumber(reservedCount) : <NoData />,
			// Available
			isSlotted && isPickable ? formatNumber(availableCount) : <NoData />,
			// Fullness
			fullness === null ? <NoData /> : formatPercent(fullness),
		];
	});

	const fasRows = (results || []).map((summary: StorageLocationSummary) => {
		return [
			// Address
			<Link key={summary.id} url={routes.location(summary.id)}>
				{summary.address}
			</Link>,
			// Work Area
			...(config?.workAreasEnabled
				? [summary.workArea ? <div>{summary.workArea?.name}</div> : <NoData />]
				: []),
			// Capacity Type
			summary.containerTypeName || <NoData />,
			// Location Type
			summary.type ? messages.locationTypes[summary.type] : <NoData />,
		];
	});

	const columns = isInventoryEnabled ? fmsColumns : fasColumns;
	const rows = isInventoryEnabled ? fmsRows : fasRows;

	return (
		<DataTable
			sortChoices={sortChoices}
			views={tabs}
			selectedView={filters.view || 'All'}
			viewKey={keys.view}
			columns={columns}
			filters={isInventoryEnabled ? filtersMarkup : []}
			appliedFilters={appliedFilters}
			rows={rows}
			loading={loading}
			pageInfo={{ endCursor: cursor }}
			sortValue={filters.sort}
			setFilters={setFilters}
			query={filters.searchText}
			queryKey={keys.SEARCH_TEXT_KEY}
			queryPlaceholder={messages.filterLocations}
			paginationCursors={paginationCursors}
			setPaginationCursors={setPaginationCursors}
		/>
	);
}
