import { Page, Layout, Card } from '@shopify/polaris';

import { AutoRefresh } from 'components/AutoRefresh';
import { Tab, ViewTabs } from 'components/ViewTabs';
import { usePolling } from 'hooks/usePolling';

interface Props {
	views: Array<Tab>;
	selectedView: string;
}

// TODO replace this with as simple spinner
export function Skeleton({ views, selectedView }: Props) {
	const { pollingEnabled, togglePolling } = usePolling();

	return (
		<Page title={'...'}>
			<Layout>
				<Layout.Section>
					<AutoRefresh
						pollingEnabled={pollingEnabled}
						togglePolling={togglePolling}
						discriminatorData={{}}
					/>
				</Layout.Section>
				<Layout.Section>
					<ViewTabs tabs={views} selected={selectedView || views?.[0]?.id} onSelect={(id) => id} />
				</Layout.Section>
				<Layout.Section>
					<Card title={'...'}>
						<Card.Section></Card.Section>
						<Card.Section></Card.Section>
					</Card>
				</Layout.Section>
				<Layout.Section>
					<Card>
						<Card.Section></Card.Section>
						<Card.Section></Card.Section>
					</Card>
				</Layout.Section>
			</Layout>
		</Page>
	);
}
