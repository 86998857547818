import { Stack, TextStyle } from '@shopify/polaris';
import { KitComponentProductDetail } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	componentProduct: KitComponentProductDetail;
}

export function Issues({ componentProduct }: Props): JSX.Element {
	const { messages } = useLocalization();

	return (
		<Stack vertical={true} spacing="extraTight">
			{componentProduct.exceptions ? (
				componentProduct.exceptions.map((issue) => {
					const reason = {
						damagedProduct: messages.kitIssues.damagedProduct,
						missingProduct: messages.kitIssues.missingProduct,
						other: messages.kitIssues.otherIssue,
					}[issue.reason];

					return (
						<TextStyle variation="negative" key={`${componentProduct.id}-${issue.reason}`}>
							{`${issue.eachQuantity} ${reason}`}
						</TextStyle>
					);
				})
			) : (
				<NoData />
			)}
		</Stack>
	);
}
