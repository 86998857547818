import { DeviceType } from '@sixriver/fulfillment-api-schema';

export enum DeviceViews {
	All = 'All',
	Chuck = 'Chuck',
	Handheld = 'Handheld',
	PackoutTerminal = 'PackoutTerminal',
	SortWall = 'SortWall',
	SortationKiosk = 'SortationKiosk',
	TerminalOnWheels = 'TerminalOnWheels',
}

export const DeviceViewStateMap: { [key in DeviceViews]: DeviceType[] } = {
	[DeviceViews.All]: [
		DeviceType.Chuck,
		DeviceType.Handheld,
		DeviceType.PackoutTerminal,
		DeviceType.SortWall,
		DeviceType.SortationKiosk,
		DeviceType.TerminalOnWheels,
	],
	[DeviceViews.Chuck]: [DeviceType.Chuck],
	[DeviceViews.Handheld]: [DeviceType.Handheld],
	[DeviceViews.PackoutTerminal]: [DeviceType.PackoutTerminal],
	[DeviceViews.SortWall]: [DeviceType.SortWall],
	[DeviceViews.SortationKiosk]: [DeviceType.SortationKiosk],
	[DeviceViews.TerminalOnWheels]: [DeviceType.TerminalOnWheels],
};
