import { Card, Link } from '@shopify/polaris';
import { Order } from '@sixriver/fulfillment-api-schema';

import { CardDetails } from 'components/CardDetails';
import { DateTime } from 'components/DateTime';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { getFirstPickingJob } from 'helpers/exception';
import { calculateUnits } from 'helpers/order';
import { useLocalization } from 'hooks/useLocalization';
import { order as orderRoute } from 'routes';

export function ExceptionDetails({
	loading = false,
	order,
	isWorkAreaEnabled,
}: {
	loading?: boolean;
	order?: Order;
	isWorkAreaEnabled?: boolean;
}) {
	const { messages, translate } = useLocalization();

	const totalPicked = calculateUnits(order?.lines);

	const exceptionCount = (order?.lines || []).reduce(
		(total, line) => (line.doneQuantity === line.quantity ? total : total + 1),
		0,
	);

	const firstResolutionJob = getFirstPickingJob(order?.servicedBy);

	return (
		<Card title={messages.details} sectioned>
			<CardDetails
				loading={loading}
				primary={[
					...(isWorkAreaEnabled
						? [
								{
									label: messages.workArea,
									content: order?.workAreas?.map((workArea) => workArea.name).join(', '),
								},
						  ]
						: []),
					{
						label: messages.order,
						content: order?.id ? (
							<Link url={orderRoute(order?.id)}>{order?.customerIdentifier}</Link>
						) : null,
					},
					{
						label: messages.initialLicensePlate,
						content: firstResolutionJob?.container?.barcode,
					},
					{
						label: messages.unitsPicked,
						content: order?.lines
							? translate(messages.pickedOfTotal, {
									picked: totalPicked.picked,
									total: totalPicked.total,
							  })
							: null,
					},
					{
						label: messages.lines,
						content: order?.lines?.length || 0,
					},
					{
						label: messages.exceptions,
						content: exceptionCount,
					},
				]}
				secondary={[
					{
						label: messages.cutoff,
						content: (
							<>
								<DateTime date={order?.expectedShipDate} />
								<RelativeDateTime date={order?.expectedShipDate} mode="deadline" />
							</>
						),
					},
					{
						label: messages.inductedAt,
						content: (
							<>
								<DateTime date={order?.timeline?.createdAt} />
								<RelativeDateTime date={order?.timeline?.createdAt} />
							</>
						),
					},
				]}
			/>
		</Card>
	);
}
