import { MutationCancelOrderArgs } from '@sixriver/fulfillment-api-schema';
import gql from 'graphql-tag';
import { useState } from 'react';
import { useMutation } from 'urql';

const CANCEL_ORDER_MUTATION = gql`
	mutation CancelOrder($projectionId: String!) {
		cancelOrder(projectionId: $projectionId) {
			success
		}
	}
`;

export const useCancelOrder = ({
	orderId,
	onSuccessfulCancel,
	onError,
	onBefore,
}: {
	orderId: string;
	onSuccessfulCancel?: () => void;
	onError?: () => void;
	onBefore?: () => void;
}) => {
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

	const closeCancelModal = () => {
		setIsCancelModalOpen(false);
	};

	const openCancelModal = () => {
		setIsCancelModalOpen(true);
	};

	const [
		{ fetching: cancelOrderLoading, error: cancelOrderError },
		cancelOrderMutation,
	] = useMutation<{ cancelOrder: Response }, MutationCancelOrderArgs>(CANCEL_ORDER_MUTATION);

	const cancelOrder = async () => {
		try {
			if (onBefore) {
				onBefore();
			}
			const { error } = await cancelOrderMutation({ projectionId: orderId });
			closeCancelModal();

			if (error) {
				throw error;
			}

			if (onSuccessfulCancel) {
				onSuccessfulCancel();
			}
		} catch (e) {
			if (onError) {
				onError();
			}
		}
	};

	return {
		isCancelModalOpen,
		closeCancelModal,
		openCancelModal,
		cancelOrder,
		cancelOrderLoading,
		cancelOrderError,
	};
};
