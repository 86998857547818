import { Page, Layout, Stack } from '@shopify/polaris';
import { LoggedAction, LoggedActionPage } from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';

import { ACTION_LOG_QUERY } from './ActionLog.graphql';
import { ResultSummary } from './ResultSummary';
import { AutoRefresh } from 'components/AutoRefresh';
import { Column, DataTable } from 'components/DataTable';
import {
	OptionValues,
	DateRange,
	DateRangeSelect,
	OptionLabelValue,
} from 'components/DateRangeSelect/DateRangeSelect';
import { DateTime } from 'components/DateTime';
import { Error } from 'components/Error';
import { NoData } from 'components/NoData';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { getMidnight } from 'helpers/time';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import * as routes from 'routes';

const defaultDateOption = OptionValues.last90Days;
const defaultDate = getMidnight(-90);

export function ActionLog() {
	const { messages } = useLocalization();

	// State
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);
	const [startDate, setStartDate] = useState(defaultDate.toISOString());
	const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(defaultDateOption);

	// Polling
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	// Queries
	const [{ fetching, data, error }] = usePollingQuery<{ actionLog: LoggedActionPage }>({
		query: ACTION_LOG_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			updatedAtFrom: startDate,
			cursor: paginationCursors[0],
			limit: 50,
		},
	});

	// Data
	const loggedEntries = data?.actionLog.results || [];
	const cursor = data?.actionLog.cursor;

	// Page info
	const pageInfo = cursor ? { endCursor: cursor, hasNextPage: true } : {};

	// Columns
	const columns: Column[] = [
		// Removing name column until wis properly returns the user info
		//   See https://6river.atlassian.net/browse/ECL-38 for tracking info
		// {
		// 	type: 'text',
		// 	heading: "Name",
		// },
		{
			type: 'text',
			heading: messages.requestedAt,
		},
		{
			type: 'text',
			heading: messages.action,
		},
		{
			type: 'text',
			heading: messages.result,
		},
		// Re implement when working https://6river.atlassian.net/browse/HRZ-648
		// {
		// 	type: 'text',
		// 	heading: "Export",
		// },
	];

	// Rows
	const rows: React.ReactNode[][] = loggedEntries.map((log) => {
		return [
			// Removing name column until wis properly returns the user info
			//   See https://6river.atlassian.net/browse/ECL-38 for tracking info
			// <Name key={`name-${log.id}`}loggedAction={log} />,
			<CreatedAtDate key={`createdAt-${log.id}`} loggedAction={log} />,
			log.actionType ? messages.cancelOrder : <NoData />,
			<ResultSummary key={`results-${log.id}`} loggedAction={log} />,
		];
	});

	// Date Range
	const options: OptionValues[] = [
		OptionValues.today,
		OptionValues.yesterday,
		OptionValues.last30Days,
		OptionValues.last60Days,
		OptionValues.last90Days,
	];

	function handleDateRangeSelectionChanged(dateRange: DateRange, selectedOption: OptionLabelValue) {
		const { start } = dateRange;

		if (start) {
			const startDateMidnight: Date = new Date(start);
			startDateMidnight.setHours(0, 0, 0, 0);

			setStartDate(startDateMidnight.toISOString());
			setSelectedDateRangeOption(selectedOption.value);
		}
	}

	// Guards
	const loading = fetching;

	// Render
	if (error) {
		return <Error graphQLError={error} />;
	}
	return (
		<Page
			breadcrumbs={[{ content: messages.orders, url: routes.orders() }]}
			fullWidth
			title={messages.actionLog}
		>
			<Layout>
				<Layout.Section>
					<Stack distribution="equalSpacing">
						<DateRangeSelect
							options={options}
							selectedOption={selectedDateRangeOption}
							onChange={handleDateRangeSelectionChanged}
						/>
						<AutoRefresh
							pollingEnabled={pollingEnabled}
							togglePolling={togglePolling}
							discriminatorData={data}
						/>
					</Stack>
				</Layout.Section>
				<Layout.Section>
					<DataTable
						columns={columns}
						rows={rows}
						loading={loading}
						pageInfo={pageInfo}
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
						emptyStateHeading={messages.logsNotFound}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}

interface Props {
	loggedAction: LoggedAction;
}

function CreatedAtDate({ loggedAction }: Props): JSX.Element {
	return loggedAction.createdAt ? <DateTime date={loggedAction.createdAt} /> : <NoData />;
}

// Removing name column until wis properly returns the user info
//   See https://6river.atlassian.net/browse/ECL-38 for tracking info
// function Name({loggedAction}: Props): JSX.Element {
// 	return loggedAction.user?.name ? <>loggedAction.user?.name</> : <NoData />;
// }
