import { Page } from '@shopify/polaris';
import { ProductInput, MutationResponse } from '@sixriver/fulfillment-api-schema';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'urql';

import { ADD_PRODUCT_MUTATION } from './AddProduct.graphql';
import { ProductForm } from './ProductForm';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

export function AddProduct(): JSX.Element {
	const { messages } = useLocalization();
	const history = useHistory();

	const [{ error: addProductError }, addProductMutation] = useMutation<
		{ addProduct: MutationResponse },
		{ input: ProductInput }
	>(ADD_PRODUCT_MUTATION);

	const onSubmit = async (input: ProductInput) => {
		const { data } = await addProductMutation({ input });
		const productId = data?.addProduct?.referenceId;

		if (productId) {
			history.push(routes.product(productId));
		}
	};

	const input: ProductInput = {
		customerIdentifier: '',
		name: '',
		description: '',
		image: null,
		width: 10,
		height: 10,
		length: 10,
		weight: 1,
	};

	return (
		<Page
			title={messages.addProduct}
			breadcrumbs={[{ content: messages.products, url: routes.products() }]}
			fullWidth
		>
			<ProductForm mode="add" data={input} onSubmit={onSubmit} error={addProductError} />
		</Page>
	);
}
