import { Line, OrderServicedBy, Picking, LineStatus } from '@sixriver/fulfillment-api-schema';

export function calculateUnits(lines?: Line[] | null) {
	return (lines || []).reduce(
		(result, line) => ({
			picked: result.picked + line.doneQuantity,
			total: result.total + line.quantity,
			inProgress:
				result.inProgress +
				(line.status?.includes(LineStatus.InProgress) ? line.quantity - line.doneQuantity : 0),
			unassigned:
				result.unassigned +
				(line.status?.includes(LineStatus.InProgress) ? 0 : line.quantity - line.doneQuantity),
		}),
		{ picked: 0, total: 0, inProgress: 0, unassigned: 0 },
	);
}

export function getPickingJobs(servicedBy?: OrderServicedBy[] | null) {
	return (servicedBy || []).filter((job) => job.__typename === 'Picking') as Picking[];
}
