import { Card, Link, Stack, TextContainer, TextStyle, Thumbnail } from '@shopify/polaris';
import { Return, ReturnLine, ReturnIssue } from '@sixriver/fulfillment-api-schema';

import styles from './Lines.module.css';
import { Divider } from 'components/Divider';
import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';
import Placeholder from 'images/placeholder.svg';
import * as routes from 'routes';
import { transformImageUrl } from 'utils/image';

// handle calculating quantities based on overRecieved
function createOriginalReturn(overReceivedCount: number, returnLine: ReturnLine) {
	return {
		...returnLine,
		sellableQuantity:
			returnLine.damagedQuantity === 0
				? returnLine.sellableQuantity - overReceivedCount
				: returnLine.sellableQuantity > returnLine.expectedQuantity
				? returnLine.sellableQuantity - (returnLine.sellableQuantity - returnLine.expectedQuantity)
				: returnLine.sellableQuantity,
		damagedQuantity:
			// if the sellable quantity is 0, we can assume the damaged quantity is the only count we need to display
			returnLine.sellableQuantity === 0
				? returnLine.damagedQuantity - overReceivedCount
				: // if the sellable quantity is greater than the expected quantity, we will only display the sellable quantity and can ignore the damaged quantity count
				returnLine.sellableQuantity >= returnLine.expectedQuantity
				? 0
				: // if the sellableQuantity is less then the expectedQuantity we need to do some complicated logic to determine how mnay units are sellable and how many are damaged
				returnLine.sellableQuantity < returnLine.expectedQuantity
				? returnLine.damagedQuantity === returnLine.expectedQuantity
					? returnLine.damagedQuantity - returnLine.sellableQuantity
					: returnLine.damagedQuantity > returnLine.expectedQuantity
					? returnLine.damagedQuantity -
					  (returnLine.damagedQuantity - returnLine.expectedQuantity) -
					  returnLine.sellableQuantity
					: returnLine.damagedQuantity - returnLine.expectedQuantity
				: returnLine.damagedQuantity,
	};
}

export function Lines({ inboundReturn }: { inboundReturn?: Return }) {
	const { messages } = useLocalization();

	const returnLines = inboundReturn?.returnLines || [];
	const returnIssues = inboundReturn?.issues || [];

	const expectedUnits: ReturnLine[] = [];
	const unexpectedUnits: ReturnLine[] = [];

	// TODO: update this mapping to use the new return line unit schema when it is available
	returnLines.forEach((returnLine) => {
		if (returnLine.expectedQuantity > 0) {
			// an expected return line that receives more units than expected needs to display the over received units in the unexpected UI area
			if (returnLine.returnedQuantity > returnLine.expectedQuantity) {
				// calculate how many units were over received
				const overReceivedCount = returnLine.returnedQuantity - returnLine.expectedQuantity;

				// the following logic is knowingly very unreadable and will be refactored when receiving services supports unit level returns
				const originalReturn = createOriginalReturn(overReceivedCount, returnLine);

				const overReturn = {
					...returnLine,
					sellableQuantity: returnLine.sellableQuantity - originalReturn.sellableQuantity,
					damagedQuantity: returnLine.damagedQuantity - originalReturn.damagedQuantity,
				};

				expectedUnits.push(originalReturn);
				unexpectedUnits.push(overReturn);
				return;
			}

			expectedUnits.push(returnLine);
			return;
		}

		// a return line is considered unexpected if the expectedQuantity === 0 and the returnedQuantity > 0
		if (returnLine.expectedQuantity === 0 && returnLine.returnedQuantity > 0) {
			unexpectedUnits.push(returnLine);
		}

		return;
	});

	const UnitCard = ({
		returnLine,
		noData,
		sellable,
		unsellable,
	}: {
		returnLine: ReturnLine;
		noData?: boolean;
		sellable?: boolean;
		unsellable?: boolean;
	}) => {
		return (
			<Card.Section>
				<div className={styles.productColumns}>
					{returnLine.product?.id ? (
						<Stack spacing="tight">
							<Thumbnail
								source={transformImageUrl(returnLine.product?.image)}
								alt={returnLine.product?.description || ''}
								size="small"
							/>
							<Stack vertical spacing="extraTight">
								<Link url={routes.product(returnLine.product.id)} removeUnderline>
									{returnLine.product.description}
								</Link>
								<TextStyle variation="subdued">{returnLine.product.name}</TextStyle>
							</Stack>
						</Stack>
					) : (
						<NoData />
					)}
					{noData ? (
						<NoData />
					) : (
						<TextContainer>
							<TextStyle variation="subdued">
								{sellable && messages.sellable}
								{unsellable && messages.unsellable}
							</TextStyle>
						</TextContainer>
					)}
				</div>
			</Card.Section>
		);
	};

	return (
		<Card title={messages.returnedInventory}>
			<Card.Section>
				<div className={styles.productColumns}>
					<TextContainer>
						<TextStyle variation="strong">{messages.product}</TextStyle>
					</TextContainer>
					<TextContainer>
						<TextStyle variation="strong">{messages.condition}</TextStyle>
					</TextContainer>
				</div>
			</Card.Section>
			{expectedUnits.map((returnLine: ReturnLine, index) => (
				<>
					<Divider />
					<div key={`expected-${returnLine.id}-container-${index}`}>
						{returnLine.expectedQuantity - returnLine.returnedQuantity > 0 &&
							[...Array(returnLine.expectedQuantity - returnLine.returnedQuantity)].map(
								(_, index) => (
									<UnitCard
										key={`expected-${returnLine.id}-${index}-units-pending`}
										returnLine={returnLine}
										noData
									/>
								),
							)}
						{[...Array(returnLine.sellableQuantity)].map((_, index) => (
							<UnitCard
								key={`expected-${returnLine.id}-${index}-sellable`}
								returnLine={returnLine}
								sellable
							/>
						))}
						{[...Array(returnLine.damagedQuantity)].map((_, index) => (
							<UnitCard
								key={`expected-${returnLine.id}-${index}-unsellable`}
								returnLine={returnLine}
								unsellable
							/>
						))}
					</div>
				</>
			))}
			{(returnIssues.length > 0 || unexpectedUnits.length > 0) && (
				<>
					<div className={styles.subduedSection}>
						<TextContainer>
							<TextStyle variation="strong">{messages.unexpectedInventory}</TextStyle>
						</TextContainer>
					</div>
					{unexpectedUnits.map((returnLine: ReturnLine, index) => (
						<>
							<Divider />
							<div key={`unexpected-${returnLine.id}-container-${index}`}>
								{[...Array(returnLine.sellableQuantity)].map((_, index) => (
									<UnitCard
										key={`unexpected-${returnLine.id}-${index}-sellable`}
										returnLine={returnLine}
										sellable
									/>
								))}
								{[...Array(returnLine.damagedQuantity)].map((_, index) => (
									<UnitCard
										key={`unexpected-${returnLine.id}-${index}-unsellable`}
										returnLine={returnLine}
										unsellable
									/>
								))}
							</div>
						</>
					))}
					{returnIssues.map(
						(issue: ReturnIssue) =>
							issue.reason === 'UnknownItem' && (
								<>
									<Divider />
									<Card.Section>
										<div className={styles.productColumns} key={issue.id}>
											<Stack alignment="center">
												<Thumbnail size="small" source={Placeholder} alt={messages.unknownItem} />
												<TextContainer>
													<TextStyle variation="subdued">{messages.unknownItem}</TextStyle>
												</TextContainer>
											</Stack>
											<TextContainer>
												<TextStyle variation="subdued">n/a</TextStyle>
											</TextContainer>
										</div>
									</Card.Section>
								</>
							),
					)}
				</>
			)}
		</Card>
	);
}
