import { OrderByDirection, StorageLocationOrderByFields } from '@sixriver/fulfillment-api-schema';

import { useFilters, useSetFilters } from 'hooks/useFilters';

// Defaults
const defaultSort = `${StorageLocationOrderByFields.Address} ${OrderByDirection.Desc}`;
const defaultView = 'all';

// Keys
const keys = {
	FULLNESS_KEY: 'fullness',
	LOCATION_IDS_KEY: 'locationIds',
	IS_CONFLICTED_KEY: 'isConflicted',
	IS_SLOTTED_KEY: 'isSlotted',
	LOCATION_TYPE_KEY: 'locationType',
	view: 'view',
	sort: 'sort',
	SEARCH_TEXT_KEY: 'searchText', // query
	WORKAREA_KEY: 'workArea',
} as const;

type FilterKeys = typeof keys[keyof typeof keys];
type LocationTableFilters = Record<FilterKeys, string | undefined>;

export function useLocationsTableFilters() {
	const setAnyFilters = useSetFilters();

	const filters: LocationTableFilters = useFilters(Object.values(keys), {
		queryKey: keys.SEARCH_TEXT_KEY,
		viewKey: keys.view,
	}) as LocationTableFilters;

	filters.sort = filters.sort || defaultSort;
	filters.view = filters.view || defaultView;

	// Methods
	function setLocationTableFilter(filterKey: FilterKeys, value: string) {
		return setAnyFilters([{ key: filterKey, value }]);
	}

	return {
		filters,
		keys,
		setLocationTableFilter,
		setFilters: setAnyFilters,
	};
}
