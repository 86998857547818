import { ChoiceList, Layout, Page } from '@shopify/polaris';
import { useDebouncedValue } from '@shopify/react-hooks';
import {
	Count,
	OrderByDirection,
	QueryShippingNoticeSummariesArgs,
	ShippingNoticeSummaryPage,
	ShippingNoticeSummaryOrderByField,
} from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';
import { useQuery } from 'urql';

import { InboundShipmentViews, InboundShipmentViewStateMap } from './InboundShipmentViews';
import {
	SHIPPING_NOTICE_SUMMARIES_QUERY,
	COUNTS_QUERY,
	MERCHANTS_QUERY,
	STATIC_COUNTS_QUERY,
} from './InboundShipments.graphql';
import { InboundShipmentsTable } from './InboundShipmentsTable';
import { DateRange, DateRangeSelect, OptionValues } from 'components/DateRangeSelect';
import { Error } from 'components/Error';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { Toteboard } from 'components/Toteboard';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { startOfDay, endOfDay, addDays, getMidnight } from 'helpers/time';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';

const ISSUES_KEY = 'hasIssues';
const ASN_TYPE_KEY = 'asnType';
const STORES_KEY = 'store';
const ESTIMATED_ARRIVAL_KEY = 'estimatedArrival';
const SLA_AT_RISK_KEY = 'slaAtRisk';
const JOB_CREATED_AT_FROM_KEY = 'jobCreatedAtFrom';

const defaultDate = getMidnight(-30);
const dateRangeOptions = [
	OptionValues.last30Days,
	OptionValues.last60Days,
	OptionValues.last90Days,
	OptionValues.allTime,
];

function calculateETAFrom(selectedETA: string | undefined) {
	return selectedETA ? startOfDay() : undefined;
}

function calculateETATo(selectedETA: string | undefined) {
	const now = Date.now();

	if (selectedETA) {
		const dayEnd = endOfDay();

		switch (selectedETA) {
			case '30DAYS':
				return addDays(dayEnd, 29);
			case '7DAYS':
				return addDays(dayEnd, 6);
			case 'TODAY':
				return dayEnd;
			case 'LATE':
			default:
				// up to 5 seconds ago, but effectively "right now."
				// this value must be deterministic to prevent a render loop
				return new Date(now - (now % 5000));
		}
	}

	return undefined;
}

export function InboundShipments() {
	const { messages, translate } = useLocalization();

	const defaultView = InboundShipmentViews.All;
	const defaultSort = ShippingNoticeSummaryOrderByField.ExternalId + ' ' + OrderByDirection.Desc;
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	// filtring logic
	const {
		query,
		sort = defaultSort,
		view = defaultView,
		[ISSUES_KEY]: issuesParam,
		[ASN_TYPE_KEY]: asnTypeParam,
		[STORES_KEY]: storeParam,
		[ESTIMATED_ARRIVAL_KEY]: etaParam,
		[SLA_AT_RISK_KEY]: slaAtRiskParam,
		[JOB_CREATED_AT_FROM_KEY]: jobCreatedAtFromParam,
	} = useFilters([
		ISSUES_KEY,
		ASN_TYPE_KEY,
		STORES_KEY,
		ESTIMATED_ARRIVAL_KEY,
		SLA_AT_RISK_KEY,
		JOB_CREATED_AT_FROM_KEY,
	]);
	const setFilters = useSetFilters();

	const searchText = useDebouncedValue(query) || '';
	const actualSearchText = searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined;
	const selectedIssues = issuesParam === 'true';
	const selectedASNType = asnTypeParam;
	const selectedStores = storeParam ? storeParam.split(',') : undefined;
	const selectedETA = etaParam;
	const selectedAtRisk = slaAtRiskParam === 'true';
	const historyDateLimiter = jobCreatedAtFromParam ? new Date(jobCreatedAtFromParam) : defaultDate;

	const statuses = InboundShipmentViewStateMap[view as InboundShipmentViews];

	// query logic
	const [
		{
			fetching: shippingNoticeSummariesFetching,
			data: shippingNoticeSummariesData,
			error: shippingNoticeSummariesError,
		},
	] = useQuery<
		{ shippingNoticeSummaries: ShippingNoticeSummaryPage },
		QueryShippingNoticeSummariesArgs
	>({
		query: SHIPPING_NOTICE_SUMMARIES_QUERY,
		variables: {
			limit: 50,
			merchants: selectedStores,
			orderBy: sort ? (sort.split(' ')[0] as ShippingNoticeSummaryOrderByField) : undefined,
			orderDirection: sort ? (sort.split(' ')[1] as OrderByDirection) : undefined,
			searchText: actualSearchText,
			statuses,
			hasIssues: selectedIssues,
			etaFrom: calculateETAFrom(selectedETA),
			etaTo: calculateETATo(selectedETA),
			createdAtFrom: historyDateLimiter ? historyDateLimiter : undefined,
			cursor: paginationCursors[0],
			slaAtRisk: selectedAtRisk,
			internalTransfer: selectedASNType
				? selectedASNType === 'NODE_TO_NODE'
					? true
					: false
				: undefined,
		},
	});

	const [{ fetching: staticCountsFetching, data: staticCountsData, error: staticCountsError }] =
		useQuery<{
			StaticArrivingTodayMerchantsCount: Count;
			StaticArrivingTodayNodeToNodeCount: Count;
			StaticArrivingTodayMerchantsUnitsCount: ShippingNoticeSummaryPage;
			StaticArrivingTodayNodeToNodeUnitsCount: ShippingNoticeSummaryPage;
			StaticAtRiskSLACount: Count;
		}>({
			query: STATIC_COUNTS_QUERY,
			variables: {
				etaFrom: calculateETAFrom('TODAY'),
				etaTo: calculateETATo('TODAY'),
				createdAtFrom: historyDateLimiter ? historyDateLimiter : undefined,
			},
		});

	const [{ fetching: countsFetching, data: countsData, error: countsError }] = useQuery<{
		SubmittedCount: Count;
		InTransitCount: Count;
		ArrivedCount: Count;
		ReceivingCount: Count;
		ClosedCount: Count;
		CancelledCount: Count;
	}>({
		query: COUNTS_QUERY,
		variables: {
			merchants: selectedStores,
			searchText: actualSearchText,
			hasIssues: selectedIssues,
			etaFrom: calculateETAFrom(selectedETA),
			etaTo: calculateETATo(selectedETA),
			createdAtFrom: historyDateLimiter ? historyDateLimiter : undefined,
			slaAtRisk: selectedAtRisk,
			internalTransfer: selectedASNType
				? selectedASNType === 'NODE_TO_NODE'
					? true
					: false
				: undefined,
		},
	});

	const [{ fetching: merchantsFetching, data: merchantsData, error: merchantsError }] = useQuery<{
		shippingNoticeMerchants: string[];
	}>({
		query: MERCHANTS_QUERY,
	});

	const fetching =
		shippingNoticeSummariesFetching || staticCountsFetching || countsFetching || merchantsFetching;
	const error = shippingNoticeSummariesError || staticCountsError || countsError || merchantsError;

	const {
		StaticArrivingTodayMerchantsCount = { count: 0 },
		StaticArrivingTodayNodeToNodeCount = { count: 0 },
		StaticArrivingTodayMerchantsUnitsCount,
		StaticArrivingTodayNodeToNodeUnitsCount,
		StaticAtRiskSLACount = { count: 0 },
	} = staticCountsData || {};

	const {
		SubmittedCount = { count: 0 },
		InTransitCount = { count: 0 },
		ArrivedCount = { count: 0 },
		ReceivingCount = { count: 0 },
		ClosedCount = { count: 0 },
		CancelledCount = { count: 0 },
	} = countsData || {};

	const views = [
		{
			label: messages.all,
			id: InboundShipmentViews.All,
		},
		{
			label: messages.submitted,
			metaLabel: SubmittedCount.count,
			id: InboundShipmentViews.Submitted,
		},
		{
			label: messages.inTransit,
			metaLabel: InTransitCount.count,
			id: InboundShipmentViews.InTransit,
		},
		{
			label: messages.arrived,
			metaLabel: ArrivedCount.count,
			id: InboundShipmentViews.Arrived,
		},
		{
			label: messages.receiving,
			metaLabel: ReceivingCount.count,
			id: InboundShipmentViews.Receiving,
		},
		{
			label: messages.closed,
			metaLabel: ClosedCount.count,
			id: InboundShipmentViews.Closed,
		},
		{
			label: messages.canceled,
			metaLabel: CancelledCount.count,
			id: InboundShipmentViews.Cancelled,
		},
	];

	function handleDateRangeSelectionChanged(dateRange: DateRange) {
		const { start } = dateRange;
		if (!start) {
			setFilters([{ key: JOB_CREATED_AT_FROM_KEY, value: undefined }]);
			return;
		}

		const startDateMidnight: Date = new Date(start);
		startDateMidnight.setHours(0, 0, 0, 0);
		setFilters([{ key: JOB_CREATED_AT_FROM_KEY, value: startDateMidnight.toISOString() }]);
	}

	const issueChoices = [
		{
			label: messages.shipmentsWithIssues,
			value: 'SHIPMENTS_WITH_ISSUES',
		},
	];

	const asnTypeChoices = [
		{
			label: messages.inbound,
			value: 'INBOUND',
		},
		{
			label: messages.internalTransfer,
			value: 'NODE_TO_NODE',
		},
	];

	const storeChoices =
		merchantsData?.shippingNoticeMerchants.map((merchant) => ({
			label: merchant,
			value: merchant,
		})) || [];

	const etaChoices = [
		{
			label: messages.arrivingLate,
			value: 'LATE',
		},
		{
			label: messages.arrivingToday,
			value: 'TODAY',
		},
		{
			label: translate(messages.arrivingWithinDays, { count: 7 }),
			value: '7DAYS',
		},
		{
			label: translate(messages.arrivingWithinDays, { count: 30 }),
			value: '30DAYS',
		},
	];

	const filters = [
		{
			key: ISSUES_KEY,
			label: messages.issues,
			filter: (
				<ChoiceList
					title={messages.issues}
					titleHidden
					choices={issueChoices}
					selected={selectedIssues ? ['SHIPMENTS_WITH_ISSUES'] : []}
					onChange={(selected) => {
						setFilters([{ key: ISSUES_KEY, value: selected.length ? 'true' : undefined }]);
					}}
					allowMultiple
				/>
			),
			shortcut: true,
		},
		{
			key: ASN_TYPE_KEY,
			label: messages.asnType,
			filter: (
				<ChoiceList
					title={messages.asnType}
					titleHidden
					choices={asnTypeChoices}
					selected={selectedASNType ? [selectedASNType] : []}
					onChange={(selected) => {
						setFilters([{ key: ASN_TYPE_KEY, value: selected.join(',') }]);
					}}
				/>
			),
			shortcut: true,
		},
		{
			key: STORES_KEY,
			label: messages.store,
			filter: (
				<ChoiceList
					title={messages.store}
					titleHidden
					choices={storeChoices}
					selected={selectedStores || []}
					onChange={(selected) => {
						setFilters([{ key: STORES_KEY, value: selected.join(',') }]);
					}}
					allowMultiple
				/>
			),
			shortcut: true,
		},
		{
			key: ESTIMATED_ARRIVAL_KEY,
			label: messages.estimatedArrival,
			filter: (
				<ChoiceList
					title={messages.estimatedArrival}
					titleHidden
					choices={etaChoices}
					selected={selectedETA ? [selectedETA] : []}
					onChange={(selected) => {
						setFilters([{ key: ESTIMATED_ARRIVAL_KEY, value: selected.join(',') }]);
					}}
				/>
			),
			shortcut: true,
		},
	];

	const appliedFilters = [
		...(selectedIssues
			? [
					{
						key: ISSUES_KEY,
						label: messages.shipmentsWithIssues,
						onRemove: () => {
							setFilters([{ key: ISSUES_KEY, value: '' }]);
						},
					},
			  ]
			: []),
		...(selectedASNType
			? [
					{
						key: ASN_TYPE_KEY,
						label: selectedASNType === 'INBOUND' ? messages.inbound : messages.internalTransfer,
						onRemove: () => {
							setFilters([{ key: ASN_TYPE_KEY, value: '' }]);
						},
					},
			  ]
			: []),
		...(selectedStores
			? [
					{
						key: STORES_KEY,
						label: selectedStores.join(', '),
						onRemove: () => {
							setFilters([{ key: STORES_KEY, value: '' }]);
						},
					},
			  ]
			: []),
		...(selectedETA
			? [
					{
						key: ESTIMATED_ARRIVAL_KEY,
						label:
							selectedETA === 'TODAY'
								? messages.arrivingToday
								: selectedETA === 'LATE'
								? messages.arrivingLate
								: (translate(messages.arrivingWithinDays, {
										count: parseInt(selectedETA),
								  }) as string),
						onRemove: () => {
							setFilters([{ key: ESTIMATED_ARRIVAL_KEY, value: '' }]);
						},
					},
			  ]
			: []),
		...(selectedAtRisk
			? [
					{
						key: SLA_AT_RISK_KEY,
						label: messages.atRiskSLA,
						onRemove: () => {
							setFilters([{ key: SLA_AT_RISK_KEY, value: '' }]);
						},
					},
			  ]
			: []),
	];

	const arrivingTodayMerchantsUnitsCount =
		StaticArrivingTodayMerchantsUnitsCount?.results.reduce(
			(counter, { quantityExpected }) => (counter += quantityExpected),
			0,
		) || 0;

	const arrivingTodayNodeToNodeUnitsCount =
		StaticArrivingTodayNodeToNodeUnitsCount?.results.reduce(
			(counter, { quantityExpected }) => (counter += quantityExpected),
			0,
		) || 0;

	const toteboardItems = [
		{
			label: messages.inboundArrivingToday,
			primaryValue: (
				<span>
					{translate(messages.countASNs, {
						count: StaticArrivingTodayMerchantsCount.count,
					})}
				</span>
			),
			secondaryValue: (
				<span>
					{translate(messages.countUnits, {
						count: arrivingTodayMerchantsUnitsCount,
					})}
				</span>
			),
			action: () =>
				setFilters([
					{ key: ESTIMATED_ARRIVAL_KEY, value: 'TODAY' },
					{ key: ASN_TYPE_KEY, value: 'INBOUND' },
				]),
		},
		{
			label: messages.internalTransfersArrivingToday,
			primaryValue: (
				<span>
					{translate(messages.countASNs, {
						count: StaticArrivingTodayNodeToNodeCount.count,
					})}
				</span>
			),
			secondaryValue: (
				<span>
					{translate(messages.countUnits, {
						count: arrivingTodayNodeToNodeUnitsCount,
					})}
				</span>
			),
			action: () =>
				setFilters([
					{ key: ESTIMATED_ARRIVAL_KEY, value: 'TODAY' },
					{ key: ASN_TYPE_KEY, value: 'NODE_TO_NODE' },
				]),
		},
		{
			label: messages.atRiskSLA,
			primaryValue: (
				<span>
					{translate(messages.countShipments, {
						count: StaticAtRiskSLACount.count,
					})}
				</span>
			),
			action: () => setFilters([{ key: SLA_AT_RISK_KEY, value: 'true' }]),
		},
	];

	return error ? (
		<Error graphQLError={error} />
	) : (
		<Page
			fullWidth
			title={messages.inboundShipments}
			primaryAction={
				<DateRangeSelect
					options={dateRangeOptions}
					selectedOption={historyDateLimiter}
					onChange={handleDateRangeSelectionChanged}
				/>
			}
		>
			<Layout>
				<Layout.Section>
					<Toteboard items={toteboardItems} icon />
				</Layout.Section>
				<Layout.Section>
					<InboundShipmentsTable
						appliedFilters={appliedFilters}
						data={shippingNoticeSummariesData?.shippingNoticeSummaries}
						filters={filters}
						loading={fetching}
						query={query}
						setFilters={setFilters}
						selectedView={view}
						sortValue={sort}
						views={views}
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
					/>
				</Layout.Section>
			</Layout>
			<TimezoneFooter />
		</Page>
	);
}
