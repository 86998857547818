import { Card } from '@shopify/polaris';
import { Return } from '@sixriver/fulfillment-api-schema';

import { Attachments } from './Attachments';
import { ExternalNotes } from './ExternalNotes';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	inboundReturn?: Return;
	refetchReturn: () => void;
}

export function ReturnIssues({ inboundReturn, refetchReturn }: Props) {
	const { messages } = useLocalization();

	return (
		<Card title={messages.returnIssues}>
			<Card.Section>
				<p>{messages.notesAndAttatchmentsVisible}</p>
			</Card.Section>
			<Card.Section>
				<ExternalNotes inboundReturn={inboundReturn} refetchReturn={refetchReturn} />
			</Card.Section>
			<Card.Section>
				<Attachments inboundReturn={inboundReturn} refetchReturn={refetchReturn} />
			</Card.Section>
		</Card>
	);
}
