import { Banner, Card, Heading, Layout, Page, Stack } from '@shopify/polaris';
import {
	GoalStates,
	KittingProject as KittingProjectApi,
	MutationResponse,
	SpecialProject as ApiSpecialProject,
	SpecialProjectType,
	WorkLog as WorkLogApi,
	WorkOrderProject as WorkOrderProjectApi,
} from '@sixriver/fulfillment-api-schema';
import { useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useMutation } from 'urql';

import { Instructions } from './Instructions';
import { KittingProject } from './KittingProject';
import Merchant from './Merchant';
import { Skeleton } from './Skeleton';
import { SpecialProjectReviewModal } from './SpecialProjectReviewModal';
import { ADD_TIME_LOG_MUTATION, SPECIAL_PROJECT_QUERY } from './SpecialProjects.graphql';
import Status from './Status';
import { TimeLogged } from './TimeLogged';
import Type from './Type';
import { WorkLog } from './WorkLog';
import { WorkLogAddEditModal } from './WorkLogAddEditModal';
import { WorkOrderProject } from './WorkOrderProject';
import { AutoRefresh } from 'components/AutoRefresh';
import { CardDetails } from 'components/CardDetails';
import { DateTime } from 'components/DateTime';
import { Error } from 'components/Error';
import { ViewTabs } from 'components/ViewTabs';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { useToast } from 'hooks/useToast';

export interface RouteMatchParams {
	projectId: string;
}
export function SpecialProject(): JSX.Element {
	const { messages, translate } = useLocalization();

	const {
		params: { projectId },
	} = useRouteMatch<RouteMatchParams>();
	const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
	const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);
	const [isFAIErrorBannerOpen, setisFAIErrorBannerOpen] = useState<boolean>(false);

	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	const { showToast } = useToast();

	const { tab } = useFilters(['tab']);
	const setFilters = useSetFilters();

	// Views
	const views = [
		{
			label: messages.overview,
			id: 'overview',
		},
		{
			label: messages.workLog,
			id: 'workLog',
		},
	];

	const selectedView = 'overview';

	// Data
	const [{ fetching, data, error: queryError }] = usePollingQuery<
		{ specialProject: ApiSpecialProject },
		{ id: string }
	>({
		query: SPECIAL_PROJECT_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			id: projectId,
		},
	});

	const project = data?.specialProject;

	const canComplete = project ? isInACompleteableStatus(project) : false;
	const isComplete = project ? project.status === GoalStates.Complete : false;

	// Mutations
	const [{ error: addTimeLogError }, addTimeLog] =
		useMutation<{ addTimeLog: MutationResponse }>(ADD_TIME_LOG_MUTATION);

	// Methods
	const onMarkAsCompleteClick = useCallback(() => {
		if (project && needsInspection(project)) {
			setisFAIErrorBannerOpen(true);
		} else {
			setIsReviewModalOpen(true);
		}
	}, [project]);

	const onModalClose = () => {
		setIsReviewModalOpen(false);
	};

	const onBannerDismiss = () => {
		setisFAIErrorBannerOpen(false);
	};

	const onAddModalClose = useCallback(() => {
		setIsAddModalOpen(false);
	}, []);

	const onSave = useCallback(
		async (updatedLogItem: Partial<WorkLogApi>) => {
			if (updatedLogItem.user) {
				const { data } = await addTimeLog(
					{
						goalId: projectId,
						task: updatedLogItem.task,
						time: updatedLogItem.timeLogged,
						happenedAt: updatedLogItem.dateLogged,
						userId: updatedLogItem.user.id,
					},
					{
						additionalTypenames: ['WorkLogPage', 'WorkLog', 'KittingProject', 'WorkOrderProject'],
					},
				);

				onAddModalClose();

				if (data?.addTimeLog.success) {
					showToast(messages.workLogged);
				}
			}
		},
		[addTimeLog, onAddModalClose, projectId, showToast, messages],
	);

	const detailSubSection = useMemo(() => {
		switch (project?.type) {
			case SpecialProjectType.Kitting:
				return <KittingProject loading={fetching} kit={project as KittingProjectApi} />;
			default:
				return <WorkOrderProject loading={fetching} workOrder={project as WorkOrderProjectApi} />;
		}
	}, [fetching, project]);

	const error = queryError || addTimeLogError;

	if (error) {
		return <Error graphQLError={error} />;
	}

	if (fetching && !project) {
		return <Skeleton views={views} selectedView={selectedView} />;
	}

	if (!project) {
		return <Error message={messages.projectNotFound} />;
	}

	return (
		<>
			<Page
				fullWidth
				title={
					translate(messages.specialProjectByExternalId, {
						externalId: project.externalId,
					}) as string
				}
				titleMetadata={
					<div data-testid="header">
						<Stack spacing="extraTight">
							<Status project={project} />
						</Stack>
					</div>
				}
				primaryAction={{
					content: messages.markAsComplete,
					disabled: !canComplete,
					primary: false,
					onAction: onMarkAsCompleteClick,
				}}
				secondaryActions={[
					{
						content: messages.logWork,
						onAction: () => setIsAddModalOpen(true),
						disabled: isComplete,
					},
				]}
			>
				<Layout>
					<Layout.Section>
						<AutoRefresh
							pollingEnabled={pollingEnabled}
							togglePolling={togglePolling}
							discriminatorData={data}
						/>
					</Layout.Section>
					<Layout.Section>
						<ViewTabs
							tabs={views}
							selected={tab || views?.[0]?.id}
							onSelect={(id) => {
								setFilters([{ key: 'tab', value: id }]);
							}}
						/>
					</Layout.Section>
					{isFAIErrorBannerOpen ? (
						<Layout.Section>
							<Banner title={messages.faiErrorTitle} status="warning" onDismiss={onBannerDismiss}>
								<p>{messages.faiErrorMessage}</p>
							</Banner>
						</Layout.Section>
					) : undefined}
					{tab === 'workLog' ? (
						<Layout.Section>
							<WorkLog
								queryPollInterval={queryPollInterval}
								projectId={projectId}
								projectType={project.type}
								totalTime={project.totalTimeLogged}
								isProjectComplete={isComplete}
							/>
						</Layout.Section>
					) : (
						<>
							<Layout.Section>
								<Card title={messages.details}>
									<Card.Section>
										<CardDetails
											loading={fetching}
											primary={[
												{
													label: messages.service,
													content: <Type project={project} />,
												},
												{
													label: messages.store,
													content: <Merchant project={project} />,
												},
												{
													label: messages.receivedAt,
													content: <DateTime date={project?.dateReceived} />,
												},
											]}
										/>
									</Card.Section>
									<Card.Section>
										<CardDetails
											loading={fetching}
											primary={[
												{
													label: messages.timeLogged,
													content: <TimeLogged timeInMs={project.totalTimeLogged} />,
												},
											]}
										/>
									</Card.Section>
								</Card>
								{detailSubSection}
							</Layout.Section>
							<Layout.Section secondary>
								<Card>
									<Card.Section>
										<Stack distribution="equalSpacing">
											<Heading>{messages.instructions}</Heading>
										</Stack>
									</Card.Section>
									<Card.Section>
										<Instructions project={project as KittingProjectApi} />
									</Card.Section>
								</Card>
							</Layout.Section>
						</>
					)}
				</Layout>
			</Page>
			<SpecialProjectReviewModal
				isOpen={isReviewModalOpen}
				onClose={onModalClose}
				project={project}
			/>
			<WorkLogAddEditModal
				isOpen={isAddModalOpen}
				onClose={onAddModalClose}
				onSave={onSave}
				projectType={project.type}
			/>
		</>
	);
}

function isInACompleteableStatus(project: ApiSpecialProject): boolean {
	const completeableStatuses = [GoalStates.Running];

	if (!project.status) {
		return false;
	}

	return completeableStatuses.includes(project.status);
}

function needsInspection(project: ApiSpecialProject): boolean {
	if (project.type === SpecialProjectType.Kitting) {
		return (project as KittingProjectApi).product.needsInspection;
	}

	return false;
}
